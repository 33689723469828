//Comet
export class Constants {
  static readonly appName = "Comet Pro";
  static readonly deepLinkURL = "https://cometpro.page.link/rM3L";
  static readonly packageId = "com.cometpro.cometpro";
  static readonly deepLinkRouter = "/rM3L";
  static readonly pathGroupName = "Comet Demo";
  static readonly appLogoPath = "../../assets/icon/Comet-Login-Logo-white.png";
  static readonly prodWebappUrl = "https://comet.1st90.com/login"; 
}
