import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, PickerController } from '@ionic/angular';
import * as moment from 'moment';
import { AppState } from '../app.service';
import { Client } from '../client/client.model';
import { CordovaService } from '../cordova.service';
import { I18n } from '../i18n.service';
import * as AddToCalendar from "../../../vendor/add-to-calendar-buttons/add-to-calendar.js";
import { NotificationService } from '../notification/notification.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { PollService } from '../poll/poll.service';
import { SessionService } from '../session/session.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { SurveyService } from '../survey/survey.service';
import { ThemeService } from '../theme/theme.service';
import { TimePickerComponent } from '../time-picker/time-picker.component';
import { PathRegistration } from '../user/path-registration.model';
import { User } from '../user/user.model';
import { UserService } from '../user/user.service';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService } from '../toaster.service';
import { SpinnerService } from '../spinner.service';
import { AlertNotifyComponent } from '../alert-notify/alert-notify.component';
import { AlertProfilePictureComponent } from "../alert-profile-picture/alert-profile-picture.component";
import { CalendarService } from '../calendar.service';
import { fadeInLeftAnimation, fadeInRightAnimation, slideInDownAnimation, slideInLeftAnimation, slideInRightAnimation, slideInUpAnimation } from 'angular-animations';
import { Platform } from '@ionic/angular';
import { NativeService } from '../services/native.service';
import { StatService } from '../stat/stat.service';
import { AnalyticsServiceService } from '../services/analytics-service.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx';
import mixpanel from 'mixpanel-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MyCommitmentsComponent } from '../my-commitments/my-commitments.component';
import { MobileChatBoardComponent } from '../pages/mobile-chat-board/mobile-chat-board.component';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const DEFAULT_URL = environment.apiBaseUrl + '/files'
const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB - Accept raw images from the phone's camera
const DEFAULT_ALLOWED_MIME_TYPES = null // Workaround: Android issue with comparing types

class UploadConfig {
  url?: string = DEFAULT_URL
  maxFileSize?: number = DEFAULT_MAX_FILE_SIZE
  allowedMimeTypes?: string[] = DEFAULT_ALLOWED_MIME_TYPES
}

declare var FCMPlugin: any;
declare var $: any;

export interface CalendarParams {
  title: String;
  startDate: Date;
  endDate: Date;
  eventLocation: String;
  notes: String;
  endEventTime: any;
  frequency: string
}

export interface UpdateUser {
  [updateUser: string]: any
}

export interface CurrentPathRegistration {
  [pathRegistration: string]: any;
}

export interface UpdatePathRegistration {
  [updatePathRegistration: string]: any;
}

const defaultColumnOptions = [
  [
    "00:00", "00:30", "01:00", "01:30",
    "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00",
    "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30",
    "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00",
    "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00"
  ]
]

@Component({
  selector: "app-onboarding-survey",
  templateUrl: "./onboarding-survey.component.html",
  styleUrls: [
    "../shared/survey.shared.scss",
    "./onboarding-survey.component.scss",
  ],
  animations: [
    fadeInLeftAnimation({ duration: 300 }),
    fadeInRightAnimation({ duration: 300 }),
    slideInRightAnimation({ duration: 300 }),
    slideInLeftAnimation({ duration: 300 }),
    slideInUpAnimation({ duration: 300 }),
    trigger('collapseAnimation', [
      state('open', style({ height: '*', opacity: '1',bottom:'75px' })),
      state('closed', style({ height: '0', overflow:'hidden', opacity: '0',bottom:'75px' })),
      transition('open <=> closed', animate('0.2s ease-in-out')),
    ]),
  ]
})
export class OnboardingSurveyComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  @ViewChildren("surveyScreen") surveyScreens: QueryList<ElementRef>;
  @ViewChild("timePicker") timePicker: TimePickerComponent;
  @ViewChild("calendarButtonsContainer") calendarButtonsContainer: ElementRef;
  currentUser: User;
  currentPath: Path;
  currentClient: Client;
  currentScreenId = "survey-screen-welcome";
  currentScreenIndex ;
  screensIds = [];
  confirmedTime: string;
  answers = {};
  pathAverage;
  benchLinearScale;
  isNewPhoto: boolean | true;
  calendarParams: CalendarParams;
  surveyQuestions = [];
  selectedTimeOption = 0;
  savingResults = false;
  loadingEnabling: boolean;
  managerEmail: string = null;
  hasNativeCalendar: boolean; // LUMBA-1421
  cloudCalendarExpanded: boolean; // LUMBA-1421
  cloudCalendarSet: boolean; // LUMBA-1421
  morningTime: boolean;
  lunchTime: boolean;
  atWorkTime: boolean;
  homeTime: boolean;
  bedTime: boolean
  isVideoViewed = false;
  isTimePickerCard: boolean;
  isTimeSelectCard: boolean;

  isNextButtonAllowed = false;

  isButtonSelected: string;
  totalDesignPages = 0;

  isonboardingcompleted = true;

  isShowed: boolean = false;
  dataRefresher: any;

  photoUrl: any;
  isPhotoUploaded: boolean;
  label: any;
  isMobile: boolean;
  accountablityEmail: any;
  arrayOfAccountabilyEmails = [];
  start: any
  end: any
  endDate: any

  isRankSwaped: boolean = false
  dataFromChild: any
  responseData: any
  scaleData: any
  addedManagerEmail: any
  accountabilityEmails: any;
  userUpdate: UpdateUser
  croppedImagepath = "";
  isLoading = false;
  onBoardingImage: string;
  startTime: any;
  endTime: any;
  pushNotificationsEnabled: boolean
  emailReminderEnabled: boolean =true;
  pushReminderEnabled: boolean
  socialReminderEnabled: boolean
  socialEmailReminder: boolean;
  loading: boolean = false
  learningTime: any

  public pathRegistration: CurrentPathRegistration



  imagePickerOptions = {
    maximumImagesCount: 1,
    quality: 50
  };

  @Input() config: UploadConfig = new UploadConfig()
  @Input() photoLabel: string
  @Input() type: string
  @Input() labelSelected: string = ''
  @Input() fileUrl: string
  @Output() fileUrlChange = new EventEmitter<string>()
  @ViewChild('dateTime') sTime;

  @ViewChild('imageContainer') imageContainer: ElementRef;
  @ViewChild('disableCard') disableCard: ElementRef;
  @ViewChild('disableMcqCard')disableMcqCard: ElementRef;
  showTakeAPhoto = true
  public uploader: FileUploader

  state: boolean = false;
  rightState: boolean = false;
  animation = ['fadeInLeft', 'fadeInRight'];
  enableBlur=false;
  enableMcqBlur =false;
  linearScale;
  stats: object
  thisPathUser: any
  thisPathUserRank: any
  routineVersion: boolean
  enabledAttributes: any
  public updatePathRegistration: UpdatePathRegistration;
  selectedTime: any
  itemPublish: number = 22;
  dailyRoutine = [{ title: this.i18n.t('After waking up'), time: null },
  { title: this.i18n.t('When starting work'), time: null },
  { title: this.i18n.t('Lunchtime'), time: null },
  { title: this.i18n.t('When finishing work'), time: null },
  { title: this.i18n.t('Before bed'), time: null }];
  customPickerOptions: { buttons: { text: string; handler: (time: any) => void; }[]; };
  currIndex: any; 
  defaultTime: string = "07:00"; 
  isSubReply: boolean
  startPlayButton;
  isMobileWeb=false;
  isMobileWebIos = false;
  isIntroVideo;
  isOnboardingSurvey;
  destroyed$ = new Subject<boolean>();
  constructor(
    private homeService: HomeV5Service,
    private appState: AppState,
    private router: Router,
    private surveyService: SurveyService,
    private userService: UserService,
    private themeService: ThemeService,
    private notificationService: NotificationService,
    private cordovaService: CordovaService,
    private toastrService: ToasterService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    public i18n: I18n,
    private sessionService: SessionService,
    private calendarService: CalendarService,
    private cdRef: ChangeDetectorRef,
    private pollService: PollService,
    public actionSheetController: ActionSheetController,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    public alertController: AlertController,
    private native: NativeService,
    private statService: StatService,
    private firebaseX: FirebaseX,
    private pickerController: PickerController,
    private youtubePlayer: YoutubeVideoPlayer,
    private http: HttpClient,
    private renderer: Renderer2,
    public homeUIService: HomeUiService,
    private streamingMedia: StreamingMedia,
  ) {
    this.currentScreenIndex = 0;
    this.customPickerOptions = {
      buttons: [
        {
          text: this.i18n.t('Cancel'),
          handler: (time) => {
            return true;
          }
        },
        {
          text: this.i18n.t('Confirm'),
          handler: (time) => {
            this.afterWakingUp(time)
          }
        }
      ]
    }
    this.pathRegistration = this.appState.get('pathRegistration')
    this.currentUser = this.appState.get("currentUser");
    this.currentPath = this.appState.get("currentPath");
    this.currentClient = this.appState.get("currentClient");
    let learningTime = localStorage.getItem('learningTime')
    if (learningTime) {
      this.learningTime = learningTime
    }else{
      if (this.pathRegistration && this.pathRegistration.pathData) {
        this.learningTime = this.pathRegistration.pathData.loginReminderTime
      } 
    }
  const navigation = this.router.getCurrentNavigation();
  const state = navigation?.extras.state as { onboardingSurvey: boolean, isIntroVideo: boolean };

  if (state) {
    this.isIntroVideo = state.isIntroVideo;
     this.isOnboardingSurvey = state.onboardingSurvey;
  }
    if (this.currentPath.names.imageUrl) {
      this.onBoardingImage = this.currentPath.names.imageUrl
    } else {
      this.onBoardingImage = 'https://1st90-api-images.s3.amazonaws.com/1st90/Coding%2Band%2BDesign%2BIllustration%2B%5BConverted%5D-03___2.svg'
    }
    this.isMobile = appState.get("isMobile");
    this.themeService.setTheme(this.currentPath.getTheme());
    this.surveyQuestions = this.currentPath.onboardingSurveys;
    this.initScreensOrder();
    this.currentScreenIndex = 0;
    this.currentScreenId = this.screensIds[this.currentScreenIndex];
    const w: any = window;
    this.hasNativeCalendar =
      this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar;
    this.prepareCalendarParams();
    this.isTimePickerCard = false;
    this.isTimeSelectCard = true;
    this.photoUrl = this.sessionService.url;
    this.getStats(this.currentPath.id)
    this.enabledAttributes = this.appState.get("routineVersion")
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion
    } else {
      this.routineVersion = false
    }
  }
  
  blurCard(enable,ptype, linearScale?,pathAverage?) {
    // this.linearScale =linearScale;
    let  rationale;
    this.surveyQuestions?.forEach((question)=>{
     if(question.ptype =='mcq'){
      rationale =question.rationale;
     }
    })
    if(ptype =='linear') {
      this.enableBlur =enable;
      this.linearScale =linearScale;
      this.pathAverage =pathAverage;
      if(enable ==true){
        this.renderer.addClass(this.disableCard?.nativeElement, 'container-blur');
        if(this.isMobile){
          document.getElementsByClassName('container-blur')[0]?.setAttribute('style','padding-bottom: 0 !important;')
        }        

      }
      else if(enable ==false) {
        this.renderer.removeClass(this.disableCard?.nativeElement, 'container-blur');
      }
    }
    else if(ptype =='mcq') {
      this.enableMcqBlur =enable
      if(enable ==true && rationale){
        this.renderer.addClass(this.disableMcqCard?.nativeElement, 'container-blur');
        if(this.isMobile){
          document.getElementsByClassName('container-blur')[0]?.setAttribute('style','padding-bottom: 0 !important;')
        }
      }
      else if(enable ==false) {
        this.renderer.removeClass(this.disableMcqCard?.nativeElement, 'container-blur');   
      }
    }
   
  }
  openStart(index) {
    switch (index) {
      case 0:
        this.defaultTime = "07:00";
        break;
      case 1:
        this.defaultTime = "09:00";
        break;
      case 2:
        this.defaultTime = "12:00";
        break;
      case 3:
        this.defaultTime = "17:00";
        break;
      case 4:
        this.defaultTime = "22:00";
        break;
      default:
        this.itemPublish = 22;
    }
    this.currIndex = index;
    this.sTime.open();
  }
  afterWakingUp(time) {
    let time12h = time.hour.text + ':' + time.minute.text + " " + time.ampm.text;
    const [tim, modifier] = time12h.split(' ');
    let [hours, minutes] = tim.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      let i = parseInt(hours, 10) + 12;
      hours = i.toString();
    }
    this.selectedTime = hours + ':' + minutes

    for (let index = 0; index < this.dailyRoutine.length; index++) {
      if (index == this.currIndex) {
        this.dailyRoutine[this.currIndex].time = this.selectedTime
        localStorage.setItem("currIndex", index + "")
      } else {
        this.dailyRoutine[index].time = null
      }
    }
    this.confirmTime()
  }
  getButtonIndex(index) {
    switch (index) {
      case 0:
        this.defaultTime = "07:00";
        break;
      case 1:
        this.defaultTime = "09:00";
        break;
      case 2:
        this.defaultTime = "12:00";
        break;
      case 3:
        this.defaultTime = "17:00";
        break;
      case 4:
        this.defaultTime = "22:00";
        break;
      default:
        this.defaultTime = "22:00";
    }
    this.currIndex=index;
  }
  getSelectedList(routingTitle: any, time: any) {
    mixpanel.track("Onboarding-dailyRoutine", {'routine':routingTitle, 'routineTime': time})
  }
  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.atWorkTime = false;
    this.morningTime = false;
    this.lunchTime = false;
    this.homeTime = false;
    this.bedTime = false
    this.isButtonSelected = "firstButton";
    this.isPhotoUploaded = false;
    this.pathRegistration = this.appState.get('pathRegistration')
    if (this.currentUser.imageUrl.endsWith("avatar.png") || this.currentUser.imageUrl === 'nil') {
      this.label = "Tap to Add Photo";
    }

    this.addedManagerEmail = this.appState.get("managerEmail")
    this.accountabilityEmails = this.appState.get("accountabilityEmails")
    if (this.accountabilityEmails) {
      this.arrayOfAccountabilyEmails = this.accountabilityEmails
    }
    this.startTime = new Date();
    this.loadSvgFile('../../assets/web_home_ui/black_play_btn.svg');
    this.cordovaService.videoViewedStatus.pipe(takeUntil(this.destroyed$)).subscribe(async(data) => {
      if (data && data !=undefined &&  this.currentScreenIndex == 0) {
        //this.isVideoViewed = true;
        this.currentScreenIndex = 0;
        this.checkScreenCompletion();
        this.cordovaService.setVideoViewedStatus(undefined)
      }
    });
  }

  ngDoCheck(){
    this.isSubReply = this.appState.get('isSubReply')
    this.isRankSwaped = this.appState.get('isRankSwapped');
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngAfterViewInit() {

    this.showCurrentScreen();    
    if (this.pathRegistration && this.pathRegistration.pathData) {
      if (!this.pathRegistration.pathData.emailReminderEnabled && this.pathRegistration.pathData.emailReminderEnabled!==null) {
        this.emailReminderEnabled = this.pathRegistration.pathData.emailReminderEnabled
      }
    }
  }

  inputFocus() {
    this.isShowed = true;
  }

  toggleButtons() {
    if (this.isButtonSelected == "firstButton") {
      this.isButtonSelected = "secondButton";
    } else {
      this.isButtonSelected = "firstButton";
    }
  }

  toggleTimeCard() {
    if (this.isTimeSelectCard) {
    } else {
    }
  }

  toggleTimePicker() {
    if (this.isTimePickerCard) {
      this.isTimePickerCard = false;
      this.isTimeSelectCard = true;
    } else {
      this.isTimePickerCard = true;
      this.isTimeSelectCard = false;
    }
  }

  totalOfDesignPages() {
    this.totalDesignPages = 0;
    this.totalDesignPages = this.surveyQuestions.length + 2;
    return this.surveyQuestions.length + 2;
  }

  newStartedButton() {
    if (this.isButtonSelected == "firstButton") {
      this.firstScreen();
    } else if (this.isButtonSelected == "secondButton") {
      this.explore();
    }
  }

  initScreensOrder() {
    if (this.isIntroVideo == undefined || this.isOnboardingSurvey == undefined) {
      this.isIntroVideo = this.currentPath.names["isIntroVideo"];
       this.isOnboardingSurvey  = this.surveyQuestions?.length > 0 && this.currentPath.enabledAttributes["onboarding_survey"];
    }
    if (this.isIntroVideo || this.isOnboardingSurvey) {
      if (this.isIntroVideo && this.isOnboardingSurvey) {
        this.screensIds = [
          "survey-screen-play-intro",
          // "survey-screen-welcome",
          ...this.surveyQuestions.map((q) => `survey-screen-${q.id}`),
        ];
      }
      else if (this.isIntroVideo &&  !this.isOnboardingSurvey) {
        this.screensIds = [
          "survey-screen-play-intro",
          // "survey-screen-welcome",
          // ...this.surveyQuestions.map((q) => `survey-screen-${q.id}`),
        ];
      }
      else {
        this.screensIds = [
          // "survey-screen-welcome",
          ...this.surveyQuestions.map((q) => `survey-screen-${q.id}`),
        ];
      }
      
    }
    else {
       this.router.navigate(["/main"]);     
       this.checkSubpathStatus();
    }

    console.log("screenIDs in onboard", this.screensIds)
    // if (this.currentPath.enabledAttributes["push_notifications"]) {
    //   this.screensIds.push("survey-screen-permissions");
    // }
    // if (this.currentPath.enabledAttributes["manager_email"]) {
    //   this.screensIds.push("survey-screen-manager");
    // }

    // if (this.currentPath.enabledAttributes["accountability_email"]) {
    //   this.screensIds.push("survey-screen-accoutablity-partner");
    // }
    // // if (this.currentPath.names["isIntroVideo"]) {
    // //   this.screensIds.push("survey-screen-video-intro");
    // // }
    // {
    //   this.screensIds.push("survey-screen-profile");
    // }
  }

  showCurrentScreen() {
    // console.log("inside showCurrentScreen",this.surveyScreens)
    this.surveyScreens.map((item) => {
      // console.log("surveyscreen", item)
      const {
        nativeElement: { classList },
      } = item;
      if (item.nativeElement?.id === this.currentScreenId) {
        classList.add("show");
        this.analyticsService.setScreen(this.currentScreenId)

      } else {
        classList.remove("show");
      }
    });
  }
  firstScreen() {
    this.currentScreenIndex = 0;
    this.currentScreenId = this.screensIds[this.currentScreenIndex];
    this.showCurrentScreen();
  }
  prevScreen(block = false) {
    if (block) {
      return false;
    }
    this.currentScreenIndex--;
    this.currentScreenId = this.screensIds[this.currentScreenIndex];
    this.showCurrentScreen();
    this.leftSwipeAnimation()
  }
  trackTimeOnScreen(screenId: string, timeSpent: any) {
    // this.startTime = new Date();
    mixpanel.track(`Onboarding-${screenId}`, {'timeSpent': timeSpent})
  }
  nextScreen(block = false) {
    this.endTime = new Date();
    let timeDiff = this.endTime - this.startTime; // time difference in milliseconds
    let seconds = Math.floor(timeDiff / 1000); // convert to seconds
    let minutes = Math.floor(seconds / 60); // convert to minutes
    let hours = Math.floor(minutes / 60); // convert to hours
    let timeSpent = hours + ":" + minutes + ":" + seconds; // format the time spent
    this.trackTimeOnScreen(this.currentScreenId, timeSpent);
    if (block) {
      return false;
    }
    this.currentScreenIndex++;
    this.currentScreenId = this.screensIds[this.currentScreenIndex];
    // mixpanel.track(this.currentScreenId, {'totalTimeSpend': timeSpent})
    // if (this.screensIds[this.currentScreenIndex] === "survey-screen-permissions") {
    //   this.getToken()
    // }
    // if (this.screensIds[this.currentScreenIndex - 1] === "survey-screen-accoutablity-partner") {
    //   this.pushAccountablityEmails();
    // }
    // if (this.screensIds[this.currentScreenIndex] === "survey-screen-permissions") {
    //   this.initCalendarButton();
    // }
    this.showCurrentScreen();
    this.rightSwipeAnimation()
  }
  

  async openPicker(numColumns = 1, numOptions = 49, columnOptions = defaultColumnOptions) {
      let defaultHour = parseInt(this.defaultTime.split(':')[0]);
      const defaultMinute = parseInt(this.defaultTime.split(':')[1].split(' ')[0]);
      let defaultAmPm = this.defaultTime.split(' ')[1];
      defaultAmPm = defaultHour < 12 ? 'AM' : 'PM';

      if (defaultHour === 0) {
        defaultHour = 12;
      } else if (defaultHour > 12) {
        defaultHour -= 12;
        defaultAmPm = 'PM';
      }
    const picker = await this.pickerController.create({
            columns: [
        {
          name: 'hour',
          options: this.generateHourOptions(), // For hours in 12-hour format
          selectedIndex: defaultHour - 1,        },
        {
          name: 'minute',
          options: this.generateColumnOptions(), // For minutes
          selectedIndex: defaultMinute === 0 ? 0 : 1,
        },
        {
          name: 'ampm',
          options: [
            { text: 'AM', value: 'AM' },
            { text: 'PM', value: 'PM' },
          ],
          selectedIndex: defaultAmPm === 'AM' ? 0 : 1,
        },
      ],
      buttons: [
        {
          text: this.i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: this.i18n.t('Confirm'),
          handler: (value) => {
            this.afterWakingUp(value)
   }
        }
      ],
      cssClass: `${this.homeUIService.isDark() ? 'dark-theme' : 'light-theme'}`,
    });

    await picker.present();
  }
  generateHourOptions() {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({
        text: i.toString(),
        value: i.toString().padStart(2, '0'),
      });
    }
    return options;
  }
  generateColumnOptions() {
    const options = [
      { text: '00', value: '00' },
      { text: '30', value: '30' },
    ];
    return options;
  }

  getColumns(numColumns, numOptions, columnOptions) {
    let columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push({
        name: `time`,
        options: this.getColumnOptions(i, numOptions, columnOptions),
        selectedIndex: this.itemPublish
      });
    }

    return columns;
  }

  getColumnOptions(columnIndex, numOptions, columnOptions) {
    let options = [];
    for (let i = 0; i < numOptions; i++) {
      options.push({
        text: columnOptions[columnIndex][i % numOptions],
        value: i
      })
    }

    return options;
  }

  nextSkipScreen(block = false) {
    if (block) {
      return false;
    }
    this.currentScreenIndex++;
    this.currentScreenId = this.screensIds[this.currentScreenIndex];
    this.showCurrentScreen();
  }

  selectOption(question, option) {
    this.answers[`Survey:${question.id}`] = option;

    // This function calls next screen on click
    this.nextScreen(!this.isAnswered(question));
  }
  isSelected(question, option) {
    return this.answers[`Survey:${question.id}`] === option;
  }
  isAnswered(question) {
    return !!this.answers[`Survey:${question.id}`];
  }
  selectTime(option) {
    if (this.confirmedTime) {
      return;
    }
    let time = "";
    switch (option) {
      case "AFTER_WAKING_UP":
        time = "07:00";
        this.morningTime = true;
        this.atWorkTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish = 14;
        break;
      case "AFTER_ARRIVING_AT_WORK":
        time = "09:00";
        this.atWorkTime = true;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish = 18;
        break;
      case "AFTER_LUNCH":
        time = "12:00";
        this.lunchTime = true;
        this.atWorkTime = false;
        this.morningTime = false;
        this.homeTime = false;
        this.bedTime = false;
        this.itemPublish = 24;
        break;
      case "AFTER_ARRIVING_HOME":
        time = "17:00";
        this.atWorkTime = false;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = true;
        this.bedTime = false;
        this.itemPublish = 34;
        break;
      case "BEFORE_BED":
        time = "22:00";
        this.atWorkTime = false;
        this.morningTime = false;
        this.lunchTime = false;
        this.homeTime = false;
        this.bedTime = true;
        this.itemPublish = 44;
        break;
      default:
        this.itemPublish = 22;
    }
    console.log(option, time);
    this.openPicker()
    this.selectedTimeOption = this.currIndex;
    
  }

  confirmTime() {
    this.confirmedTime = this.selectedTime
    
    this.appState.set('learningTime', this.confirmedTime)
    localStorage.setItem('learningTime', this.confirmedTime)

    localStorage.setItem('notificationsEnabled', 'true');
    localStorage.setItem('emailReminderEnabled', 'true');
    localStorage.setItem('socialReminderEnabled', 'true');
    localStorage.setItem('socialEmailReminder', 'true');

    this.initCalendarButton(); // LUMBA-1421
    if (this.isMobile) {
      let msg = this.i18n.t("On the next screen, you’ll get a prompt to allow notifications. These are for daily learning reminders and interactions with your coworkers. You’ll also see a button to add learning reminders to your calendar.")
      this.sessionService.presentAlert(this.confirmedTime, msg)
    }else{
      let msg = this.i18n.t("On the next screen, with just 1 click you can add reminders to your calendar at this time each day.")
      this.sessionService.presentAlert(this.confirmedTime, msg)
    }
    
  }
  unconfirmTime() {
    const previousTime = this.confirmedTime;
    this.confirmedTime = null;
  }
  questionAnswer(question) {
    const option = this.answers[`Survey:${question.id}`];
    return option && option.text ? option.text : "N/A";
  }


  permissionRequest(deviceToken : any = null) {
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.currentPath.id,
      pathData: {
        loginReminderEnabled: true,
        loginReminderTime: this.confirmedTime,
        deviceRegistrationId: deviceToken,
        emailReminderEnabled: this.emailReminderEnabled,
        pushReminderEnabled: true,
        socialReminderEnabled: true,
        socialEmailEnabled: true,
      }
    });

    this.loadingEnabling = true;
    this.userService.savePathRegistration(pathRegistration).subscribe(
      (response) => {
        this.isNextButtonAllowed = true;
        this.updatePathRegistration = response.data
        this.appState.set("pathRegistration", this.updatePathRegistration.updatePathRegistration);
      },
      (error) => {
        console.log("Server push Notifi error", error);
        this.toastrService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
        this.loadingEnabling = false;
      },
      () => {
        this.loadingEnabling = false;
      }
    );

    this.analyticsService.setOnboardingEvent('Onboarding', 'Notification button enabled', this.currentScreenId, this.currentPath.id, this.currentClient.id)

  }

  getToken() {
    let deviceToken;
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.platform.ready().then(() => {

        this.firebaseX.hasPermission().then((res)=>{
          this.firebaseX.grantPermission().then((permission) => {
            console.log("ios permission : ", permission);
          });
  
          this.firebaseX
            .getToken()
            .then((token) => {
              deviceToken = token
              localStorage.setItem("deviceToken", token);
              this.permissionRequest(deviceToken)
            })
            .catch((error) => console.error("Error getting token", error));
  
          this.firebaseX
            .onMessageReceived()
            .subscribe((data) => {
                let postData = JSON.parse(data?.keysandvalues)
              let notificationId = parseInt(data['gcm.message_id'])
              const requestData = {
                mode: postData?.mode,
                postId: postData?.post_id,
                id: parseInt(data['gcm.message_id']),
                route: postData?.route
              }
              this.appState.set('AfterAppInit', requestData)
              // "AfterAppInit" used for when app is not in BG

              // platform.resume() is used for when app is in BG
              this.platform.resume.subscribe(() => {
                let communitySavedId = parseInt(localStorage.getItem('CommunityNotificationId'))
                let commitmentsSavedId = parseInt(localStorage.getItem('CommitmentsNotificationId'))
                if (postData.route.match('/main/commitments') && notificationId && (notificationId > commitmentsSavedId || !commitmentsSavedId)) {
                  if (data['notification_foreground'] === 'true' && !data['tap']) {
                    localStorage.setItem('CommitmentsNotificationId', notificationId.toString())
                  } else {
                    localStorage.setItem('CommitmentsNotificationId', notificationId.toString())
                    this.homeUIService.openNextComponent(MyCommitmentsComponent);
                    //this.router.navigate(['/main/commitments']);
                  }
                }

                if (postData.route.match('/main/questions') && notificationId && (notificationId > communitySavedId || !communitySavedId)) {
                  if (data['notification_foreground'] === 'true' && !data['tap']) {
                    localStorage.setItem('CommunityNotificationId', notificationId.toString())
                  } else {
                    this.appState.set('communityNotification', true)
                    this.appState.set('notificationData', requestData)
                    if (this.router.url === '/main/questions') {
                      this.appState.set('isBackground', true)
                    } else {
                      // this.router.navigate(['/main/questions']);
                      this.homeUIService.openNextComponent(MobileChatBoardComponent);

                    }
                  }
                }
              })
              
              this.sessionService.removeNotificationBadge()
            }
            );
        }, (error)=> {
          console.log('permission error :', error);
        })
        
        this.firebaseX.onTokenRefresh().subscribe((token: string) => {
          deviceToken = token
          this.permissionRequest(deviceToken)
          localStorage.setItem("deviceToken", token);
        });
       
      }).catch(error => {
        console.log("platform  error : ", error);
      });

    } else {
       let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = this.pathRegistration.pathData.deviceRegistrationId;
    }
      this.permissionRequest(deviceId)
    }
  }


  viewVideo(url: string) {
    if (this.cordovaService.onCordova) {
      this.cordovaService.openBrowserLinkVideo(url,'isOnboarding');
     // this.isVideoViewed = true;
      mixpanel.track("Onboarding-clicked onboard video", {'url': url})
      // this.nextScreen()
      //this.checkScreenCompletion();
    } else {
      mixpanel.track("Onboarding-clicked onboard video", {'url': url, 'isVideoViewed': true})
      this.isVideoViewed = true;
      window.open(url, "_blank");
      // this.nextScreen()
       this.checkScreenCompletion();     
    }
    this.analyticsService.setOnboardingEvent('Onboarding', 'welcome video clicked', this.currentScreenId, this.currentPath.id, this.currentClient.id)
  }

  playRemoteVideo(url: string) {
    mixpanel.track("Onboarding-clicked onboard remote video", {'url': url})
    if (this.sessionService.confirmYoutubeUrl(url)) {
      if (this.platform.is('ios')) {
        try {
          // Try to run this code 
          this.youtubePlayer.openVideo(this.sessionService.getYouTubeVideoIdFromUrl(url));
        }
        catch(err) {
          // if any error, Code throws the error
          console.log("catch_block", err);
        }
      } else {
        // window.open(url, "_blank");
        this.cordovaService.openBrowserLinkVideo(url,'isOnboarding');
      }
      // this.isVideoViewed = true;
      // // this.nextScreen()
      // this.checkScreenCompletion();
    } else if(this.platform.is('android')){
      this.cordovaService.openBrowserLinkVideo(url,'isOnboarding');
      // this.isVideoViewed = true;
      // this.checkScreenCompletion();
      
    }
    else {
      if (!this.isMobile) {
        // this.router.navigate(['/video'], { state: { data: this.journalPosts } })
        this.isVideoViewed = true;
        window.open(url, "_blank");
        // this.nextScreen()
        this.checkScreenCompletion();
      }
      else {
        let options: StreamingVideoOptions = {
          successCallback: () => { console.log('Video played') },
          errorCallback: (e) => { console.log(e) },
          orientation: 'portrait',
          shouldAutoClose: true,
          controls: true
        };
        this.streamingMedia.playVideo(url, options);
        this.isVideoViewed = true;
        // this.nextScreen()
        this.checkScreenCompletion();
      }
    }
    this.analyticsService.setOnboardingEvent('Onboarding', 'welcome video clicked', this.currentScreenId, this.currentPath.id, this.currentClient.id)
  }

  addCalendarEvent() {
    try {
      this.createCalendarEvent();
    } catch (error) {
      this.toastrService.error(this.i18n.t('calendar: Something went wrong.'))
    }
    this.analyticsService.setOnboardingEvent('Onboarding', 'create calendarEvent clicked', this.currentScreenId, this.currentPath.id, this.currentClient.id)
  }


  initCalendarButton() {
    this.prepareCalendarParams();
    if (this.hasNativeCalendar) {
      return;
    }
    const calendarButtons = AddToCalendar.addToCalendar({
      options: {
        class: "mtr-btn",
        id: "add-calendar-buttons-id",
      },
      data: {
        title: this.calendarParams.title,
        start: this.calendarParams.startDate,
        // timezone: America/Los_Angeles,					// converts the time to the IANA timezone
        end: this.calendarParams.endDate,
        // duration: 120,                            // Event duration (IN MINUTES)
        //allday: true,													// Override end time, duration and timezone, triggers 'all day'
        address: this.calendarParams.eventLocation,
        description: this.calendarParams.notes,
        frequency: "DAILY",
        endDate: this.endDate,
      },
    });
    const parent = $(this.calendarButtonsContainer?.nativeElement);
    const w: any = window;
    w.closeCalenderOnMouseDown = this.cloudCalendarCollapser.bind(this);
    parent.empty();
    parent.append(calendarButtons);
    $("#checkbox-for-add-calendar-buttons-id").change(
      (event) => (this.cloudCalendarExpanded = event.target.checked)
    );
  }

  cloudCalendarCollapser() {
    const doCollapseCloudCalendar = () => {
      setTimeout(() => {
        $("#checkbox-for-add-calendar-buttons-id")[0].checked = false;
      }, 150);
      this.cloudCalendarExpanded = false;
      this.cloudCalendarSet = true;
      document?.removeEventListener("mousedown", doCollapseCloudCalendar);
    };
    document.addEventListener("mousedown", doCollapseCloudCalendar);
  }

  explore() {
    this.savingResults = true;
    this.stopInterval(); // Stop interval if started
    this.managerEmail = this.appState.get("managerEmail");
    let currentDay = this.appState.get("currentScheduleDay");
    if (this.answers) {
     this.surveyService
       .saveAnswers(this.answers, null, null)
       .subscribe(
         () => {
           this.savingResults = false;
           this.router.navigate(["/main"]);
         },
         () => {
           this.savingResults = false;
         }
       );
   } 
    
    else {
       this.router.navigate(["/main"]);
    }
     
    this.checkSubpathStatus();
  }

  checkScreenCompletion(ptype?) {
    if(this.confirmedTime) {
      const time = moment(this.confirmedTime, 'HH:mm');
      const timeString = time.format('HH:mm A');
      mixpanel.track("Onboarding-confirmTime", {'confirmTime': timeString})
    }
    console.log("currentScreenIndex", this.currentScreenIndex,this.screensIds.length);
    if (this.currentScreenIndex === this.screensIds.length - 1) {
      mixpanel.track(`Onboarding-${this.currentScreenId}`, {"isSkip": true})
      this.explore();
    } else {
      mixpanel.track(`Onboarding-${this.currentScreenId}`, {"isSkip": true})
      // if(ptype!='linear') {
        this.nextScreen();
      // }
    }
  }

  prepareCalendarParams(routinelen: any = null) {
    const daysCount = this.currentPath.daysOfContent * 1.5;
    if (!this.confirmedTime) {
      this.start = moment(new Date(), "HH:mm");
      this.end = moment(new Date(), "HH:mm").add(10, "minute");
      this.endDate = moment(new Date(), "HH:mm")
        .add(daysCount, "day")
        .toDate()
    } else {
      this.start = moment(this.confirmedTime, "HH:mm");
      this.end = moment(this.confirmedTime, "HH:mm").add(10, "minute");
      this.endDate = moment(this.confirmedTime, "HH:mm")
        .add(daysCount, "day")
        .toDate()
    }

    var titleOfEvent = "Daily Learning Reminder";
    
    try {
      if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
        titleOfEvent = this.currentPath.names.titleOfEvent;
      } else {
        titleOfEvent = "Daily Learning Reminder";
      }
    } catch (error) { }

    this.calendarParams = {
      startDate: this.start.toDate(),
      endDate: this.end.toDate(),
      title: titleOfEvent,
      eventLocation: "",
      notes: this.i18n.t(
        "Click here or open the Comet Pro app to take your next step: https://cometpro.page.link/rM3L"
      ),
      endEventTime: null,
      frequency: "DAILY"
    };

  }

  createCalendarEvent() {
    console.log('under createCalendarEvent');
    const success = function (message) {
      console.log("Success: " + JSON.stringify(message));
    };
    const error = function (message) {
      console.error("Error: " + message);
    };


    try {
      if (!this.confirmedTime) {
        this.toastrService.warning(this.i18n.t("Please select the time on previous screen!"))
        return
      } else {
        // create an event interactively
        const w: any = window;
        const calOptions = w.plugins.calendar.getCalendarOptions();
        const showAlertPrompt=true
        const daysCount = this.currentPath.daysOfContent * 1.5; // LUMBA-1399
        calOptions.recurrence = "DAILY";
        calOptions.recurrenceInterval = 1;
        calOptions.firstReminderMinutes = 0;
        calOptions.recurrenceEndDate = moment(this.confirmedTime, "HH:mm")
          .add(daysCount, "day")
          .toDate();
        this.calendarService.scheduleEvents(
          this.calendarParams.title,
          this.calendarParams.eventLocation,
          this.calendarParams.notes,
          this.calendarParams.startDate,
          this.calendarParams.endDate,
          calOptions,
          success,
          error,
          showAlertPrompt
        );
      }
    } catch (error) {
      console.log('error', error);
    }


  }

  checkSubpathStatus() {

    const request = {
      user_id: this.currentUser.id,
      active_path_id: this.currentUser.activePathId,
      isonboardingcompleted: true,
    };

    this.homeService.onbordingCompleted(request).subscribe(
      (response) => {
        this.analyticsService.setOnboardingEvent('Onboarding', 'Onboarding completed', this.currentScreenId, this.currentPath.id, this.currentClient.id)
      },
      (error) => {
        console.log("Response of subpath status ", error);
      }
    );
  }

  notificationAlertBox() {
    let dialogRef = this.dialog.open(AlertNotifyComponent);
    dialogRef.afterClosed().subscribe((result) => { });
  }

  chooseOption() {
    this.stopInterval();
    let dialogRef = this.dialog.open(AlertProfilePictureComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.isNewPhoto = true;
      this.refresh();
    });
  }

  refresh() {
    this.dataRefresher = setInterval(() => {
      this.currentUser.imageUrl = this.sessionService.url;
      if (this.isNewPhoto && this.currentUser.imageUrl) {
        this.spinnerService.on();
        this.updateProfile();
        this.isPhotoUploaded = true;
        this.currentUser.imageUrl = null;
        this.isNewPhoto = false;
      }
    }, 500);
  }

  stopInterval() {
    if (this.dataRefresher) {
      clearInterval(this.dataRefresher);
    }
  }

  updateProfile() {
    this.spinnerService.on();
     delete this.currentUser.userBgColor;
    this.userService.save(this.currentUser).subscribe((response) => {
      this.userUpdate = response.data
      const updatedUser = new User(this.userUpdate.updateUser);
      this.currentUser = updatedUser;
      this.label = "";
      this.appState.set("currentUser", updatedUser);
      localStorage.setItem('userid', updatedUser.id.toString());
      this.spinnerService.off();
      this.isPhotoUploaded = true;
      
      this.toastrService.success(this.i18n.t("Profile Picture Added!"));
      //this.stopInterval()
    });
  }

  addAccountablityEmail(email: any) {
    if (this.sessionService.validateEmail(email)) {
      this.arrayOfAccountabilyEmails.push(email);
      this.accountablityEmail = "";
    } else {
      this.toastrService.warning(this.i18n.t("Please enter valid email!"));
    }

  }

  removeEmail(email: any) {
    const index = this.arrayOfAccountabilyEmails?.indexOf(email);
    if (index > -1) {
      this.arrayOfAccountabilyEmails.splice(index, 1);
    }
  }

  pushAccountablityEmails(currentDay?) {

    if (this.arrayOfAccountabilyEmails && this.arrayOfAccountabilyEmails?.length > 0) {

      var data = {
        id: this.currentUser.id,
        accountability_email: this.arrayOfAccountabilyEmails,
        current_day: currentDay,
      };

      this.homeService.accountablityEmailAPI(data).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
      this.analyticsService.setOnboardingEvent('Onboarding', 'Accountablity emails added', this.currentScreenId, this.currentPath.id, this.currentClient.id)

    }
  }

  playVideo() {
    if (this.currentPath.names["isIntroVideo"]) {
      this.screensIds.push("survey-screen-video-intro");
    }
  }

  receiveData($event) {
    this.dataFromChild = $event;
  }

  receiveScale(event) {
    this.scaleData = event.scaleValue;
    this.setScale(event.question,event.higherScale)
  }

  saveQuestionData(option: any) {
    if (this.scaleData && option.ptype == 'linear') {
       mixpanel.track("Onboarding-linear", {'linearData': option.options})
      // this.setScale(option)
      this.checkScreenCompletion()
    } else {
      // this.nextScreen()
      this.checkScreenCompletion();
    }
    this.isRankSwaped = false
  }

  setScale(option: any, higherScale?) {
   // this.spinnerService.on()
    const requestData = {
      'path_id': this.currentPath.id,
      'user_id': this.currentUser.id,
      'schedule_day': this.appState.get('currentScheduleDay'),
      'linear_rating': this.scaleData,
      'linear_question_id': option.id,
    }

    if (!requestData) {
      this.spinnerService.off();
    } else {
      this.pollService.setOnboardingLinearScale(requestData).subscribe(
        (response) => {
          this.responseData = response.data
          this.spinnerService.off();
          for (let linear of this.responseData ) {
            this.pathAverage = linear?.path_average+'/'+higherScale;
          }
          this.blurCard(true,'linear',this.scaleData+"/"+higherScale,this.pathAverage)
          this.cdRef.detectChanges()
          // this.checkScreenCompletion(option?.ptype)
          this.analyticsService.setOnboardingEvent('Onboarding', 'Linearscale completed', this.currentScreenId, this.currentPath.id, this.currentClient.id)
        },
        (error) => {
          this.spinnerService.off();
          console.log(error);
        }
      )
    }
  }

  leftSwipeAnimation() {
    this.rightState = false;
    setTimeout(() => {
      this.rightState = true;
    }, 1);
  }

  rightSwipeAnimation() {
    this.state = false;
    setTimeout(() => {
      this.state = true;
    }, 1);
  }

  async chooseUploadOption() {
    this.stopInterval()
    const fileResult = await this.native.showActionSheetForFiles();
    // this.spinnerService.on();
    if (fileResult) {
      mixpanel.track('Onboarding-profile image uploaded', {'profileImage': fileResult})
      this.isNewPhoto = true;
      this.uploadPhoto(fileResult);
      this.analyticsService.setOnboardingEvent('Onboarding', `Profile image uploaded`, this.currentScreenId, this.currentPath.id, this.currentClient.id)

    } else {
      this.spinnerService.off();
    }
  }

  fileChangeEvent(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.spinnerService.on();
    this.uploadPhoto(files[0]);
  }

  uploadPhoto(file: any) {
    const myFormData = new FormData()
    if (this.isMobile) {
      myFormData.append('file', file.blob, file.blob.name);
    mixpanel.track('Onboarding-profile image uploaded', {'profileImage': myFormData})
    } else {
      myFormData.append('file', file, file.name);
      mixpanel.track('Onboarding-profile image uploaded', {'profileImage': myFormData})
    }
    this.spinnerService.on();
    this.userService.postProfilePic(myFormData).subscribe((result) => {
      this.spinnerService.off();
      this.sessionService.url = result['data']['url']
      this.currentUser.imageUrl = this.sessionService.url
      this.updateProfile()

    }, error => {
      this.spinnerService.off();
      this.toastrService.success(this.i18n.t(error))
    })
  }

  getStats(pathId) {
    this.statService.getList(pathId)
      .then(response => {
        this.stats = response
        this.getCurrentUser(this.stats)
      })
      .catch(error => {
        this.stats = error
      })

  }

  getCurrentUser(stats: any) {
    var current_path_user = []
    current_path_user = stats.leaderboard.current_path_user

    try {
      this.thisPathUserRank = current_path_user.findIndex(element => element.isMe)
      let thisUser = current_path_user.filter(element => element.isMe)
      this.thisPathUser = thisUser[0]
    } catch (error) {
      console.log('error catch', error);

    }
  }
  async getTokenAfterLogin() {
    await this.platform.ready().then(() => {
      this.firebaseX
        .getToken()
        .then((token) => {
          localStorage.setItem("deviceToken", token);
          console.log(`The token is ${token}`);
        })
        .catch((error) => console.error("Error getting token", error));

      this.firebaseX.onTokenRefresh().subscribe((token: string) => {
        localStorage.setItem("deviceToken", token);
        console.log("New device token : ", token);
      });
    }).catch(error => {
      console.log("platform  error : ", error);
    });
  }

  toggle(){
    if (this.pushNotificationsEnabled && this.cordovaService.isIOS) {
        this.notificationAlertBox() 
    }
    this.save()
  }

  save() {
    this.getTokenAfterLogin()
     let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = this.pathRegistration.pathData.deviceRegistrationId;
    }
    let savedPathRegistration = this.appState.get('pathRegistration')    
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.currentPath.id,
      pathData: {
        loginReminderEnabled: savedPathRegistration.pathData.loginReminderEnabled,
        loginReminderTime: this.confirmedTime,
        deviceRegistrationId: deviceId,
        emailReminderEnabled: this.emailReminderEnabled,
        pushReminderEnabled: savedPathRegistration.pathData.pushReminderEnabled,
        socialReminderEnabled: savedPathRegistration.pathData.socialReminderEnabled,
        socialEmailEnabled: savedPathRegistration.pathData.socialEmailEnabled
      }
    })

    this.loading = true
    this.appState.set('loginReminderEnabled', this.pushNotificationsEnabled)
    this.appState.set('emailReminderEnabled', this.emailReminderEnabled)
      this.spinnerService.on()
      this.userService.savePathRegistration(pathRegistration).subscribe(
        (response) => {
          this.updatePathRegistration = response.data
          this.appState.set("pathRegistration", this.updatePathRegistration.updatePathRegistration);
          this.toastrService.success(this.i18n.t('Settings updated!'))
        },
        error => {
          this.spinnerService.off()
          console.log('Server error', error)
          this.loading = false
          this.toastrService.error(this.i18n.t('Settings not saved. Please check your internet connection.'))
        },
        () => {
          this.spinnerService.off()
          this.loading = false
        }
      )
    
  }

  loadSvgFile(path) {
    this.http.get(path, { responseType: 'text' })
      .subscribe(svgData => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
        const primaryColor = getComputedStyle(document?.documentElement)?.getPropertyValue('--theme-color');
        let pathElements = svgDoc.querySelectorAll('stop');
       pathElements?.forEach(pathElement => {
        const currentColor = pathElement?.getAttribute('stop-color')
        if(pathElement?.getAttribute('stop-color')=='#8077E7'){
          pathElement?.setAttribute('stop-color', primaryColor); // Use the var(--color) notation to set the fill color dynamically         
        }
      });   
      const serializer = new XMLSerializer();
      const modifiedSvgData = serializer.serializeToString(svgDoc);
      const encodedData = encodeURIComponent(modifiedSvgData);
       if(path =='../../assets/web_home_ui/black_play_btn.svg'){
        this.startPlayButton =`data:image/svg+xml,${encodedData}`;
      }   
      });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.disableCard?.nativeElement?.contains(event.target)) {
       this.enableBlur =false;
      this.blurCard(this.enableBlur,'linear',this.scaleData, this.pathAverage);
    }
    if(this.disableMcqCard?.nativeElement?.contains(event.target)) {
      this.enableMcqBlur =false;
     this.blurCard(this.enableMcqBlur,'mcq',this.scaleData, this.pathAverage);
   }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
