import { ChangeDetectorRef, Component, DoCheck, EventEmitter, HostListener, OnInit, Output, ViewChild, Input, AfterViewInit, ElementRef, Renderer2, ViewChildren, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.service';
import { UtilityService } from 'src/app/common-services/utility.service';
import { Habit } from 'src/app/habit/habit.model';
import { I18n } from 'src/app/i18n.service';
import { Path } from 'src/app/path/path.model';
import { StatService } from 'src/app/stat/stat.service';
import { Document } from 'src/app/document/document.model';
import { CohortService } from 'src/app/cohorts/cohort.service';
import { ClientService } from 'src/app/client/client.service';
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model';
import * as _ from 'underscore';
import { CordovaService } from 'src/app/cordova.service';
import { HomeUiService } from '../home-ui.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionRescheduleComponent } from 'src/app/to-do-list/action-reschedule/action-reschedule.component';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import { IonItemSliding, ModalController } from '@ionic/angular';
import { User } from 'src/app/user/user.model';
import { FeedbackFormComponent } from 'src/app/components/commitments/feedback-form/feedback-form.component';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { Reply } from 'src/app/reply/reply.model';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { Platform } from '@ionic/angular';
import { FullScreenFeedComponent } from 'src/app/full-screen-feed/full-screen-feed.component';
import { MobileChatServiceService } from 'src/app/pages/mobile-chat-board//mobile-chat-service.service'
import { ReplyService } from '../../reply/reply.service'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FullScreenRoutineComponent } from 'src/app/full-screen-routine/full-screen-routine.component';
import { LibraryService } from 'src/app/my-library/library.service';
import { SessionService } from 'src/app/session/session.service';
import { SpinnerService } from 'src/app/spinner.service';
import { JournalService } from 'src/app/pages/journal/journal.service';
import { ToasterService } from 'src/app/toaster.service';
import { MyLibraryComponent } from 'src/app/my-library/my-library.component';
import mixpanel from 'mixpanel-browser';
import { YourProgressComponent } from '../your-progress/your-progress.component';
import { MyCommitmentsComponent } from 'src/app/my-commitments/my-commitments.component';
import { MobileChatBoardComponent } from 'src/app/pages/mobile-chat-board/mobile-chat-board.component';
import { StatListComponent } from 'src/app/stat/stat-list.component';
import { YourPathComponent } from '../your-path/your-path.component';
import { AnimationsService } from 'src/app/shared/animations.service';
import { UserComponent } from 'src/app/user/user.component';
import { HttpClient } from '@angular/common/http';
import { CompletedReviewModalComponent } from 'src/app/completed-review-modal/completed-review-modal.component';
import { HabitService } from 'src/app/habit/habit.service';
import { FullScreenStepComponent } from 'src/app/my-library/full-screen-step.component';
import { FullScreenReflectionComponent } from 'src/app/my-library/full-screen-reflection/full-screen-reflection.component';

@Component({
  selector: 'app-home-sheet-modal',
  templateUrl: './home-sheet-modal.component.html',
  styleUrls: ['./home-sheet-modal.component.scss']
})

export class HomeSheetModalComponent implements OnInit, DoCheck, AfterViewInit {
  @ViewChild('modal') modal: HTMLIonModalElement;
  @ViewChild('communitywrapper') communitywrapper: ElementRef
  @ViewChildren('slidingItem') slidingItem: IonItemSliding[];
  @ViewChildren('contentContainer') contentContainer: ElementRef[];
  @ViewChild('leaderboardwrapper') leaderboardwrapper: ElementRef
  isMobile: boolean
  @Output() changeBreakPoint = new EventEmitter<any>();
  @Output() onLogaRep: any = new EventEmitter<any>();
  @Output() onTextClick = new EventEmitter<any>();
  @Input() item: any; 
  percentage: number
  currentActionPoints
  height = 0;
  newWidth = 3;
  path: Path
  thisPathUserRank: any
  thisPathUser: any
  stats: object
  inoutScreens: InoutScreen[]
  scheduleDay: number
  currentInoutDay: any
  upComingDay: any
  currentDay: number
  todoRoutines: any
  todoActions: any
  actionId: number
  enableHandler;
  checked: boolean = false
  checkedHabit: boolean = false
  currentUser: User
  todoLevelsList: any
  levelId: number
  thisPathFirst: any
  thisPathSecond: any
  thisPathThird: any
  singleHabit
  user;
  commentCount=0;
  collegues_len;
  colleagueList;
  isModalOpen = false;
  public repliesTree: Reply[]
  feedsList: any[] = [];
  page: number
  isLoading=false;
  showNoDataMsg: boolean = false
  isMobileWebIos = false;
  isMobileWeb=false;
  communityNotification: boolean
  notificationData: any
  isRoutineV2: any;
  voteLoading: boolean = false;
  destroyed$ = new Subject<boolean>();
  filteredFeedList: any[] = [];
  isProfileClicked=false;
  currentClient;
  savedPosts;
  recommentedPosts;
  showPosts =[];
  tabLabel;
  yourProgress= YourProgressComponent;
  yourPath= YourPathComponent;
  commitment= MyCommitmentsComponent;
  community= MobileChatBoardComponent;
  library= MyLibraryComponent;
  leaderboard= StatListComponent;
  learningMinutes: any
  currentCompletedDay: number
  pathRegistration: any
  currentBreakpoint;
  startY;
  webFeedList: any[] = [];;
  showSingleWebFeedList =0;
  @ViewChild('modalContent') modalContent: ElementRef;
  userComponent = UserComponent;
  focusCommunity =false;
  rightSwipe;
  leftSwipe;
  yesterdayHabit: any;
  @ViewChild('slider') slider;
  currentPath;
  web_todoLevelsList =[];
  habitCommitmentIndex;
  actionCommitmentIndex;
  isSliderClicked =false;
  constructor(private route: Router,
    private appState: AppState,
    private i18n: I18n,
    private statService: StatService,
    private utilService: UtilityService,
    public cohortService: CohortService,
    private cordovaService: CordovaService,
    public homeUIService: HomeUiService,
    private routineV2Service: RoutineV2Service,
    private router: Router,
    private homev5Service: HomeV5Service,
    private platform: Platform,
    private mobileService: MobileChatServiceService,
    private cdRef: ChangeDetectorRef,
    private replyService: ReplyService,
    public spinnerService: SpinnerService,
    public render: Renderer2,
    public animationService: AnimationsService,
    public journalService: JournalService,
    public toasterService: ToasterService,
    private dialog: MatDialog,
    public libraryService: LibraryService,
    public sessionService: SessionService,
    private dailyStepService: DailyStepsService,
    private http: HttpClient,
    private habitService: HabitService,
    private ngZone: NgZone,
    public modalController: ModalController,) 
    {
    this.currentPath = this.appState.get('currentPath')
    this.path = this.appState.get('currentPath')
    this.currentUser = this.appState.get('currentUser')
    this.isMobile = this.appState.get('isMobile')
    this.getStatsList(this.path.id)
    this.inoutScreens = this.path.inoutScreens
    this.scheduleDay = this.appState.get("scheduleDayForCurrentStep")
    this.currentDay = this.appState.get('currentScheduleDay')
    this.currentCompletedDay = parseInt(localStorage.getItem('stepCompleted'))
    this.currentInoutDay = this.findForCurrentDay(this.path.inoutScreens)
    this.upComingDay = this.findForNextDay(this.path.inoutScreens)
    this.todoRoutines = this.appState.get('todoRoutines')
    this.todoActions = this.appState.get('todoActions')
    this.currentClient = this.appState.get('currentClient')
    this.user = this.appState.get("currentUser");
    this.page = this.appState.get('page')
    this.isRoutineV2 = this.appState.get("routineVersion")
    if (!this.page) {
      this.page = 1
    }
    this.getFeeds(this.path.id, this.user.id, false, null, this.page, true);
    this.getLearningMinute()
    this.pathRegistration = this.appState.get('pathRegistration')
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    console.log("this.user", this.user, this.currentPath.dailySteps);
    this.singleHabit = this.item;
    this.collegues_len= this.item?.colleagues_count;
    this.sessionService.getLibraryPosts();
    this.setYesterdayHabit()
    this.homeUIService.getAllRoutines(this.currentUser.id, this.path.id)
  }

  ngAfterViewInit(): void {
    this.selectedTabData()
    this.ngZone.runOutsideAngular(() => {
      // Delay the animation for a smoother transition
      
      setTimeout(() => {
        this.ngZone.run(() => {
          if(this.communitywrapper) {
            this.communitywrapper['el'].style.display='flex'
            this.communitywrapper['el']?.classList?.add('fade-in');
          }        
        });
      }, 700);

      setTimeout(() => {
        this.ngZone.run(() => {
          if(this.leaderboardwrapper) {
            this.leaderboardwrapper['el'].style.display = 'block';
            this.leaderboardwrapper['el'].classList.add('fade-in');
          }
        });
      }, 1000);
    });
  }
  slideChanged()
  {
    setTimeout(()=>{
      this.slider.startAutoplay()
    },550)
  }
 
  openOptions(item: IonItemSliding,index,type) {
    // Close any open sliding item first
    this.closeOpenItems(index);
    if(type=='action'){
      this.actionCommitmentIndex =index;
    }
    else {
      this.habitCommitmentIndex =index;
    }
    // Open the selected sliding item's options
    item.open('start');
  }

  closeOpenItems(index?) {
    // Close any open sliding item
    if(this.slidingItem){
      this.slidingItem.forEach((item)=>{
       item.closeOpened();
       this.actionCommitmentIndex =null;
       this.habitCommitmentIndex = null;
      });
    }   

  }

  loadSvgFile(path,pos) {
    this.http.get(path, { responseType: 'text' })
      .subscribe(svgData => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
        const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');
        let pathElements = svgDoc.querySelectorAll('path');
       pathElements?.forEach(pathElement => {
        if(pathElement.style?.stroke){
          pathElement.style.stroke = primaryColor; // Use the var(--color) notation to set the fill color dynamically
         
        }
      
      });   
      const serializer = new XMLSerializer();
      const modifiedSvgData = serializer.serializeToString(svgDoc);
      const encodedData = encodeURIComponent(modifiedSvgData);

      if(pos=='left'){
        this.leftSwipe = `data:image/svg+xml,${encodedData}`;
      }
      else {
       this.rightSwipe = `data:image/svg+xml,${encodedData}`;
      }    
      });
   
    
  }
  changeFont() {
  this.contentContainer?.forEach((content)=>{
    const element = content?.nativeElement;
    const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
    if(specificElement) {
      this.render.setStyle(specificElement, 'font-size', '20px');
      this.render.setStyle(specificElement, 'font-weight', '700');
      this.render.setStyle(specificElement, 'font-style', 'normal');
      this.render.setStyle(specificElement, 'line-height', 'normal');
      this.render.setStyle(specificElement, 'font-family', 'Roboto');
      this.render.setStyle(specificElement, 'word-break', 'break-word');
    }
  })
  return true;
  }
  
  async selectedTabData() {
    if(this.platform.is("ios") || this.platform.is("android")) {
    this.tabLabel = this.appState.get('tabLabel')
    this.appState.set('prevPathData', undefined)
    if (this.tabLabel == 'saved') {
      this.appState.set('tabLabel', 'saved')
      this.getPostsList()
      this.showPosts = this.savedPosts;

    }
    else {
      this.appState.set('tabLabel', 'recommended')
    this.getCasualDocuments(this.currentClient.id, this.currentUser.id)
      this.showPosts = this.recommentedPosts;
    }
  }
  else {
    this.getCasualDocuments(this.currentClient.id, this.currentUser.id)
    await this.getPostsList()
   
  }

  }

  sortDataByTitle(): void {
    this.showPosts.sort((a, b) => {
      const titleA = a.title?.replace(/<\/?[^>]+(>|$)/g, "")?.toLowerCase();
      const titleB = b.title?.replace(/<\/?[^>]+(>|$)/g, "")?.toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  }

  sortToolsByTitle(): void {
    this.recommentedPosts.sort((a, b) => {
      const titleA = a.title.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase();
      const titleB = b.title.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  }
  
  ngDoCheck() {
    this.singleHabit = this.item;
    this.todoActions = this.appState.get('todoActions')
    this.todoRoutines = this.appState.get('todoRoutines')
    let compStatus = this.appState.get("to-do-comp");
    if (compStatus) {
      this.closeOpenItems();
      this.actionCommitmentIndex = null;
      this.habitCommitmentIndex = null;
      this.appState.set("to-do-comp", false);
    }
    this.todoLevelsList = this.homeUIService.getRoutinesByCurrentDay(this.todoRoutines)
    this.tabLabel = this.appState.get('tabLabel')
    this.currentActionPoints = this.appState.get('currentActionPoints')
    if(this.appState.get('isLibraryRefresh')) {
      this.appState.set("isLibraryRefresh",false);
      this.getCasualDocuments(this.currentClient.id, this.currentUser.id)
    }
    if (this.appState.get('isBackground')) {
      this.appState.set('isBackground', undefined)
        this.navigateToPost(this.feedsList)
        
      } 
    if(this.appState.get("libraryTabChanged") ) {
     this.selectedTabData();
     this.appState.set("libraryTabChanged",false) 
    }  
    this.currentDay = this.appState.get('currentScheduleDay')
    this.scheduleDay = this.appState.get("scheduleDayForCurrentStep")
    this.currentCompletedDay = parseInt(localStorage.getItem('stepCompleted'))
    this.currentInoutDay = this.findForCurrentDay(this.path.inoutScreens)
    this.upComingDay = this.findForNextDay(this.path.inoutScreens)
    this.getLearningMinute()
    if(this.appState.get('postSaved')) {
      setTimeout(() => {
        this.getPostsList()
      })
    }
    if(this.appState.get('updateActionPoints')){
      this.appState.set('updateActionPoints', undefined)
      this.getStatsList(this.currentPath.id)
    }
  }
  getCasualDocuments(clientId,userId){
   
    let requestData = {
      'clientId': clientId,
      'userId': userId
    }
    this.recommentedPosts=[];
    if (requestData) {
      this.libraryService.getCasualDocuments(requestData).subscribe((documents) => {
        if(this.showPosts?.length>3) {
          this.showPosts = this.showPosts.slice(0,3);
         } 
          documents?.data?.forEach((doc)=>{
            if(doc?.isrecommended){
              this.recommentedPosts.push(doc);
              this.showPosts= this.showPosts.concat(doc);
            }
          });
         if(this.showPosts?.length>5 && !this.isMobile){
          this.showPosts = this.showPosts.slice(0,5);
         }
         this.sortDataByTitle();
         this.sortToolsByTitle();
        if(this.recommentedPosts?.length>=2){
          this.recommentedPosts = this.recommentedPosts.slice(0,2);
        }
      })

    }
  }

  getPostsList() {
    this.appState.set('postSaved',undefined)
    let journalPost;
    this.savedPosts =[];
    this.journalService.getList().subscribe((journalPosts) => {
      this.appState.set('dontRefresh', false)
    
      this.appState.set('savedPosts', journalPosts)
      if(this.showPosts?.length>2) {
        this.showPosts = this.showPosts.slice(0,2);
       } 
      //this.cdRef.detectChanges()
     journalPost = journalPosts;
     if(journalPost?.length>0){
      journalPost.forEach((post)=>{
        if((post?.documents?.length>=1 &&post?.documents[0]?.isRecommended !=true ) ||post?.documents?.length<1 ){
          this.showPosts = this.showPosts.concat(post);
          this.showPosts = this.homev5Service.removeDuplicates(this.showPosts ,'id')
          // console.log('showPosts :', this.showPosts);
        }
        if(post?.documents?.length>=1 && post?.documents[0]?.isRecommended !=true && (this.savedPosts==undefined ||this.savedPosts?.length<2)){
          this.savedPosts.push(post);
        }
        else if(post?.documents?.length<1 &&(this.savedPosts==undefined ||this.savedPosts?.length<2)){
          this.savedPosts.push(post);
        }
      })
    }
    if(this.showPosts?.length>5){
      this.showPosts = this.showPosts.slice(0,5);
     }
    },
      (error) => {
        // this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
        console.log('error :', error);
      })
  }

  openReflection(post: any){
    this.appState.set('journalPost', post)
    if (this.isMobile) {
      this.homeUIService.openNextComponent(FullScreenReflectionComponent);
    } else {
      this.homeUIService.openNextComponent(FullScreenReflectionComponent,'','desktop-library-custom');
    }
  }

  getAllPosts(post) {
    this.appState.set('journalPost', post)
    this.appState.set('singleDocNote', post.text)
    mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
    setTimeout(()=>{
      if (this.isMobile) {
        this.homeUIService.openNextComponent(FullScreenStepComponent);
      } else {
        this.homeUIService.openNextComponent(FullScreenStepComponent,'','desktop-library-custom');        
      }
    },100);
  }
  findForCurrentDay(collection: any): any {
    return collection.find(item => item.startDay === this.scheduleDay && 
      this.scheduleDay === item.endDay)
  }

  findForNextDay(collection: any): any {
    return collection.find(item => item.startDay <= this.scheduleDay+1 && 
      this.scheduleDay+1 <= item.endDay)
  }
 onLeftSliderEnter() {
  this.focusCommunity =true
   this.slider.stopAutoplay();
  if(this.rightSwipe ==undefined || this.leftSwipe == undefined){
    this.loadSvgFile('../../../assets/web_home_ui/left_swipe_arrow.svg','left')
    this.loadSvgFile('../../../assets/web_home_ui/right_swipe_arrow.svg','right')
  
  }

}

onLeftSliderLeave() {
  this.focusCommunity = false
  this.slider.startAutoplay();
}

  onScroll(ev){   
      if(this.currentBreakpoint !=0.25){
 
       if(this.enableHandler && (ev.target.scrollTop==0 && this.height>ev.target.scrollTop)){
        document.getElementById('modalGrid').classList.remove('expanded-sheet')
        document.getElementById('modalGrid')?.setAttribute('style','pointer-events:none;')
        this.touchEnd(ev);
       }
        this.height =ev.target.scrollTop;
        if(this.height <=0) {
          if(this.enableHandler){
            this.enableHandler =false;
          }
          else {
          document.getElementById('modalGrid')?.setAttribute('style','pointer-events:auto;')

          }
          document.getElementsByClassName('dynamic-modal')[0]?.setAttribute('style','--height:86% !important;--border-radius: 30px !important;pointer-events: none;')
        }
        
        else {
          document.getElementsByClassName('dynamic-modal')[0]?.setAttribute('style','--height:86% !important;--border-radius: 0px !important;pointer-events: none;')
    
        }
      }
  }
 touchStart (ev) {
  this.startY = ev.touches[0].clientY;
//  document.getElementById('modalGrid')?.setAttribute('style','pointer-events:none;')
 }

  disableDrag(ev){
   const currentY = ev.touches[0].clientY;
   const deltaY = currentY - this.startY;
   if (deltaY > 0 && this.height<=0) {
     // Your logic for swiping down
     document.getElementById('modalGrid')?.classList?.remove('expanded-sheet')
      document.getElementById('modalGrid')?.setAttribute('style','pointer-events:none;')
   }
   else {
    document.getElementById('modalGrid')?.setAttribute('style','pointer-events:auto;')

   }
     if(this.height >0){
      this.enableHandler =true;
      ev.stopPropagation();
    }
  }
  touchEnd(ev) {
    if(ev && this.currentBreakpoint ==1){
      document.getElementById('modalGrid')?.classList?.add('expanded-sheet')
    }
    document.getElementById('modalGrid')?.setAttribute('style','pointer-events:auto;')
  }
  onChange(ev) {
    this.changeBreakPoint.emit({
      breakpointValue :ev.detail.breakpoint
    })
    this.currentBreakpoint =ev.detail.breakpoint
    if(ev.detail.breakpoint === 1){    
      ev.target.scrollTop =0;
      document.getElementById('modalGrid')?.classList?.add('expanded-sheet')
      document.getElementsByClassName('dynamic-modal')[0]?.setAttribute('style','--height:86% !important;--border-radius: 30px !important;transition: linear;transition-property: all;transition-delay: 0s;transition-duration: 0.4s;position: absolute;right: 0;left: 0;')
    }else{
          this.enableHandler= false;
          this.height=0;
    
        document.getElementById('modalGrid')?.classList?.remove('expanded-sheet')
  
      document.getElementsByClassName('dynamic-modal')[0]?.setAttribute('style','--border-radius: 30px !important;')

    }
  }

  closeModal() {
    this.modal.dismiss(null, 'cancel');
  }

  returnPathPercentage(){
    try {
      const totalCount = this.path.daysOfContent
      let screensViewed = this.appState.get('screensViewed')
      if (screensViewed) {
        let stepsArray = this.homev5Service.removeDuplicates(screensViewed, "step")
        let completedSteps = []
        stepsArray.forEach(element => {
          if (element.visitedScreens === element.totalScreens) {
            completedSteps.push(element)
          }
        });
        const completedTaskCount = completedSteps.length
        this.percentage = (completedTaskCount / totalCount) * 100
      } else {
        let stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed, "step")
        let completedSteps = []
        stepsArray.forEach(element => {
          if (element.visitedScreens === element.totalScreens) {
            completedSteps.push(element)
          }
        });
        const completedTaskCount = completedSteps.length
        this.percentage = (completedTaskCount / totalCount) * 100
      }
      this.appState.set('pathPercentage', Math.ceil(this.percentage))
      return Math.ceil(this.percentage)
    } catch (error) { }
  }

  getStatsList(pathId) {
    this.statService.getList(pathId)
      .then(response => {
        this.stats = response
        this.thisPathUserRank = this.utilService.getCurrentUserRank(this.stats)
        this.thisPathUser = this.utilService.getCurrentPathUser(this.stats)
        this.getUserStats(this.stats)
        this.appState.set('currentRank',this.thisPathUserRank)
        this.appState.set('currentActionPoints', this.thisPathUser?.actionPoints)
        this.currentActionPoints = this.thisPathUser?.actionPoints;
        this.appState.set('statsForActivities',this.stats)
      })
      .catch(error => {
        this.stats = error
      })
  }

  getCircumference(): number {
    return 2 * Math.PI * 45;
  }

  getStrokeOffset(): number {
    const circumference = this.getCircumference();
    return circumference - (circumference * this.percentage) / 100;
  }

  getTagForCurrentDay(currentDay): any {
    let tag;
    this.inoutScreens.forEach(element => {
      if (currentDay === element.startDay) {
        tag = element.tag
      }
    });
    if(tag === 0){
      tag = this.i18n.t('Action');
    }else if(tag === 1){
      tag = this.i18n.t('Story');
    }else if(tag === 2){
      tag = this.i18n.t('Reflection');
    }
    return tag;
  }

  getTagForNextDay(currentDay): any {
    let tag;
    this.inoutScreens.forEach(element => {
      if (currentDay === element.startDay) {
        tag = element.tag
      }
    });
    if(tag === 0){
      tag = this.i18n.t('Action');
    }else if(tag === 1){
      tag = this.i18n.t('Story');
    }else if(tag === 2){
      tag = this.i18n.t('Reflection');
    }
    return tag;
  }

  goToStep(inout){
    this.closeModal()
    if(this.currentDay >= inout.startDay){
      this.route.navigate(['/content', inout.startDay])
    }
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }
  disableComponentNav(status){
   if(status?.isClicked){
      this.isProfileClicked =true;
    }
  }
  async openComponent(comp,event=null){
    if(!this.voteLoading && !this.isProfileClicked){
    mixpanel.track('Home-Screen-Modal-Widget', {'Screen Modal Component': comp.name == 'YourPathComponent' ? 'Your Path' : comp.name == 'YourProgressComponent' ? 'Your Progress' : comp.name == 'MyCommitmentsComponent' ? 'Commitment' : comp.name == 'MobileChatBoardComponent' ? 'Community' : comp.name == 'MyLibraryComponent' ? 'Library' : comp.name == 'StatListComponent' ? 'Leaderboard' : '' })
    if (event) {
      const pathWidgetCard = document.getElementById('pathWidget');
      if (pathWidgetCard && pathWidgetCard.contains(event.target as Node)) {
        this.goToStep(this.currentInoutDay);
      } else {
        const modal = await this.modalController.create({
          component: comp,
          cssClass: 'custom-modal',
          enterAnimation: this.animationService.slideInLeftEnterAnimation,
          leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
        });
        this.homeUIService.setModalStack(modal);
        await modal.present();
      }
    } else {
      const modal = await this.modalController.create({
        component: comp,
        cssClass: 'custom-modal',
        enterAnimation: this.animationService.slideInLeftEnterAnimation,
        leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
      });
      this.homeUIService.setModalStack(modal);
      await modal.present();
    }
   }
   else {
      this.isProfileClicked =false;
   }
  }
  
  async openWebComponent(comp, customClass?, event = null, compProps?) {
    if (this.isModalOpen) {
      return
    }
    this.closeOpenItems();
    if (!this.voteLoading && !this.isProfileClicked && !this.isSliderClicked) {
         this.isModalOpen = true;
      mixpanel.track('Home-Screen-Modal-Widget', {'Screen Modal Component': comp.name == 'YourPathComponent' ? 'Your Path' : comp.name == 'YourProgressComponent' ? 'Your Progress' : comp.name == 'MyCommitmentsComponent' ? 'Commitment' : comp.name == 'MobileChatBoardComponent' ? 'Community' : comp.name == 'MyLibraryComponent' ? 'Library' : comp.name == 'StatListComponent' ? 'Leaderboard' : '' })
      if (event) {
        const pathWidgetCard = document.getElementById('pathWidget');
        if (pathWidgetCard && pathWidgetCard.contains(event.target as Node)) {
          this.goToStep(this.currentInoutDay);
        } else {
          console.log("comp", comp, comp !== '')
          const modal = await this.modalController.create({
            component: comp,
            cssClass: customClass,
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
          });
          //this.homeUIService.setModalStack(modal);
          await modal.present();
          modal.onDidDismiss().then(() => {
            this.isModalOpen =false
          })
        }
      } else {
        const modal = await this.modalController.create({
          component: comp,
          cssClass: customClass,
          componentProps: compProps?compProps:'',
          enterAnimation: this.animationService.slideInLeftEnterAnimation,
          leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
        });
        this.homeUIService.setModalStack(modal);
        await modal.present();
        modal.onDidDismiss().then(() => {
          this.isModalOpen = false;
        });
      }
     }
     else {
        this.isProfileClicked =false;
        this.isSliderClicked =false;

     }
    
  }
  openDocument(post,event) {
    const docLink = document.getElementById('docLink');
    if ((docLink.contains(event.target as Node))) {
      this.homeUIService.openDocument(post,this.dialog, this.path.userGroup.name)
    }
  }
  setYesterdayHabit() {
    // LUMBA-1373
    if (this.currentDay < 1) {
      return
    }
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
    // LUMBA-1360
    let currentHabitIndex
    let prevHabitIndex
    for (let i in this.currentPath.habits) {
      const habit = this.currentPath.habits[i]
      if (
        habit.startDay <= this.currentDay &&
        this.currentDay <= habit.endDay
      ) {
        if (!currentHabitIndex) {
          currentHabitIndex = i
        }
      } else if (
        habit.startDay <= this.currentDay &&
        habit.endDay <= this.currentDay
      ) {
        prevHabitIndex = i
      }
    }
    if (currentHabitIndex >= 0) {
      prevHabitIndex = currentHabitIndex
    } else if (!prevHabitIndex) {
      return
    }
    this.yesterdayHabit = this.currentPath.habits[prevHabitIndex] // LUMBA-1344

  }

  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }

  habitConfirm_condensed(habit) {
    mixpanel.track("InSteps-Action-Mark Habit Complete", {'title': habit.title, 'startDay': habit.startDay, 'screenId': habit.screenId,'pathName': habit.pathName,'isConvertedToRoutine': habit.isConvertedToRoutine, 'id': habit.id,'casualDocumentId': habit.casualDocumentId})
    var isHabitComplete = habit.confirmation
    try {
      this.dailyStepService.removeFromSnoozed(this.yesterdayHabit.id)
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:HabitConfirmed:${this.yesterdayHabit.id}`,
        'yes'
      )
      localStorage.removeItem(
        `Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`
      )
      // this.spinnerService.on()
      this.habitService.confirm(habit.id).subscribe(res => {
        // LUMBA-1344
        // this.spinnerService.off()
        this.cdRef.detectChanges()
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }
    try {
      let todaysHabit
      if (this.currentDay != 0) {
        todaysHabit = this.findLastHabit(this.currentPath.habits, this.currentDay)
      }
      if (this.currentPath.enabledAttributes.feedback && isHabitComplete == 0) {
        this.initModal(habit)
      }
    } catch (o_O) {
      console.log(o_O);
    }
  }

  async initModal(habit) {
    // this.appState.set('currentDay',  habit?.startDay);
    const modal = await this.modalController.create({
      component: CompletedReviewModalComponent,
       componentProps: { currentHabit: habit },
      cssClass: this.isMobile?'mobile-custom-to_do_list-forms':'web_feedbackForm_modal'
      // cssClass: 'web_feedbackForm_modal'

    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.appState.set("isCommented", false)
      }
      if(!this.isMobile) {
        this.appState.set('updateActionPoints',true)
        this.closeOpenItems();
      }
      this.dailyStepService.getActions(this.currentUser.id, this.path.id)
    });

    return await modal.present();
  }
  async openModal(event: Event, action){
    let modal;
    event.stopPropagation();
    this.actionId = action.id
    this.checked = true

  //  if(this.isMobile) {
  //    modal = await this.modalController.create({
  //     component: ActionRescheduleComponent,
  //     componentProps: {
  //       action: action,
  //       currentDay: action.startDay
  //     },
  //   });
  //   modal.onDidDismiss().then((modelData) => {
     
  //     this.checked = false
  //     this.dailyStepService.getActions(this.currentUser.id, this.path.id)
  //   });
  //   return await modal.present();
  //  } 
  //  else {
    
    this.habitConfirm_condensed(action);
   
   
  }

  getTitle(title){
    return title?.slice(15,-1)
  }
  getLevelTitle(level){
    return level?.title
  }
  getRoutineLevelInfo(routine,index)
  {
    this.web_todoLevelsList[index] = this.homeUIService.getRoutineLevel(routine)
    if(this.web_todoLevelsList[index]) {
      return true
    }
    else {
      return false;
    }
  }
  // TODO : add this method in service

  async showFeedbackForm(subItem) {
    this.levelId = subItem?.id
    let currentRoutine = this.homeUIService.getRoutineByLevel(subItem, this.todoRoutines)
    let repsIndex = subItem?.reps_commit.findIndex((item) => item.is_completed === false);    
    if (repsIndex > -1) {
      this.checkedHabit = true
      const modal = await this.modalController.create({
        component: FeedbackFormComponent,
        componentProps: {
          mainItem: currentRoutine,
          subItem: subItem,
          index: repsIndex,
          IsHomescreen:true
        },
        cssClass: this.isMobile?'mobile-custom-to_do_list-forms': 'web_feedbackForm_modal'
      });
      modal.onDidDismiss().then((modelData) => {
        // if(!this.isMobile) {
          this.closeOpenItems();
        // }
        this.checkedHabit = false
        if (modelData !== null && modelData.data !== undefined) {
          this.onLogaRep.emit(modelData.data);
          this.homeUIService.logaRep(currentRoutine,subItem,repsIndex,this.currentUser, currentRoutine.schedule_day, this.path, this.modal)
        }
      });
      return await modal.present();
    } else {
      this.checkedHabit = false
      this.cdRef.detectChanges()
    }
  }

  async openTodoComponent(comp,event){
    const actionCheck = document.getElementById('actionCheck');
    const habitCheck = document.getElementById('habitCheck');
    const docLink = document.getElementById('docLink');
    if (
      (habitCheck && habitCheck.contains(event.target as Node)) ||
      (docLink && docLink.contains(event.target as Node))) {
    } else {
      this.closeOpenItems();
      const modal = await this.modalController.create({
        component: comp,
        cssClass: 'custom-modal',
        enterAnimation: this.animationService.slideInLeftEnterAnimation,
        leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
      });
      this.homeUIService.setModalStack(modal);
      await modal.present();
    }
  }

  getUserStats(stats: any){
    var current_path_user=[]
    current_path_user = stats.leaderboard.current_path_user
    try {
      this.thisPathFirst = stats.leaderboard.current_path_user[0]
      this.thisPathSecond = stats.leaderboard.current_path_user[1]
      this.thisPathThird = stats.leaderboard.current_path_user[2]
    } catch (error) {
      console.log('error catch', error);
    }
  }
  getHabitDetails() {
    if (!this.singleHabit) {
      return
    }

    try {
      const data = {
        id: this.singleHabit.id,
        path_id: this.path?.id,
        user_id: this.user.id,
        mode: this.singleHabit.type
      }

      this.routineV2Service.getHabitDetails(data).subscribe(
        (response) => {
          this.commentCount = response.data.replies.length
          this.collegues_len = response.data?.colleagues_count;
          var level1 = response.data.replies;
          this.colleagueList = response.data.colleagues_list;
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1.forEach(reply => {
              subReply.forEach(element => {
                if (element.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });

            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
                     
            this.appState.set('repliesLength', this.repliesTree.length)
          }

        },

        (error) => {
          console.log('error', error)
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
    } catch (error) {
      console.log('error', error)
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
    }

  }
  clickOnText(event, clickItem, flag?) {
    
    this.onTextClick.emit({
      item: clickItem,
      flag: flag,
      evn: event
    })
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  getFeeds(path_id, userId, isTranslated, language, page, isFirstLoad, event = null) {
    if (this.router.url === '/main/questions') {
      this.spinnerService.on();      
    }
    this.homev5Service.getListOfComments_v2(path_id, isTranslated, language, page).subscribe(
      (response) => {
        if (this.router.url === '/main/questions') {
          this.spinnerService.off();
        }
        this.isLoading=true;
        this.webFeedList = this.webFeedList.concat(response.data);

        this.feedsList = this.feedsList.concat(response.data)
        if(this.webFeedList?.length>=10) {
          this.webFeedList= this.webFeedList.slice(0,10);
        }
        this.appState.set("feeds_list", this.feedsList);
        if(this.feedsList?.length>=2){
          this.feedsList = this.feedsList.slice(0,2);
        }

        if(this.isLoading) {
          this.showNoDataMsg = true
        }
        if (isFirstLoad){}
          event?.target.complete();
        this.page = this.page + 1

        // this.platform.ready().then(() => {
        //   if(this.platform.is('android') || this.platform.is('ios')){
        //     this.navigateToPost(this.feedsList)
        //   }
        // })
      },
      (error) => {
        this.isLoading=true;
        if (this.router.url === '/main/questions') {
          this.spinnerService.off();
        }        
        this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
      }
      
      );
  }
  openNextPost(pos) {
    // if(i<=this.webFeedList?.length && i>=0) {
    //   this.showSingleWebFeedList = i;

    // }
    this.isSliderClicked =true;
    if(pos=='right') {
      this.slider.slideNext()
    }
    else {
      this.slider.slidePrev()
    }
  }
  navigateToPost(feedsList: any) {
    this.communityNotification = this.appState.get('communityNotification')
    this.notificationData = this.appState.get('notificationData')
    let selectedOption = null;
    let postId: number
    if(typeof this.notificationData?.postId === 'string'){
      postId = parseInt(this.notificationData?.postId)
    }else{
      postId = this.notificationData?.postId
    }
    
    if (this.communityNotification && this.notificationData) {
      let singlePost = feedsList.find(item => item.id === postId, 10)
      let singleHabitPost = feedsList.find(item => item.routine_info_id === postId, 10)
      let path = this.appState.get('currentPath')
      this.sessionService.removeNotificationBadge()
      localStorage.setItem('CommunityNotificationId', this.notificationData.id.toString())

      if (this.notificationData.mode == 'question' || this.notificationData.mode == 'habit') {
        selectedOption = path.update(this.notificationData.mode + "s", singlePost.id, {
          votesCounts: singlePost.votescount
        });
        if (selectedOption) {
          const data = {
            question: selectedOption,
            modes: this.notificationData.mode,
          };
          this.appState.set("singleFeed", data)
          this.appState.set("singleHabit", singlePost)
          if(this.notificationData.mode == 'question'){
            localStorage.setItem('communityTab', '1');
          } else if(this.notificationData.mode == 'habit'){
            localStorage.setItem('communityTab', '2');
          }
          // this.dialog.open(FullScreenFeedComponent)
          setTimeout(() => {
            this.homeUIService.openNextComponent(FullScreenFeedComponent, { data: singlePost })
          }, 1900);
        }
      }

      if (this.notificationData.mode == 'routineInfo') {
        selectedOption = path.update("routineInfo", singleHabitPost.routine_info_id, {
            votesCounts: singleHabitPost.votescount
          });
        if (selectedOption) {
          const data = {
            routine: selectedOption,
            mode: 'routineInfo',
          };
          this.appState.set("singleFeed", data);
        }
        if (singleHabitPost) {
          const data = {
            singleRoutine: singleHabitPost,
            mode: 'routineInfo',
          };
          this.appState.set("singleRoutineFeed", data)
          localStorage.setItem('communityTab', '3');
        }
      }
      
    }
  }
  showCommentList(mode: string, id: number) {
   this.isProfileClicked =true;
    const requestUserList = {
      id: id,
      path_id: this.path.id,
      mode: mode
    }
    this.mobileService.setIsLikeList(false)
    this.mobileService.setRequestUserList(requestUserList)
  }
  async openQuestion(e,flag, singleTask, mode, isCommentSelect: boolean = null) {
    const scrollPosition = {
      x: e.pageX,
      y: e.pageY - 160
    }

    this.appState.set("scrollPosition", scrollPosition);

    let selectedOption = null;
    if (
      e.target.tagName.toLowerCase() === "a" &&
      e.target.target === "_blank"
    ) {
      if (this.cordovaService.onCordova) {
        e.stopPropagation();
        e.preventDefault();
        this.cordovaService.openLinkInBrowser(e.target.href);
      } else {
        window.open(e.target.href, "_blank");
      }
    } else {
      if (isCommentSelect || flag) {
        this.appState.set("commentClicked", true);
      }

      selectedOption = this.appState
        .get("currentPath")
        .update(mode + "s", singleTask.id, {
          votesCounts: singleTask.votescount
        });
      if (selectedOption) {
        const data = {
          question: selectedOption,
          modes: mode,
        };
        this.appState.set("singleFeed", data)
        this.appState.set("singleHabit", singleTask)
        setTimeout(async ()=>{

        if(flag) {
          const modal = await this.modalController.create({
            component: FullScreenFeedComponent,
            componentProps: {data:singleTask, focuseTextArea: true},
            // animated: false,
            cssClass: 'custom-modal',
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
          
           })  
           await modal.present()  
          
        }
        else {
          const modal = await this.modalController.create({
            component: FullScreenFeedComponent,
            // animated: false,
            componentProps: {data:singleTask, focuseTextArea: false},
            cssClass: 'custom-modal',
            enterAnimation: this.animationService.slideInLeftEnterAnimation,
            leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,
           })  
            modal.present()  

        }
      },1500);
      
      }
    }
  }
  toggleLike(reply, mode) {
    this.user = this.appState.get("currentUser");
    this.voteLoading = true;
    reply.hasVoted = !reply.hasVoted;
    reply.votescount += reply.hasVoted ? 1 : -1;
    if(reply.hasVoted)
    {
      reply.likes?.push(this.user?._data);

    }
    else {
      reply.likes?.forEach( (user, index) => {
        if(user?.id === this.user?._data?.id) 
        reply.likes?.splice(index,1);
      });
      
    }
    this.cdRef.detectChanges();
    this.replyService.voteReply(reply, mode)
    .pipe(takeUntil(this.destroyed$)).subscribe(
      (status) => {
        this.voteLoading = false;
        this.cdRef.detectChanges();

        if (mode) {
          let habit = this.appState
            .get("currentPath")
            .update(mode + "s", reply.id, {
              hasVoted: status,
              votesCounts: status ? reply.votescount + 1 : reply.votescount - 1,
            });
        }
      },
      (error) => { 
        this.voteLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }
  showLikeList(postId: number, mode: string) {
    this.mobileService.setIsLikeList(true)
    this.mobileService.setPostId(postId)
    this.mobileService.setMode(mode)
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
  }
  async openRoutine(e,flag, singleTask, mode, isCommentSelect: boolean = null) {
      const scrollPosition = {
        x: e.pageX,
        y: e.pageY - 160
      }
  
      this.appState.set("scrollPosition", scrollPosition);
      this.appState.set("routine_color", singleTask.routine_color);
  
      let selectedOption = null;
      if (
        e.target.tagName.toLowerCase() === "a" &&
        e.target.target === "_blank"
      ) {
        if (this.cordovaService.onCordova) {
          e.stopPropagation();
          e.preventDefault();
          this.cordovaService.openLinkInBrowser(e.target.href);
        } else {
          window.open(e.target.href, "_blank");
        }
      } else {
        if (isCommentSelect || flag) {
          this.appState.set("commentClicked", true);
        }
  
        selectedOption = this.appState
          .get("currentPath")
          .update("routineInfo", singleTask.routine_info_id, {
            votesCounts: singleTask.votescount
          });
  
        if (selectedOption) {
          const data = {
            routine: selectedOption,
            mode: mode,
          };
  
          this.appState.set("singleFeed", data);
        }
  
        if (singleTask) {
          const data = {
            singleRoutine: singleTask,
            mode: mode,
          };
  
          this.appState.set("singleRoutineFeed", data)
          if(flag) { 
            const modal = await this.modalController.create({
              component: FullScreenRoutineComponent,
              // animated: false,
              componentProps: {data:singleTask, focuseTextArea: true},
              cssClass: 'custom-modal',
              enterAnimation: this.animationService.slideInLeftEnterAnimation,
              leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,
  
             })  
             await modal.present() 
          }
          else {
            const modal = await this.modalController.create({
              component: FullScreenRoutineComponent,
              // animated: false,
              componentProps: {data:singleTask, focuseTextArea: false},
              cssClass: 'custom-modal',
              enterAnimation: this.animationService.slideInLeftEnterAnimation,
              leaveAnimation: this.animationService.SlideOutRightLeaveAnimation,
  
             })  
             await modal.present() 
          }
        }
  
      }
    }
    doInfinite(event) {
      this.getFeeds(this.path.id, this.user.id, false, null, this.page, false, event);
    }

  getLearningMinute() {
    if (this.currentCompletedDay) {
      this.learningMinutes = this.sessionService.calculateLearningMinutes(this.path.inoutScreens, this.currentCompletedDay)
    }else{
      if(this.scheduleDay>1) this.learningMinutes = this.sessionService.calculateLearningMinutes(this.path.inoutScreens, this.scheduleDay)
    }
    this.appState.set('learningMinutes',this.learningMinutes)
  }

  getResumeStatus(pathRegistration, currentStep) {
    let isResume = false
    let currentEle;
    if (pathRegistration?.screensViewed) {
      let screensViewed = this.homev5Service.removeDuplicates(pathRegistration.screensViewed, "step");
      screensViewed.forEach(element => {
        if (element.step === currentStep && element.visitedScreens < element.totalScreens) {
          isResume = true
          currentEle = element
        }
      });
    }
    return isResume
  }

  getReviewStatus(pathRegistration, currentStep) {
    let isReview = false
    let currentEle;
    if (pathRegistration?.screensViewed) {
      let screensViewed = this.homev5Service.removeDuplicates(pathRegistration.screensViewed, "step");
      screensViewed.forEach(element => {
        if (element.step === currentStep && element.visitedScreens === element.totalScreens) {
          isReview = true
          currentEle = element
        }
      });
    }
    return isReview
  }

  unlockReplay(index) {
    this.appState.set("allowScreenCount", true);
    let isResume=this.getResumeStatus(this.pathRegistration,index)
    let isReview=this.getReviewStatus(this.pathRegistration,index)
    if(isReview){
      this.replay(index)
    }
    else if(isResume){
      this.router.navigate(['/content', index])
    }else{
      var i = index - 1;
      if (i == 0) {
        return;
      }
       let previousDayCompleted;
      let updateScheduleDay = i + 1;
      for (let s = index - 1; s >= 0; s--) {
        let dayHasContent = this.path.checkContentForDay(s);
        if (dayHasContent) {
          previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
            this.pathRegistration,
            s,
            this.currentUser,
            this.path
          )
          break;
        }
      }
      // let showPrev = previousDayCompleted;
      let data = {
        schedule_day: updateScheduleDay,
        user_id: this.currentUser.id,
      };
       if (previousDayCompleted || index<= this.scheduleDay) {
        this.homev5Service.updateScheduleDay(data).subscribe(
          (response) => {
            if (response.success == true) {
              localStorage.setItem('isBeginClicked','true')
              this.appState.set("allowScreenCount", true);
              this.appState.set("LevelNumber", 1);
              this.appState.set("maxAllowedScheduleDay", updateScheduleDay);
              this.appState.set("currentScheduleDay", updateScheduleDay);
              this.appState.set("scheduleDayForCurrentStep", updateScheduleDay);
              this.appState.set("indexOfLevel", 0);
              this.appState.set("levelUpdated", undefined);
              this.currentDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
              this.scheduleDay = this.appState.get("scheduleDayForCurrentStep");
              localStorage.removeItem(
                `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
                  !(i == undefined) ? i + 1 : this.scheduleDay
                }`
              );
              this.router.navigate([
                "/content",
                !(i == undefined) ? i + 1 : this.scheduleDay,
              ]);
              return false;
            } else {
              this.toasterService.warning(this.i18n.t(
                "Server or connection issue. Please check your internet connection."
              ));
            }
          },
          (error) => {
            this.toasterService.warning(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
            console.log("Response of update  error", error);
          }
        );
      } else {
        this.toasterService.warning(this.i18n.t("Complete the previous step to unlock this step."));
      }
    }
  }

  replay(i) {
    this.appState.set("isNewStepUnlocked", undefined);
    let screensViewed = this.appState.get('screensViewed')
    this.appState.set('allowScreenCount', undefined)
    let stepsArray;
    if (this.pathRegistration?.screensViewed) {
      stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration?.screensViewed,"step");
    }else{
      stepsArray = screensViewed
    }
    stepsArray?.forEach((element) => {
      if (
        element?.step == this.scheduleDay &&
        element?.visitedScreens === element?.totalScreens
      ) {
      }
    });    
    localStorage.removeItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
        !(i == undefined) ? i + 1 : this.scheduleDay
      }`
    );
    this.router.navigate([
      "/content",
      !(i == undefined) ? i + 1 : this.scheduleDay,
    ]);
    return false;
  }

}
