import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppState } from 'src/app/app.service';
import { I18n } from 'src/app/i18n.service';
import { ReplyComponent } from 'src/app/reply/reply.component';
import { Reply } from 'src/app/reply/reply.model';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { SessionService } from 'src/app/session/session.service';
import { ToasterService } from 'src/app/toaster.service';
import { UserPublicProfileComponent } from '../../user-public-profile/user-public-profile.component';
import { HomeUiService } from 'src/app/new-design/home-ui.service';

@Component({
  selector: 'app-question-community',
  templateUrl: './question-community.component.html',
  styleUrls: ['./question-community.component.scss'],
})
export class QuestionCommunityComponent implements OnInit {
  @Input() item: any;
  @Output() onShowCommentList = new EventEmitter<any>();
  @Output() onTextClick = new EventEmitter<any>();
  @Output() onToggleLike = new EventEmitter<any>();
  @Output() onShowLikeList = new EventEmitter<any>();
  @Output() onComment = new EventEmitter<any>();
  @ViewChild("repliesContainer") repliesContainer: ElementRef;
  @Input() mode: string = "question";
  @Output() isProfileClicked = new EventEmitter<any>() ;
  @ViewChild('btn') btn: ElementRef;
  isMobile: boolean
  singleHabit;
  collegues_len;
  colleagueList;
  path;
  replyToComponent: ReplyComponent;
  user;
  commentCount=0;
  public repliesTree: Reply[]
  constructor(
    public sessionService: SessionService,
    public appState: AppState,
    private popoverController:PopoverController,
    private i18n: I18n,
    private routineV2Service: RoutineV2Service,
    private toasterService: ToasterService,
    private render: Renderer2,
    public homeUiService: HomeUiService
  ) {
    this.isMobile = this.appState.get('isMobile')
    this.singleHabit = this.item;
    this.path = this.appState.get("currentPath");
    this.user = this.appState.get("currentUser");
   }

  ngOnInit() {
    this.singleHabit = this.item;
    this.collegues_len= this.item?.colleagues_count;
   }

  ngAfterViewInit(){
    try {
      if (!this.isMobile) {
        document.getElementById('questionHeader')?.classList?.add('desktop');
      }
    } catch (error) { }
  }
  async openUserInfo(ev, userData): Promise<void> {
    console.log('userData', userData);
    this.isProfileClicked.emit({isClicked:true})
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }

  showCommentList(clickItem) {
    this.onShowCommentList.emit({
      id: clickItem.id
    })
  }
  clickOnText(event, clickItem, flag?) {
    
    this.onTextClick.emit({
      item: clickItem,
      flag: flag,
      evn: event
    })
  }
  doLikeUnlike() {
    this.render.addClass(this.btn.nativeElement, 'bounce');
    if(this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn.nativeElement, 'bounce');
    }, 200);
    this.onToggleLike.emit({
      item: this.item
    })
  }
  emitShowLikeList() {
    this.onShowLikeList.emit(this.item.id);
  }
  emitComment() {
    this.onComment.emit({
      item: this.item
    })
  }

  getHabitDetails() {
    if (!this.singleHabit) {
      return
    }

    try {
      const data = {
        id: this.singleHabit.id,
        path_id: this.path?.id,
        user_id: this.user.id,
        mode: this.singleHabit.type
      }

      this.routineV2Service.getHabitDetails(data).subscribe(
        (response) => {
        //  console.log("Response", response);
          this.commentCount = response.data.replies.length
          this.collegues_len = response.data?.colleagues_count;
          var level1 = response.data.replies;
          this.colleagueList = response.data.colleagues_list;
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1.forEach(reply => {
              subReply.forEach(element => {
                if (element.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });

            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            console.log('repliesTree', this.repliesTree)            
            this.appState.set('repliesLength', this.repliesTree.length)
          }

        },

        (error) => {
          console.log('error', error)
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
    } catch (error) {
      console.log('error', error)
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
    }

  }
}

