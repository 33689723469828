import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { PathRegistration } from '../../user/path-registration.model'
import { AppState } from '../../app.service'
import { Path } from '../../path/path.model'
import { User } from '../../user/user.model'
import { CordovaService } from '../../cordova.service'
import { UserService } from '../../user/user.service'
import { NotificationService } from '../../notification/notification.service'
import { ToastrService } from 'ngx-toastr'
import { I18n } from '../../i18n.service'
import { AlertNotifyComponent } from '../../alert-notify/alert-notify.component';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService } from 'src/app/toaster.service';
import { ModalController, Platform } from '@ionic/angular';


import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { SpinnerService } from 'src/app/spinner.service';
import { SessionService } from 'src/app/session/session.service';
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from 'src/app/new-design/home-ui.service';
import { DayPartSelectComponent } from 'src/app/day-part-select/day-part-select.component';
import { AnimationsService } from 'src/app/shared/animations.service';
import { MyCommitmentsComponent } from 'src/app/my-commitments/my-commitments.component';
import { MobileChatBoardComponent } from '../mobile-chat-board/mobile-chat-board.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface CurrentPathRegistration {
  [pathRegistration: string]: any;
}
export interface UpdatePathRegistration {
  [updatePathRegistration: string]: any;
}

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.scss']
})
export class NotificationsSettingsComponent implements OnInit, DoCheck, OnDestroy {
  pushNotificationsEnabled: boolean
  confirmedTime: string
  selectedTimeOption: string
  currentPath: Path
  currentUser: User
  loading: boolean = false
  isTimePicked=false
  isChanged = false
  emailReminderEnabled: boolean
  pushReminderEnabled: boolean
  socialReminderEnabled: boolean
  socialEmailReminder: boolean

  isLearningInfo: boolean = false
  isSocialInfo: boolean = false
  isMobileWebIos = false;
  isMobileWeb=false;

  @Input()selectedTime: any
  selectedSocialTime: any
  learningTime: any
  isMobile: boolean
  public pathRegistration: CurrentPathRegistration;
  public updatePathRegistration: UpdatePathRegistration;
  isSubReply: boolean
  processedCommitmentNotifications: Set<number> = new Set();
  processedCommunityNotifications: Set<number> = new Set();
  destroyed$ = new Subject<boolean>();
  constructor(
    private i18n: I18n,
    private appState: AppState,
    private cordovaService: CordovaService,
    private userService: UserService,
    private dialog: MatDialog,
     private platform: Platform,
    private modalController: ModalController,
    private firebaseX: FirebaseX,
    private router: Router,
    private spinnerService: SpinnerService,
    private toastrService: ToasterService,
    private homeUIService: HomeUiService,
    public route: ActivatedRoute,
    private animationService: AnimationsService,
    private sessionService: SessionService) {
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.pathRegistration = this.appState.get('pathRegistration')
    console.log('pathRegistration :', this.pathRegistration);
    this.isMobile = this.appState.get('isMobile')

    let learningTime = localStorage.getItem('learningTime')
    if (learningTime) {
      mixpanel.track('Learning Reminder', {'learnTime': learningTime})
      this.learningTime = learningTime
    }else{
      if (this.pathRegistration && this.pathRegistration.pathData) {
        this.learningTime = this.pathRegistration.pathData.loginReminderTime
      } 
    }
    
    if (this.pathRegistration && this.pathRegistration.pathData) {
      if (this.pathRegistration.pathData.loginReminderEnabled) {
        this.pushNotificationsEnabled = this.pathRegistration.pathData.loginReminderEnabled
      }
      if (this.pathRegistration.pathData.emailReminderEnabled) {
        this.emailReminderEnabled = this.pathRegistration.pathData.emailReminderEnabled
      }
      if (localStorage.getItem('pushReminderEnabled')) {
        this.pushReminderEnabled = true
      }
      if (this.pathRegistration.pathData.socialReminderEnabled) {
        this.socialReminderEnabled = this.pathRegistration.pathData.socialReminderEnabled
      }
      if (this.pathRegistration.pathData.socialEmailEnabled) {
        this.socialEmailReminder = this.pathRegistration.pathData.socialEmailEnabled
      }
    }

  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    setTimeout(()=>{
      this.selectedSocialTime  = this.selectedTime;
    },100);
  }

  ngOnChanges(){
    console.log("changes done");
  }
  prevScreen() {
    this.modalController.dismiss();
    //window.history.back();
  }
  ngDoCheck(){
    this.isSubReply = this.appState.get('isSubReply')
    let learningTime = localStorage.getItem('learningTime')
    if (learningTime) {
      this.learningTime = learningTime
    }else{
      if (this.pathRegistration && this.pathRegistration.pathData) {
        this.learningTime = this.pathRegistration.pathData.loginReminderTime
      } 
    }
  }

  toggle(){
    mixpanel.track('Learning Reminder', {'push notification': this.pushNotificationsEnabled})
    
    if (this.pushNotificationsEnabled && this.cordovaService.isIOS) {
        this.notificationAlertBox() 
    }
    this.save()
  }

  save() {    
    // this.setLocalData()
    mixpanel.track('Learning Reminder', {'email notification': this.emailReminderEnabled})
    mixpanel.track('Social Notification', {'email notification': this.socialEmailReminder, 'push notification': this.socialReminderEnabled})
    let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = this.pathRegistration.pathData.deviceRegistrationId;
    }
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.currentPath.id,
      pathData: {
        loginReminderEnabled: this.pushNotificationsEnabled,
        loginReminderTime: this.confirmedTime,
        deviceRegistrationId: deviceId,
        emailReminderEnabled: this.emailReminderEnabled,
        pushReminderEnabled: this.pushReminderEnabled,
        socialReminderEnabled: this.socialReminderEnabled,
        socialEmailEnabled: this.socialEmailReminder
      }
    })

    this.getToken()

    this.loading = true
    const deviceToken = localStorage.getItem('deviceToken')
    console.log('deviceToken', deviceToken);
    console.log('deviceRegistrationId', this.pathRegistration.pathData.deviceRegistrationId);
    if(this.isMobile) {
      if (deviceToken || this.pathRegistration.pathData.deviceRegistrationId) {
       this.updateNotificationToggles(pathRegistration);
      } else {
        this.spinnerService.off()
        console.error('Device token not found')
        this.loading = false
        if (this.isMobile) {
          this.getToken()
          this.toastrService.error(this.i18n.t('Device token not found'))
        }
        
      }
    }
    else {
      this.updateNotificationToggles(pathRegistration);
    }
     
  }

  updateNotificationToggles(pathRegistration){
    this.spinnerService.on()
    this.userService.savePathRegistration(pathRegistration).subscribe(
      (response) => {
        console.log("Notifications reponse ",response);
        this.updatePathRegistration = response.data
        this.appState.set("pathRegistration", this.updatePathRegistration.updatePathRegistration);
        this.toastrService.success(this.i18n.t('Settings updated!'))
      },
      error => {
        this.spinnerService.off()
        console.log('Server error', error)
        this.loading = false
        this.toastrService.error(this.i18n.t('Settings not saved. Please check your internet connection.'))
      },
      () => {
        this.spinnerService.off()
        this.loading = false
      }
    )
  }
  refresh() {
    this.appState.refreshMainComponent.emit();
    this.spinnerService.off()
  }


  notificationAlertBox(){
    let dialogRef = this.dialog.open(AlertNotifyComponent)

    dialogRef.afterClosed().subscribe((result) => {

    })
  }
  loadprocessedCommitmentNotifications() {
    const storedData = localStorage.getItem('processedCommitmentNotifications');
    if (storedData) {
      this.processedCommitmentNotifications = new Set(JSON.parse(storedData));
    }
  }
  loadprocessedCommunityNotifications() {
    const storedData = localStorage.getItem('processedCommunityNotifications');
    if (storedData) {
      this.processedCommunityNotifications = new Set(JSON.parse(storedData));
    }
  }
  getToken(){
    console.log('isSubReply', this.appState.get('isSubReply'));
    
    if (this.platform.is('ios') || this.platform.is('android')) {

      this.platform.ready().then(() => {

        
        this.firebaseX.hasPermission().then((res)=> {
          console.log('res :', res);
          if (res === false) {
            this.firebaseX.grantPermission().then((permission) => {
              console.log("ios permission : ", permission);
            });

            this.firebaseX
              .getToken()
              .then((token) => {
                localStorage.setItem("deviceToken", token);
                console.log(`The token is ${token}`);
              })
              .catch((error) => console.error("Error getting token", error));
          }else{
            this.firebaseX
            .onMessageReceived()
              .subscribe((data) => {
               let postData = JSON.parse(data?.keysandvalues)
              console.log('data outside platform.resume() app component:', data);
                let notificationId = data['gcm.message_id']?parseInt(data['gcm.message_id']):parseInt(data['google.message_id']?.split(':')[1]?.split('%')[0])
              console.log('Starting notification ID (app component):', notificationId);

              const requestData = {
                mode: postData?.mode,
                postId: postData?.post_id,
                id: notificationId,
                route: postData?.route
              }
              this.appState.set('AfterAppInit', requestData)
              // "AfterAppInit" used for when app is not in BG

              // platform.resume() is used for when app is in BG
              this.platform.resume.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                this.loadprocessedCommitmentNotifications();
                this.loadprocessedCommunityNotifications();
                console.log('data INSIDE platform.resume() app component:', data);
                console.log('Starting notification ID inside (app component):', notificationId);

                let communitySavedId = parseInt(localStorage.getItem('CommunityNotificationId'))
                console.log('communitySavedId (app component):', communitySavedId);

                let commitmentsSavedId = parseInt(localStorage.getItem('CommitmentsNotificationId'))
                console.log('commitmentsSavedId (app component):', commitmentsSavedId);

                 if (postData.route.match('/main/commitments') && notificationId && !this.isCommitmentNotificationProcessed(notificationId) && !this.processedCommunityNotifications?.has(notificationId)) {
                   this.processedCommitmentNotifications.add(notificationId);
                    if (data['tap'] == 'foreground') {
                      localStorage.setItem( 'processedCommitmentNotifications',JSON.stringify(Array.from(new Set(this.processedCommitmentNotifications))));
                    }
                   else if (data['notification_foreground'] === 'true' && !data['tap']) {
                    console.log('Forground notification received inside app component:', data.route);
                    localStorage.setItem('CommitmentsNotificationId', notificationId.toString())
                  } else {
                    console.log('Background Notification received inside app component:');
                    localStorage.setItem('CommitmentsNotificationId', notificationId.toString())
                   if (this.homeUIService.getModalStack()?.length > 0) {
                        if (this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.component?.name != 'MyCommitmentsComponent' && this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.name!= 'MyCommitmentsComponent') {
                          console.log("stayed on same commitment screen. no redirection needed")
                          this.homeUIService.setModalStack(MyCommitmentsComponent)
                          this.homeUIService.openNextComponent(MyCommitmentsComponent);
                        }
                      }
                      else {
                        this.homeUIService.setModalStack(MyCommitmentsComponent)
                        this.homeUIService.openNextComponent(MyCommitmentsComponent);
                      }
                     // this.router.navigate(['/main/commitments']);
                    }
                  localStorage.setItem( 'processedCommitmentNotifications',JSON.stringify(Array.from(new Set(this.processedCommitmentNotifications))));
                }

                if (postData.route.match('/main/questions') && notificationId && !this.isCommitmentNotificationProcessed(notificationId) && !this.processedCommunityNotifications?.has(notificationId)) {
                  if (data['tap'] == 'foreground') {
                    this.processedCommunityNotifications.add(notificationId);
                    localStorage.setItem('processedCommunityNotifications',JSON.stringify(Array.from(new Set(this.processedCommunityNotifications))));
                  }
                  else if (data['notification_foreground'] === 'true' && !data['tap']) {
                    console.log('Forground notification received inside app component:', postData.route);
                    this.processedCommunityNotifications.add(notificationId);
                    localStorage.setItem('CommunityNotificationId', notificationId.toString())
                  } else {
                    console.log('Background Notification received inside app component:', data.route);
                    this.appState.set('communityNotification', true)
                    this.appState.set('notificationData', requestData)
                    this.processedCommunityNotifications.add(notificationId);
                    if (this.router.url === '/main/questions' || this.homeUIService.getModalStack()?.length > 0) {
                      if (this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.component?.name == 'MobileChatBoardComponent' || this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.name== 'MobileChatBoardComponent' ) {
                      console.log('this.router.url :', this.router.url,);
                      this.appState.set('isBackground', true)
                        }
                        else {
                          this.homeUIService.setModalStack(MobileChatBoardComponent)
                          this.homeUIService.openNextComponent(MobileChatBoardComponent);
                       }
                    } else {
                      console.log('this.router.url :', this.router.url,);
                          this.homeUIService.setModalStack(MobileChatBoardComponent)
                      this.homeUIService.openNextComponent(MobileChatBoardComponent);
                    }
                  }
                    localStorage.setItem('processedCommunityNotifications',JSON.stringify(Array.from(new Set(this.processedCommunityNotifications))));
                }
              })
  
                this.sessionService.removeNotificationBadge()
              
            },
            (error) => {
              console.log('error notifications:', error);
            });
          }
          
          
        })


        this.firebaseX.onTokenRefresh().subscribe((token: string) => {
          localStorage.setItem("deviceToken", token);
          console.log("New device token : ", token);
        });
      }).catch(error => {
        console.log("platform  error : ", error);
      });

    }
  }
  isCommitmentNotificationProcessed(id: number): boolean {
    console.log("this.processedCommitmentNotifications?.has(id)",this.processedCommitmentNotifications?.has(id),id)
    return this.processedCommitmentNotifications?.has(id);
  }
  async openComp() {
    // modal = this.homeUIService.openNextComponent(DayPartSelectComponent)
    const modal = await this.modalController.create({
      component: DayPartSelectComponent,
      cssClass: 'custom-modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
    });
     await modal.present() 
     this.homeUIService.setModalStack(modal)
    modal.onDidDismiss().then((val) => {
      this.selectedTime = val.data?.selectedTime;
      })
  }

  close(){
    this.modalController.dismiss();
  }

  changeTime(){
    this.homeUIService.openNextComponent(DayPartSelectComponent,'','desktop-profile-custom')
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
