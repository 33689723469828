import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { AlertUserLikeListComponent } from 'src/app/alert-user-like-list/alert-user-like-list.component';
import { UserPublicProfileComponent } from '../../user-public-profile/user-public-profile.component';
import { SessionService } from 'src/app/session/session.service';
import { ReplyComponent } from 'src/app/reply/reply.component';
import { AnalyticsServiceService } from 'src/app/services/analytics-service.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PopoverController, Platform } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.service';
import { CordovaService } from 'src/app/cordova.service';
import { RoutineService } from 'src/app/daily-content-v2/routine.service';
import { I18n } from 'src/app/i18n.service';
import { MobileChatServiceService } from 'src/app/pages/mobile-chat-board/mobile-chat-service.service';
import { Path } from 'src/app/path/path.model';
import { QuestionService } from 'src/app/question/question.service';
import { Reply } from 'src/app/reply/reply.model';
import { ReplyService } from 'src/app/reply/reply.service';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { AttachmentUploaderComponent } from 'src/app/shared/attachment-uploader/attachment-uploader.component';
import { TranslationService } from 'src/app/translation/translation.service';
import { WalletService } from 'src/app/wallet/wallet.service';
import { WINDOW } from 'src/app/window.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { collapseOnLeaveAnimation, expandOnEnterAnimation, fadeInExpandOnEnterAnimation, fadeInExpandRightOnEnterAnimation, fadeInUpAnimation, fadeOutAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { trigger, state, style, transition, animate, AUTO_STYLE } from '@angular/animations';
const DEFAULT_DURATION = 100;
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from '../../../new-design/home-ui.service';
import { MobileChatBoardComponent } from 'src/app/pages/mobile-chat-board/mobile-chat-board.component';

@Component({
  selector: 'app-comments-list-web',
  templateUrl: './comments-list-web.component.html',
  styleUrls: ['./comments-list-web.component.scss'],
  animations: [
    expandOnEnterAnimation({ duration: 100 }),
    fadeOutAnimation({duration:100}),
    collapseOnLeaveAnimation({ duration: 100 }),
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({  visibility: 'hidden', opacity: 0 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
       transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
    
  ]
  
})
export class CommentsListWebComponent implements OnInit {

  @Input() mode: string = "question";
  @Input() isPreview: boolean;
  @Input() inverted: boolean = false;
  @Input() focusSpecificCommentPost;
  @Input() small = false;
  @Input() isHomeRoute: boolean;
  @Output() closed: EventEmitter<any>;
  @Output() itemSelected: EventEmitter<any>;
  @Output() onShowCommentList = new EventEmitter<any>();
  @Output() onToggleLike = new EventEmitter<any>();
  @Output() onShowLikeList = new EventEmitter<any>();
  @Output() onComment = new EventEmitter<any>();
  @ViewChild("uploader") uploader: AttachmentUploaderComponent;
  @ViewChild("replyBlock") replyBlock: ElementRef;
  @ViewChild("repliesContainer") repliesContainer: ElementRef;
  @ViewChild("textareaForFeeds") txtarea_post: ElementRef;
  @ViewChild("cardContainer") cardContainer: ElementRef;
  @ViewChild('btn') btn: ElementRef;
  @ViewChild('replyComponent') replyComponent: ReplyComponent;
  @ViewChild('h1')
  h1: ElementRef;
  @Input() data;
  @ViewChild('textContainer') textContainer: ElementRef;
  isTranslated;
  colleagues_len=0;
  flag;
  showComment =false;
  leftState=false;
  rightState =false;
  path: Path;
  ishideComment: boolean = false;
  sortBy: string = "timestamp";
  replyText: string = "";
  cachedReplyText: string = ""; // LUMBA-1383
  attachmentUrl: string = null;
  errors: { answerError: false };
  replyTo: Reply = null;
  replyToComponent: ReplyComponent;
  replyToId: number;
  isLoading: boolean = false;
  replyOffset: number;
  selected: boolean = false;
  voteLoading: boolean = false;
  user: any;
  showReplies=false;
  question: any;
  recordsToShow;
  tagName_id: number
  dataOfUser: Observable<any>;
  namesData: any[]
  hideRecords=0;
  tempData: any;
  check=false;
  textareaContentLength=0;
  fadeState: boolean = false;
  animation = ['bounceIn'];
  isScrolledBottom: boolean = false
  singleHabit: any
  commentCount: number = 0
  public replies: Reply[]
  public repliesTree: any[] = [];
  colleagueList = [];
  isMobile: boolean;
  tagsArray = [{ id: 'test', username: 'test' }];
  isCommentPosted:boolean;
  options = [
    {
      label: 'Other',
      animations: ['expandCollapse']
    }
  ]
 public isCollapsed: boolean = false;
 public isCollapsable: boolean = false; 
 isLoaded =false
  page;
  replyCount = 0;
  apiReplyCount = 0;
  pathRegistration
  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private routine:RoutineService,
    private toastrService: ToastrService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private cordovaService: CordovaService,
    public sessionService: SessionService,
    private mobileService: MobileChatServiceService,
    private routineV2Service: RoutineV2Service,
   public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToastrService,
    private walletService: WalletService,
    private analyticsService: AnalyticsServiceService,
    private sanitizer:DomSanitizer,
    private keyboard: Keyboard,
    private popoverController:PopoverController,
    public render: Renderer2,
    public platform: Platform,
    private homeUiService: HomeUiService,
    @Inject(WINDOW) private window: Window,
   
  ) {
   
    this.path = this.appState.get("currentPath");
    this.user = this.appState.get("currentUser");
    this.pathRegistration = this.appState.get("pathRegistration");
    this.isMobile = this.appState.get('isMobile')
    this.appState.set('oldActionPoints', this.walletService.actionPoints.value)
    this.singleHabit = this.appState.get('singleHabit')
    this.analyticsService.setScreen(AnalyticsServiceService.FULLFEED)
    this.page=1;
  }

  async ngDoCheck() {
    let isCommentDeleted = this.appState.get('commentDeleted')    
    if (isCommentDeleted) {
      this.appState.set('commentDeleted', undefined)
     let id = this.appState.get("deletedPostId");
     //this.page = 1;
      //await this.getHabitDetails(null, true);
      if (id) {
        this.removeDeletedComment(id);
      }
      
    }
  }
  removeDeletedComment(id) {
    if (id) {
      let deletedReply;
       if (this.replyCount == 0) {
         this.replyCount = this.data?.reply_count;
       }
      this.repliesTree = this.appState.get("repliesTreeObject");
      deletedReply = this.repliesTree?.findIndex((reply) => reply?.id === id);
      if (deletedReply != -1 && deletedReply != undefined) {
        let deletedIdCount = this.repliesTree[deletedReply]?.replies?.length;
        this.repliesTree.splice(deletedReply, 1);
        this.replyCount = this.replyCount - (deletedIdCount + 1);
        if (this.apiReplyCount > 0) {
          this.apiReplyCount -= (deletedIdCount + 1);
        } 
        //this.getRoutineDetails((deletedPost/10)+1);
      } else {
        for (const reply of this.repliesTree) {
          deletedReply = reply?.replies?.findIndex((reply) => reply?.id === id);
          if (deletedReply != -1 && deletedReply != undefined) {
            reply?.replies?.splice(deletedReply, 1);
            this.replyCount = this.replyCount - 1;
            if (this.apiReplyCount > 0) {
               this.apiReplyCount -= 1;
            }
            
          }
        }
      }
      this.data.replies = this.repliesTree;
      this.data.reply_count = this.replyCount;
      console.log("deleted post", deletedReply, this.repliesTree);
      this.appState.set("deletedPostId", null);
    }
  }

  ngOnInit() {
    // LUMBA-1317
    this.isLoaded =false
    let selectedOption =null;
    selectedOption = this.appState
    .get("currentPath")
    .update(this.mode.toLowerCase() + "s", this.data?.id, {
      votesCounts: this.data?.votescount
    });
  if (selectedOption) {
    const data = {
      question: selectedOption,
      modes: this.mode,
    };

    this.appState.set("singleFeed", data)
    this.appState.set("singleHabit", this.data)
  }
    let dataOfFeeds = this.appState.get('singleFeed')
    if (!dataOfFeeds) {
      this.refresh()
    }
    this.tempData = dataOfFeeds["question"];
    this.mode = dataOfFeeds["modes"].toLowerCase();
    this.appState.set("mode", this.mode.toLowerCase() + "s");
    this.question = this.sessionService.findById(this.path[dataOfFeeds["modes"].toLowerCase() + 's'], this.tempData?.id)
    this.appState.set('oldActionPoints', this.walletService.actionPoints.value)
    this.singleHabit = this.appState.get('singleHabit')
    this.analyticsService.setScreen(AnalyticsServiceService.FULLFEED)
    this.data = this.data;
    this.question = this.data;
    this.appState.set('communityNotification', undefined)
    this.appState.set('notificationData', undefined)
    if (this.focusSpecificCommentPost?.id == this.data?.id && this.focusSpecificCommentPost?.type == this.data?.type && !this.isHomeRoute)  {
      setTimeout(() => {
        this.cardContainer?.nativeElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });;
        this.onCommentClick();
      }, 500);
    }
    
  }
  ngAfterViewInit() {
    this.focusComment()
    window.scroll(0, 0);
   
    //     // this line moves the screen at the bottom scroll
    if(this.isMobile){
      if(this.platform.is('android')) {
       let id =document.getElementsByClassName('list-group')[0];       
       id?.setAttribute('style', 'margin-top:60px;')
        this.keyboard.onKeyboardDidShow().subscribe((info) => {
          console.log('keyboard will show with height', info.keyboardHeight);
          this.replyBlock.nativeElement.style.setProperty(
            'bottom',
            `${info.keyboardHeight-20}px`
          );
          this.replyBlock.nativeElement.style.setProperty(
            'padding-bottom',
            `${276-(info.keyboardHeight-20)}px`
          );
        });
    
        this.keyboard.onKeyboardDidHide().subscribe(() => {
          console.log('keyboard did hide');
          this.replyBlock.nativeElement.style.setProperty(
            'bottom',
            `${0}px`
          );
          this.replyBlock.nativeElement.style.setProperty(
            'padding-bottom',
            `${0}px`
          );
        });
      }
    }
    if (!this.isMobile && !this.isHomeRoute) {
      if (this.focusSpecificCommentPost?.isFocus ==false && this.focusSpecificCommentPost?.post?.id == this.data?.id) {
       setTimeout(() => {
          this.cardContainer?.nativeElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });;
        },500)
   
      }
    }
  if(this.isHomeRoute) {
    setTimeout(() => {
      const container = this.textContainer?.nativeElement;
      const lineHeight = parseFloat(window.getComputedStyle(container).lineHeight);
      const containerHeight = container.clientHeight;
      const lineCount = Math.floor(containerHeight / lineHeight);
         if (lineCount > 5) {
            this.isCollapsed = true;
            this.isCollapsable = true;
            let el = document.getElementsByClassName('text')[0]
            this.render.addClass(this.textContainer?.nativeElement, 'expand-texts');
         }
    },500)
  }
  this.showCommentsData(this.data);
  }
showCommentsData(data) {
  this.commentCount = data.replies?.length
     var level1
           if (this.focusSpecificCommentPost?.post?.id == this.data?.id && this.focusSpecificCommentPost?.replyId && this.focusSpecificCommentPost?.replyId != undefined && (this.focusSpecificCommentPost?.post?.replies?.length>0 && this.focusSpecificCommentPost?.post?.replies[0]?.id== this.focusSpecificCommentPost?.replyId)) {
             if (this.focusSpecificCommentPost?.post?.replies?.length > 2) {
               level1 = this.focusSpecificCommentPost?.post?.replies?.slice(0,2)
             }
             else {
               level1 = this.focusSpecificCommentPost?.post?.replies
             }
           }
           else {
              level1 = data.replies;
           }
          this.colleagueList = data.colleagues_list;
          this.colleagues_len= data?.colleagues_count

          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1.forEach(reply => {
              subReply.forEach(element => {
                let flag=0
                reply?.replies?.forEach((item)=>{
                  if(item.id===element.repliableId)
                  {
                     flag ++;
                  }
                })
                if (element.repliableId === reply.id && flag ==0) {
                  reply.replies?.push(element)
                  reply.replies = [...new Set( reply.replies)];
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });
            this.replyCount = data?.reply_count;
            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            this.isLoaded =true
             console.log('repliesTress', this.repliesTree)
           
            this.appState.set('repliesLength', this.repliesTree?.length)
          }
          
          if(this.repliesTree?.length>1 && !this.isHomeRoute) {
            this.recordsToShow =2;
      
          this.render.addClass(this.repliesContainer?.nativeElement, 'hide_comment');
        }
        if(this.repliesTree?.length>1 && this.isHomeRoute) {
          this.recordsToShow =1;
    
        this.render.addClass(this.repliesContainer?.nativeElement, 'hide_comment');
      }
         
                
}
checkTextareaLength() {
        let len = this.replyText?.trim();
       this.textareaContentLength =len.length;
        console.log("LENGTH>>>", len)
    }
  itemMentioned(tag) {
    console.log("selected ", tag);
  }
  onCommentClickInHome(event,question) {
    event.stopPropagation();
    this.homeUiService.openNextComponent(MobileChatBoardComponent, {data:question},'desktop-custom-modal')
  }
  onCommentClick() {
    this.showReplies =false;
    this.replyTo = null;
    this.replyToId = null;
    this.showComment =!this.showComment;

    if(this.showComment) {
      if(!this.isMobile) {
        this.replyService.setPropertyValue(true);

      }

      setTimeout(()=>
        {this.txtarea_post.nativeElement.focus();
        },500);
      
    }
    else {
      this.replyService.setPropertyValue(null);
    }
  }
  doLikeUnlike() {
    console.log(this.btn.nativeElement,this.btn.nativeElement.querySelector('ion-icon'), this.btn.nativeElement.querySelector('ion-icon').getAttribute("name"))
    this.render.addClass(this.btn.nativeElement, 'bounce');
    if(this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render?.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render?.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn.nativeElement, 'bounce');
    }, 200);
    this.onToggleLike.emit({
      item: this.question
    })
  }
  emitShowLikeList(){
    this.onShowLikeList.emit(this.question.id);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false;
    }
    //
    this.question.sortRepliesBy(this.sortBy);
    this.cdRef.detectChanges();
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by;
    this.question.sortRepliesBy(by);
  }

  hideAndShowComment() {
    if (this.ishideComment) {
      this.ishideComment = false;
    } else {
      this.ishideComment = true;
    }
  }
   orderingSubReplies(reply) {
     var level1 = [reply, ...this.repliesTree];
    if (level1) {
      var subReply = level1.filter(item => item.repliableId)
      level1?.forEach(reply => {
        subReply?.forEach(element => {
          if (element?.repliableId === reply.id) {
            const updatedReply = { ...reply };
            //  if (updatedReply?.replies?.length > 0 || ((updatedReply?.sub_reply_count ==0|| updatedReply?.sub_reply_count == undefined) && this.repliesTree?.length==1)) {
              updatedReply.replies = [element, ...updatedReply.replies];
            // }
            // else {
            //   if (updatedReply?.sub_reply_count == undefined || updatedReply.sub_reply_count == null) {
            //     updatedReply.sub_reply_count =1
            //   }
            //   else {
            //     updatedReply.sub_reply_count+=1
            //   }
            // }
            
            updatedReply.timestamp = new Date(reply.createdAt);
            //replySort =updatedReply;
            const index = level1.findIndex(r => r.id === updatedReply.id);
            level1[index] = updatedReply;
          }
        });
      });
      this.isLoaded = true;
    //   if (replySort) {
    //     replySort.timestamp = new Date(reply.createdAt);
    //   }
    //  let index =level1?.findIndex((reply) => reply.id == replySort.id)
    //   level1.splice(index,1)
      let repliesTree = level1.filter(reply => reply.id && reply.level === 1)
      this.repliesTree = repliesTree;
      console.log("repliestree in comment list", this.repliesTree, this.replyText);
    }
  }
  replyFun(text?) {
    if(this.textareaContentLength>0){
      this.isLoaded =false
    }
    //this.isLoaded =false;
    if(text) {
      this.replyText =text.replyText;
      console.log(text, this.replyText)
    }
    if(!this.isMobile) {
      this.replyService.setPropertyValue(null);
    }

      this.showComment = false;
      this.showReplies =false;
  
    
    console.log("New tag ", SessionService.getData()
    );

    let formatedText;
    if (this.replyText.length == 0) {
      this.toastrService.error(
        this.i18n.t("Please enter a comment"),
        this.i18n.t("Error")
      );
      return false;
    }

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText, this.complexItems)
    mixpanel.track('Community-web-Comment reply', {'Comment post': this.replyText, 'post id': this.repliesTree[0]?.id, 'post text': this.repliesTree[0]?.text, 'post userId': this.repliesTree[0]?.user?.id, 'post type': this.repliesTree[0]?.type, 'post vote': this.repliesTree[0]?.hasVoted })
    // if (this.isPreview) {
    //   return false;
    // }
    // if (this.isLoading) {
    //   return false;
    // }
    this.isLoading = true;


    this.complexItems?.forEach(element => {
      let matchText = `@${element.username.toUpperCase()}`;
      if (this.replyText.includes(matchText)) {
        let replaceValue = `<span id="${element.id}">${element.username.toUpperCase()} </span>`;
        formatedText = this.replyText?.replace(RegExp(matchText, 'g'), replaceValue);
      }
    });

    this.unloadReply();
    if (formatedText) {
      this.cachedReplyText = formatedText;
    }
    if (this.replyTo) {
      console.log("this.replyTo", this.replyTo);
      this.isLoaded =true;
      this.replyService
        .reply(
          this.question,
          this.replyTo.id,
          this.cachedReplyText,
          this.attachmentUrl,
          list_of_tags_ids,
          this.question.id,
          this.mode,

        )
        .subscribe(
          (reply) => {
            if(!this.isMobile){
              this.select(false);
            }
            this.ishideComment = true;
            this.reset();
            this.isLoading = false;
            this.showReplies =false;
            this.replyTo = null;
      this.replyToId = null;
            this.toastrService.success(
              this.i18n.t("Comment posted!")
            );
            this.appState.set('isCommentPosted',true)
            
            this.appState.set('isReplyAnimationApplied',true);
            this.loadReply();
            //this.getHabitDetails();
            // this.repliesTree = this.repliesTree.concat(reply);
            // this.repliesTree = [reply, ...this.repliesTree];
            this.orderingSubReplies(reply)
            this.isLoading = true;
            this.cdRef.detectChanges();
            this.replyComponent.replyText = '';
            if (this.repliesContainer != undefined) {
              this.cardContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
              this.cardContainer.nativeElement.scrollTop = 0;
            }
          },
          (error) => {
            this.toastrService.error(
              this.i18n.t("Comment not posted. Check your connection and try reloading the app."),
              this.i18n.t("Error")
              );
              mixpanel.track("Community-web-comment not posted")
            this.isLoading = false;
            this.loadReply();
            this.cdRef.detectChanges();
          }
        );
      this.loadReply();

      this.cdRef.detectChanges();
    } else {
      this.questionService
        .replyQuestion(
          this.question,
          this.cachedReplyText,
          this.attachmentUrl,
          list_of_tags_ids, 
          this.mode
        )
        .subscribe(
          (reply) => {
            
            this.ishideComment = true;
            this.showReplies =false;
            this.reset();
            this.isLoading = false;
            this.showComment = false;
    this.showReplies =false;
    this.replyTo = null;
            this.replyToId = null;
            this.orderingSubReplies(reply.data.replyQuestion.reply);
          //  this.repliesTree = [reply.data.replyQuestion.reply, ...this.repliesTree];
          // this.repliesTree = this.repliesTree.concat(reply.data.replyQuestion.reply);
            this.appState.set('isAnimationApplied',true);
            this.loadReply();
            // this.getHabitDetails();
            this.isLoading = true;
            this.toastrService.success(
              this.i18n.t("Comment posted!")
            );
            this.appState.set('isCommentPosted',true)
            
            this.cdRef.detectChanges();
          },
          (error) => {
            console.log(error);
            this.loadReply();
            this.isLoaded = true;
            this.toastrService.error(
              this.i18n.t("Comment not posted. Check your connection and try reloading the app."),
              this.i18n.t("Error")
            );
            this.isLoading = false;
            this.cdRef.detectChanges();
          }
        );
      this.cdRef.detectChanges();
    }

    SessionService.setData(0)


  }

  translate($event){
    let toggleEnabled = $event.detail.checked
    console.log('toggleEnabled :', toggleEnabled);
    if (toggleEnabled === true) {
      this.isTranslated =true;
      this.flag =false;
      
      const repliesIds = this.repliesTree.map((reply) => reply.id);
       this.path = this.appState.get("currentPath");

      this.translationService
        .loadRepliesTranslations(repliesIds, this.path.language)
        .subscribe((translations) => {
          this.repliesTree.forEach((reply) => {
            const key = `Reply:${reply.id}`;
            reply.text = translations[key].text;
            this.isTranslated =false;
            this.flag = true
            this.cdRef.detectChanges()
          });
        });
    }

    else {
      this.flag = false;
      this.appState.set('commentDeleted', true)
      this.cdRef.detectChanges()
    }
  }
  showRecords() {
   let count = this.repliesTree?.length-this.recordsToShow;
   this.hideRecords =this.recordsToShow;
   if(count>=10) {
    this.recordsToShow = this.recordsToShow+10;
   }
   else {
    this.recordsToShow = this.recordsToShow+count;
   }
  }
  select(val) {
   if(!val && this.recordsToShow ==2) {
    if(this.repliesTree?.length>10){
      this.recordsToShow =10;
      this.hideRecords =0;
    }
    else {
      this.recordsToShow = this.repliesTree?.length;
      this.hideRecords =0;
    }
     if (this.replyCount <= 2 && this.repliesTree.length>1 &&this.repliesTree.length<=2) {
          this.check = false;
        } else {
          this.check = true;
     }
     this.cdRef.detectChanges()
   } 
  else if (!val){
    if (this.replyCount > 1 && this.repliesTree.length <= 2) {
        this.recordsToShow = this.repliesTree.length;
        this.hideRecords = 0;
      if (this.replyCount <= 2 && this.repliesTree.length>1 &&this.repliesTree.length<=2) {
          this.check = false;
        } else {
          this.check = true;
     }      
        this.cdRef.detectChanges()
    }
   }
  else if(val){
    if(this.repliesTree.length <2){
      this.recordsToShow = this.repliesTree.length;
     }
     else {
       this.recordsToShow = 2;
     }
    this.hideRecords = 0;
     this.check = false;
     this.page = 1;
     this.apiReplyCount = 0;
    this.cdRef.detectChanges()
    this.cardContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    this.cardContainer.nativeElement.scrollTop = 0;
   }
    if(!val && this.repliesTree?.length>=1 && this.replyCount>1) {
    this.render.removeClass(this.repliesContainer?.nativeElement, 'hide_comment');
    }
    if(val && !this.check) {
  
      this.render.addClass(this.repliesContainer?.nativeElement, 'hide_comment');
      }
    this.cdRef.detectChanges()
  }
  unselect() {
    this.check=false;
    this.cdRef.detectChanges()
  }
  reset() {
    this.replyText = "";
    this.attachmentUrl = null;
    if (this.uploader) {
      this.uploader.reset();
    }
    this.cachedReplyText = "";
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText;
      this.cachedReplyText = "";
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText;
    this.replyText = "";
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset();
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    this.showReplies =true ;
    if (this.replyToComponent) {
    }
    this.showComment =false;
    if(replyComponent?.selected){
      
      if(this.showReplies) {
        setTimeout(()=>
        {this.txtarea_post?.nativeElement.focus();
        },500);    }
    }
    else {
      this.showReplies =false;
    }
    this.replyToComponent = replyComponent;
    if (replyComponent) {
      this.replyTo = replyComponent.reply;
      this.replyToId = this.replyTo.id;
    } else {
      this.replyTo = null;
      this.replyToId = null;
    }
  }

  refresh() {
    console.log(this.router.url);
    this.apollo.getClient().clearStore();
    this.appState.refreshMainComponent.emit();
  }

  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log("click event");
      console.log(this.replyBlock?.nativeElement.offsetTop);

      setTimeout(() => {
        window.scrollTo({
          top: this.replyBlock?.nativeElement.offsetTop - 160,
          behavior: "auto"
        });
      }, 10);
    }
  }

  totalLikes(items) {
    return items.reduce((a, b) => +a + +b.votesCount, 0);
  }

  clickOnInput() {
    this.txtarea_post?.nativeElement.focus();
  }

  toggleVote(reply) {
   
    mixpanel.track('Community-web-post like', {'post id': reply.id, 'post question': reply.text, 'post type': reply.type, 'post vote': reply.hasVoted} )
    this.bounceAnimation()
    if (this.isHomeRoute) {
      this.doLikeUnlike();
    }
    else {
      this.voteLoading = true;
      reply.hasVoted = !reply.hasVoted;
      reply.votescount += reply.hasVoted ? 1 : -1;

      this.cdRef.detectChanges();
      if (reply.hasVoted) {
        reply.likes?.push(this.user?._data);
        this.data.likes = reply.likes;
        this.question.likes = reply.likes;
      }
      else {
        reply.likes?.forEach((user, index) => {
          if (user.id === this.user?._data.id)
            reply.likes?.splice(index, 1);
        });
        this.data.likes = reply.likes;
        this.question.likes = reply.likes;

      }
      this.replyService.voteReply(reply, this.mode).subscribe(
        (status) => {
          this.voteLoading = false;
          this.cdRef.detectChanges();
        },
        (error) => {
          this.voteLoading = false;
          this.cdRef.detectChanges();
        }
      );
    }  
  }

  complexItems = this.appState.get("userlist");
  

  tempValue = 0
  index = 0

  format(item: any) {
    var data = item['id'];
    setTimeout(() => {
      SessionService.setData(data)
    }, 100);
    return `@${item['username'].toUpperCase()}`;
  }

  mentionConfig: any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp: true
      }
    ]
  };


  focusComment() {
    if (this.appState.get('commentClicked')) {
      this.appState.set('commentClicked', false)
    
        this.txtarea_post?.nativeElement.focus();
    }
    else {
      this.txtarea_post?.nativeElement.blur();
    }
  }

  bounceAnimation() {
    this.render.addClass(this.btn?.nativeElement, 'bounce');
    if(this.btn?.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn?.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render.setAttribute(this.btn?.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn?.nativeElement, 'bounce');
    }, 200);
  }

  showLikeList(postId: number, mode: string) {
    this.mobileService.setIsLikeList(true)
    this.mobileService.setPostId(postId)
    this.mobileService.setMode(mode)
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
  }

  showCommentList(mode: string, id: number) {
    if (!this.isHomeRoute) {
      const requestUserList = {
        id: id,
        path_id: this.path.id,
        mode: mode
      }
      this.mobileService.setIsLikeList(false)
      this.mobileService.setRequestUserList(requestUserList)
      // var dialog = this.dialog.open(AlertUserLikeListComponent, {
      //   panelClass: 'custom-dialog-container'
      // }
      // )
      this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
    }
  }




  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        console.log('cordova not click')
        window.open(e.target.href, '_blank')
      }
    }
    if(e.target.tagName.toLowerCase() === 'span'){
      console.log('target id', e.target.id);
      console.log("e.target.id",e, e.target.id);
      this.getUserData(e, e.target.id);
    }
  }

  getUserData(event, id){
    this.routine.getUserDetailsById(id).subscribe(value=>{
      console.log('userid....', value);
      this.openuserInfo(event, value.data[0]);
    });
  }
  async openuserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }

  onClick(): void {
    this.h1?.nativeElement.scrollIntoView({
      top: this.h1?.nativeElement.offsetTop,
      behavior: 'smooth',
      block: 'nearest'
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 100) {
      this.isScrolledBottom = true
    } else {
      this.isScrolledBottom = false
    }
  }

  async getHabitDetails(val?,isPostDeleted=false) : Promise<any>{
    if (!this.singleHabit) {
      return
    }
   return new Promise(async (resolve, reject) => {
   try {
     let data, replyId =null;
     if (this.focusSpecificCommentPost?.post?.id == this.data?.id && this.focusSpecificCommentPost?.replyId && this.focusSpecificCommentPost?.replyId != undefined && (this.focusSpecificCommentPost?.post?.replies?.length > 0 && this.focusSpecificCommentPost?.post?.replies[0]?.id == this.focusSpecificCommentPost?.replyId)) {
        replyId= this.focusSpecificCommentPost?.replyId
     }
      if (replyId) {
        data = {
          id: this.singleHabit.id,
          path_id: this.path.id,
          mode: this.singleHabit.type,
          page: this.page,
          reply_id: replyId
        }
      }
      else {
         data = {
          id: this.singleHabit.id,
          path_id: this.path.id,
          mode: this.singleHabit.type,
          page: this.page,
        }
      }
     await this.routineV2Service.getAllPostsRepliyDetails(data).subscribe(
       (response) => {
         if (this.appState.get("isAnimationApplied")) {
           this.isLoaded = true;
         }
         this.commentCount = response.data.replies?.length;
         var level1;
         if (this.page == 1 || isPostDeleted) {
           this.apiReplyCount = 0;
           this.repliesTree = [];
         }
         if (this.page == 1 && response.data.replies?.length > 0) {
           this.repliesTree = [];
           level1 = response.data.replies;
         } else if (response.data.replies?.length > 0) {
           level1 = this.repliesTree.concat(response.data.replies);
         }
         this.replyCount = response.data.reply_count;
         this.apiReplyCount = this.apiReplyCount + this.commentCount;
         this.data.reply_count =response.data.reply_count
         this.colleagueList = response.data.colleagues_list;
         this.colleagues_len = response.data?.colleagues_count;
         if (level1) {
           var subReply = level1.filter((item) => item.repliableId);
           level1.forEach((reply) => {
             subReply?.forEach((element) => {
               if (element?.repliableId === reply.id) {
                 reply.replies?.push(element);
               }
               reply.timestamp = new Date(reply.createdAt);
             });
           });

           this.repliesTree = level1.filter(
             (reply) => reply.id && reply.level === 1
           );
           this.data.replies = this.repliesTree
           // if (this.page == 1 && repliesTree?.length > 0) {
           //         this.repliesTree = [];
           //   this.repliesTree = this.repliesTree.concat(repliesTree);
           // }
           // else if(repliesTree?.length>0) {
           //    this.repliesTree = this.repliesTree.concat(repliesTree);
           // }
           this.page = this.page + 1;
           this.appState.set("repliesLength", this.repliesTree.length);
           if (val == false) {
             this.select(val);
           } else if (val == true) {
             this.showRecords();
           }
         }
       },

       (error) => {
         console.log("error", error);
         if (this.appState.get("isAnimationApplied")) {
           this.isLoaded = true;
         }
         this.toasterService.error(
           this.i18n.t(
             "Server or connection issue. Please check your internet connection."
           )
         );
       }
     );
     resolve(true);
   } catch (error) {
     console.log("error", error);
     if (this.appState.get("isAnimationApplied")) {
       this.isLoaded = true;
     }
     reject(error);
     this.toasterService.error(
       this.i18n.t(
         "Server or connection issue. Please check your internet connection."
       )
     );
    }
   });
  }

  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        console.log('response :', response);
        if(response.status){
          this.replyText = response.data.text
          this.cdRef.detectChanges()
          this.toastrService.success(this.i18n.t('Post translated successfully!'))
        }
      },
    (error) => {
      this.toastrService.error(this.i18n.t('Text translation failed!'))
      console.log("TranslationService data ",error);
    }
    )
  }

}

