import { ChangeDetectorRef,Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';
import { I18n } from '../i18n.service';
import { Survey } from '../survey/survey.model';
import { expandOnEnterAnimation, slideInDownAnimation } from 'angular-animations';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'survey-linear-scale',
  templateUrl: './survey-linear-scale.component.html',
  styleUrls: ['./survey-linear-scale.component.scss'],
  animations:[
    expandOnEnterAnimation({ duration: 400 }),
    slideInDownAnimation({duration:300})
  ],

})
export class SurveyLinearScaleComponent implements OnInit {

  @Input() question: Survey
  @Input() pathAverage;
  @Output() blurCard= new EventEmitter<any>();
  @Output() scaleEvent = new EventEmitter<any>();
  @Input() enable= false;
  @ViewChild('slider') slider: MatSlider;
  optionIds: number[]
  lowerScale;
  higherScale;
  scaleValue: any
  answerList: any
  linearScale = 0
  enableFinal: boolean =false;
  isMobileWebIos = false;
  isMobileWeb=false;
  isMobile: boolean
  @Input() parentComponentName: string;

  
  constructor(private appState: AppState, public i18n: I18n,public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,) {
    this.isMobile = appState.get('isMobile')
   }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.lowerScale = this.question.lowerscale
    this.higherScale = this.question.higherscale
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  onInputChange(event) {
    this.scaleValue =event.target.value;
    this.enableFinal =true;
  }


  onTouchMove(event: TouchEvent, slider: MatSlider) {
    const touch = event.touches[0];
    if (slider) {
      this.scaleValue = this.calculateValue(touch.clientX);
      this.enableFinal = true;
      this.cdRef.detectChanges();
    }
  }

  // function to calculate slider value from touch position
  calculateValue(clientX: number): number {
    const min = this.lowerScale;
    const max = this.higherScale;
    const percentage = clientX / window.innerWidth;
    return Math.round(min + percentage * (max - min));
  }
  


  finalize(){
    this.question.isCompleted = true;
    this.enableFinal =false;
    this.enable =true;
    mixpanel.track('linear scale event value', {'value': this.scaleValue})
    this.scaleEvent.emit(
      {
        scaleValue:this.scaleValue.toString(),
        question: this.question,
        higherScale: this.higherScale.toString(),

      });
  }
  enableBlur() {
    this.enable=!this.enable;
   // if(this.enable)
   let value ;
   value =this.linearScale.toString()
   let scale
   scale=this.higherScale.toString()
   
      this.blurCard?.emit({
        enable: this.enable,
        linearScale: value+"/"+scale,
        pathAverage: this.pathAverage + "/"+scale

      })
      
  }
}
