import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  HostListener,
  ViewEncapsulation,
  Inject,
  DoCheck,
  Renderer2,
  Optional,
} from "@angular/core";
import { Question } from "../question/question.model";
import { Reply } from "../reply/reply.model";
import { QuestionService } from "../question/question.service";
import { ReplyService } from "../reply/reply.service";
import { ReplyComponent } from "../reply/reply.component";
import { AttachmentUploaderComponent } from "../shared/attachment-uploader/attachment-uploader.component";
import { ToastrService } from "ngx-toastr";

import { TranslationService } from "../translation/translation.service";
import { AppState } from "../app.service";
import { Path } from "../path/path.model";
import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { CordovaService } from "../cordova.service";
import { I18n } from "../i18n.service";
import { SessionService } from "../session/session.service";
import { Observable } from "rxjs";
import { bounceInAnimation, fadeInLeftAnimation, fadeInRightAnimation, slideInLeftAnimation, slideInLeftOnEnterAnimation, slideInRightAnimation, slideOutLeftAnimation, slideOutRightAnimation } from "angular-animations";
import { AlertUserLikeListComponent } from "../alert-user-like-list/alert-user-like-list.component";
import { MobileChatBoardComponent } from "../pages/mobile-chat-board/mobile-chat-board.component";
import { MobileChatServiceService } from "../pages/mobile-chat-board/mobile-chat-service.service";
import { WINDOW } from "../window.service";
import { WalletService } from "../wallet/wallet.service";
import { RoutineV2Service } from "../routine-v2/routine-v2.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SpinnerService } from "../spinner.service";
import { AnalyticsServiceService } from "../services/analytics-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { RoutineService } from "../daily-content-v2/routine.service";
import { ModalController, Platform, PopoverController } from "@ionic/angular";
import { UserPublicProfileComponent } from "../components/user-public-profile/user-public-profile.component";
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";
import { HomeUiService } from "../new-design/home-ui.service";

@Component({
  selector: "app-full-screen-feed",
  templateUrl: "./full-screen-feed.component.html",
  styleUrls: ["./full-screen-feed.component.scss"],
  animations: [
    slideInLeftAnimation({ duration: 300 }),
    fadeInRightAnimation({ duration: 300 }),
    fadeInLeftAnimation({ duration: 300 }),
    slideInLeftOnEnterAnimation({duration:500, delay:100}),
    slideOutLeftAnimation({duration: 500, delay:0}),
    slideOutRightAnimation({duration:300, delay:0}),
    bounceInAnimation()
  ]
})
export class FullScreenFeedComponent implements OnInit, DoCheck, AfterViewInit {
  
  @Input() mode: string = "question";
  @Input() isPreview: boolean;
  @Input() inverted: boolean = false;
  @Input() small = false;
  @Input() replyId;
  @Output() closed: EventEmitter<any>;
  @Output() itemSelected: EventEmitter<any>;
  @ViewChild("uploader") uploader: AttachmentUploaderComponent;
  @ViewChild("replyBlock") replyBlock: ElementRef;
  @ViewChild("repliesContainer") repliesContainer: ElementRef;
  @ViewChild("textareaForFeeds") txtarea_post: ElementRef;
  @ViewChild('btn') btn: ElementRef;
  @ViewChild('h1')
  h1: ElementRef;
  @ViewChild('replyComponent') replyComponent: ReplyComponent;
  @Input() data;
  @Input() focuseTextArea;
  isTranslated;
  flag;
  isTextboxHide =true;
  textareaContentLength=0;
  leftState=false;
  rightState =false;
  path: Path;
  ishideComment: boolean = false;
  sortBy: string = "timestamp";
  replyText: string = "";
  cachedReplyText: string = ""; // LUMBA-1383
  attachmentUrl: string = null;
  errors: { answerError: false };
  replyTo: Reply = null;
  replyToComponent: ReplyComponent;
  replyToId: number;
  isLoading: boolean = false;
  replyOffset: number;
  selected: boolean = false;
  voteLoading: boolean = false;
  user: any;
  question: any;
  isLoaded =false;
  tagName_id: number
  dataOfUser: Observable<any>;
  namesData: any[]
  tempData: any
  state: boolean = false;
  animation = ['bounceIn'];
  isScrolledBottom: boolean = false
  singleHabit: any
  commentCount: number = 0
  public replies: Reply[]
  public repliesTree: any[] =[]
  colleagueList = [];
  colleagues_len=0;
  isMobile: boolean;
  tagsArray = [{ id: 'test', username: 'test' }];
  page
  apiRepliesCount = 0;
  replyCount = 0;
  pathRegistration
  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private routine:RoutineService,
    private toastrService: ToastrService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private mobileService: MobileChatServiceService,
    private routineV2Service: RoutineV2Service,
    private modalController :ModalController,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToastrService,
    private walletService: WalletService,
    private analyticsService: AnalyticsServiceService,
    private sanitizer:DomSanitizer,
    private keyboard: Keyboard,
    private popoverController:PopoverController,
    public render: Renderer2,
    public platform: Platform,
    public homeUiService: HomeUiService,
    @Inject(WINDOW) private window: Window,
  //  @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.path = this.appState.get("currentPath");
    this.user = this.appState.get("currentUser");
     this.pathRegistration = this.appState.get("pathRegistration");
    this.isMobile = this.appState.get('isMobile')
    let dataOfFeeds = this.appState.get('singleFeed')
    if (!dataOfFeeds) {
      this.refresh()
    }
    this.tempData = dataOfFeeds["question"];
    this.mode = dataOfFeeds["modes"];
    this.appState.set("mode", this.mode + "s");
    this.question = this.sessionService.findById(this.path[dataOfFeeds["modes"] + 's'], this.tempData?.id)
    this.appState.set('oldActionPoints', this.walletService.actionPoints.value)
    this.singleHabit = this.appState.get('singleHabit')
    this.analyticsService.setScreen(AnalyticsServiceService.FULLFEED)
    this.page = 1;
  }

  async ngDoCheck() {
    let isCommentDeleted = this.appState.get('commentDeleted')    
    if (isCommentDeleted) {
      this.appState.set('commentDeleted', undefined)
      this.page = 1;
      await this.getHabitDetails(null, true);
    }
  }

  ngOnInit() {
    // LUMBA-1317
    this.getHabitDetails(this.replyId?this.replyId:null)
   setTimeout(()=>{
    if(this.focuseTextArea) {
      this.clickOnInput();
     }
   },600)
    
    this.isLoaded =false
    this.toggleSort("timestamp");
    this.data = this.data;
    this.appState.set('communityNotification', undefined)
    this.appState.set('notificationData', undefined)
  }
  ngAfterViewInit() {
    this.focusComment()
    window.scroll(0, 0);
    //     // this line moves the screen at the bottom scroll
    //this.replyOffset = 0;
    if(this.isMobile){
      if(this.platform.is('android')) {
       let id =document.getElementsByClassName('list-group')[0];       
      //  id?.setAttribute('style', 'margin-top:60px;')
        this.keyboard.onKeyboardDidShow().subscribe((info) => {
          console.log('keyboard will show with height', info.keyboardHeight);
          this.replyBlock.nativeElement.style.setProperty(
            'bottom',
            `${0}px`
          );
          this.replyBlock.nativeElement.style.setProperty(
            'padding-bottom',
            `${25}px`
          );
        });
    
        this.keyboard.onKeyboardDidHide().subscribe(() => {
          console.log('keyboard did hide');
          this.replyBlock.nativeElement.style.setProperty(
            'bottom',
            `${0}px`
          );
          this.replyBlock.nativeElement.style.setProperty(
            'padding-bottom',
            `${20}px`
          );
        });
      }
    }
    // this.showCommentsData(this.data);

  }
  showCommentsData(data) {
    this.commentCount = data.replies?.length
          
             var level1 = data.replies;
            this.colleagueList = data.colleagues_list;
            this.colleagues_len = data?.colleagues_count;
            this.replyCount = data.reply_count;
            if (level1) {
              var subReply = level1.filter(item => item.repliableId)
              level1.forEach(reply => {
                subReply.forEach(element => {
                  let flag=0
                  reply?.replies?.forEach((item)=>{
                    if(item.id===element.repliableId)
                    {
                       flag ++;
                    }
                  })
                  if (element.repliableId === reply.id && flag ==0) {
                    reply.replies?.push(element)
                    reply.replies = [...new Set( reply.replies)];
                  }
                  reply.timestamp = new Date(reply.createdAt)
                });
              });
  
              this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
              this.isLoaded =true;
        
              this.appState.set('repliesLength', this.repliesTree?.length)
            }
  
  }
  itemMentioned(tag) {
    console.log("selected ", tag);
  }
  async openUserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false;
    }
    this.question.sortRepliesBy(this.sortBy);
    this.cdRef.detectChanges();
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by;
    this.question.sortRepliesBy(by);
  }

  hideAndShowComment() {
    if (this.ishideComment) {
      this.ishideComment = false;
    } else {
      this.ishideComment = true;
    }
  }
  checkTextareaLength() {
      let len = this.replyText?.trim();
      this.textareaContentLength =len.length;
      console.log("LENGTH>>>", len)
  }

  orderingSubReplies(reply) {
    this.isLoaded = true;
    let replySort;
    this.isTextboxHide = true;
    this.appState.set("isMobileReply", false);
    // this.commentCount = reply.reply_count;
    // this.data.votescount = reply?.voteCount;
    this.data.reply_count = this.data.reply_count+1;
    var level1 = [reply, ...this.repliesTree];
    if (level1) {
      var subReply = level1.filter(item => item.repliableId)
      level1?.forEach(reply => {
        subReply?.forEach(element => {
         if (element?.repliableId === reply.id) {
            const updatedReply = { ...reply };
            updatedReply.replies = [element, ...updatedReply.replies];
            updatedReply.timestamp = new Date(reply.createdAt);
            //replySort =updatedReply;
            const index = level1.findIndex(r => r.id === updatedReply.id);
            level1[index] = updatedReply;
          }
        });
      });
    //  if (replySort) {
    //     replySort.timestamp = new Date(reply.createdAt);
    //   }
    //  let index =level1?.findIndex((reply) => reply.id == replySort.id)
    //   level1.splice(index,1)
      let repliesTree = level1.filter(reply => reply.id && reply.level === 1)
      this.repliesTree = repliesTree;
      this.data.replies = this.repliesTree;
      console.log("repliestree in comment list", this.repliesTree);
    }
  }
  reply() {

    if(this.replyTo) {
      this.isTextboxHide =false;
     this.appState.set('isMobileReply',true)
    }
    if(this.textareaContentLength>0){
      this.isLoaded =false
    }
    console.log("New tag ", SessionService.getData()
    , this.replyText?.length);
    console.log("New tag ", typeof SessionService.getData()
    );
    let formatedText;
    //this.tagName_id = SessionService.getData()

    if (this.replyText?.length == 0) {
      this.toastrService.error(
        this.i18n.t("Please enter a comment"),
        this.i18n.t("Error")
      );
      return false;
    }

    // if (!this.replyText || this.replyText.length < 3) {
    //   this.toastrService.error(this.i18n.t('Comment is too short'), this.i18n.t('Error'))
    //   return false
    // }

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText, this.complexItems)

    // if (this.isPreview) {
    //   return false;
    // }
    // if (this.isLoading) {
    //   return false;
    // }
    this.isLoading = true;


    this.complexItems.forEach(element => {
      let matchText = `@${element.username.toUpperCase()}`;
      if (this.replyText.includes(matchText)) {
        let replaceValue = `<span id="${element.id}">${element.username.toUpperCase()} </span>`;
        formatedText = this.replyText.replace(RegExp(matchText, 'g'), replaceValue);
      }
    });

    //this.replyText=formatedText;

    this.unloadReply();
    if (formatedText) {
      this.cachedReplyText = formatedText;
    }
    if (this.replyTo) {
      this.isLoaded =true;

      this.replyService
        .reply(
          this.question,
          this.replyTo.id,
          this.cachedReplyText,
          this.attachmentUrl,
          list_of_tags_ids,
          this.question.id,
          this.mode,

        )
        .subscribe(
          (reply) => {
            this.ishideComment = true;
            this.replyTo = null;
            this.replyToId = null;
            this.reset();
            this.isLoading = false;
            this.appState.set('isReplyAnimationApplied',true);
            this.toastrService.success(
              this.i18n.t("Comment posted!")
            );
            this.appState.set('isCommentPosted', true)
            this.replyComponent.replyText = '';
            this.replyComponent.isLoaded = true;
            this.replyComponent.isMobileLoaded=true
            //this.appState.set('oldPoints',this.walletService.actionPoints.value)
            //localStorage.setItem("oldPoints",this.walletService.actionPoints.value.toString())
            this.loadReply();
            this.orderingSubReplies(reply)
            this.isLoading = true;
            // this.getHabitDetails();
            this.cdRef.detectChanges();
            // if (this.repliesContainer != undefined) {
            //   this.repliesContainer.nativeElement.scrollIntoView();
            //   this.repliesContainer.nativeElement.scrollTop = 0;
            // }
          },
          (error) => {
            this.toastrService.error(
              this.i18n.t("Comment not posted. Check your connection and try reloading the app."),
              this.i18n.t("Error")
            );
            this.isLoading = false;
             this.isLoaded = true;
            this.isTextboxHide = true;
            this.appState.set("isAnimationApplied", true);
             this.appState.set("isMobileReply", false);
            this.loadReply();
            this.cdRef.detectChanges();
          }
        );
      this.loadReply();
      this.cdRef.detectChanges();
    } else {
      this.questionService
        .replyQuestion(
          this.question,
          this.cachedReplyText,
          this.attachmentUrl,
          list_of_tags_ids, 
          this.mode
        )
        .subscribe(
          (reply) => {
            this.ishideComment = true;
            this.reset();
            this.isLoading = false;
            this.replyTo = null;
            this.replyToId = null;
            this.appState.set('isAnimationApplied',true);
            this.loadReply();
            // this.getHabitDetails();
            this.orderingSubReplies(reply.data.replyQuestion.reply);
            this.isLoading = true;
            this.toastrService.success(
              this.i18n.t("Comment posted!")
            );
           
            this.cdRef.detectChanges();
          },
          (error) => {
            console.log(error);
            this.loadReply();
            this.isLoaded = true;
            this.toastrService.error(
              this.i18n.t("Comment not posted. Check your connection and try reloading the app."),
              this.i18n.t("Error")
            );
            this.isLoading = false;
            this.cdRef.detectChanges();
          }
        );
      this.cdRef.detectChanges();
    }

    SessionService.setData(0)


  }

  translate($event){
    let toggleEnabled = $event.detail.checked
    console.log('toggleEnabled :', toggleEnabled);
    if (toggleEnabled === true) {
      this.isTranslated =true;
      this.flag =false;
      
      const repliesIds = this.repliesTree.map((reply) => reply.id);
       this.path = this.appState.get("currentPath");

      this.translationService
        .loadRepliesTranslations(repliesIds, this.path.language)
        .subscribe((translations) => {
          this.repliesTree.forEach((reply) => {
            const key = `Reply:${reply.id}`;
            reply.text = translations[key].text;
            this.isTranslated =false;
            this.flag = true
            this.cdRef.detectChanges()
          });
        });
      console.log('this.repliesTree after translation:', this.repliesTree);
    }

    else {
      this.flag = false;
      this.appState.set('commentDeleted', true)
      this.cdRef.detectChanges()
    }
  }

  reset() {
    this.replyText = "";
    this.attachmentUrl = null;
    if (this.uploader) {
      this.uploader.reset();
    }
    this.cachedReplyText = "";
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText;
      this.cachedReplyText = "";
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText;
    this.replyText = "";
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset();
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
      this.replyToComponent.selected = false;
    }
    if(replyComponent?.selected){
      this.txtarea_post?.nativeElement.focus();
    }
    this.replyToComponent = replyComponent;
    if (replyComponent) {
      this.replyTo = replyComponent.reply;
      this.replyToId = this.replyTo.id;
    } else {
      this.replyTo = null;
      this.replyToId = null;
    }
  }

  refresh() {
    console.log(this.router.url);
    this.apollo.getClient().clearStore();
    this.appState.refreshMainComponent.emit();
  }
  onCommentClick() {
    this.txtarea_post?.nativeElement.focus();
      this.replyTo = null;
      this.replyToId = null;
  }
  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log("click event");
      console.log(this.replyBlock?.nativeElement.offsetTop);

      setTimeout(() => {
        window.scrollTo({
          top: this.replyBlock?.nativeElement.offsetTop - 160,
          behavior: "smooth",
        });
      }, 10);
    }
  }

  close() {
  
      this.modalController.dismiss();
   
  }

  totalLikes(items) {
    return items.reduce((a, b) => +a + +b.votesCount, 0);
  }

  clickOnInput() {
    this.txtarea_post?.nativeElement.focus();
  }



  toggleVote(reply) {
   
    this.bounceAnimation()

    this.voteLoading = true;
    reply.hasVoted = !reply.hasVoted;
    reply.votescount += reply.hasVoted ? 1 : -1;

    this.cdRef.detectChanges();
    if(reply.hasVoted)
    {
      reply.likes?.push(this.user?._data);
      this.data.likes = reply.likes;    
      this.question.likes = reply.likes;  

    }
    else {
      reply.likes?.forEach( (user, index) => {
        if(user.id === this.user?._data.id) 
        reply.likes?.splice(index,1);
      });
      this.data.likes = reply.likes;    
      this.question.likes = reply.likes;  
    }
    this.replyService.voteReply(reply, this.mode).subscribe(
      (status) => {
              
        this.voteLoading = false;
        this.cdRef.detectChanges();
      },
      (error) => { },
      () => {
        this.voteLoading = false;
        this.cdRef.detectChanges();
      }
    );
  }

  complexItems = this.appState.get("userlist");
  

  tempValue = 0
  index = 0

  format(item: any) {
    var data = item['id'];
    setTimeout(() => {
      SessionService.setData(data)
      //this.tagsArray.push(item);
    }, 100);
    return `@${item['username'].toUpperCase()}`;
    //return item['username'].toUpperCase()
  }

  mentionConfig: any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp: true
      }
    ]
  };


  focusComment() {
    if (this.appState.get('commentClicked')) {
      this.appState.set('commentClicked', false)
    
        this.txtarea_post?.nativeElement.focus();
    }
    else {
      this.txtarea_post?.nativeElement.blur();
    }
  }

  bounceAnimation() {
    this.render.addClass(this.btn.nativeElement, 'bounce');
    if(this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn.nativeElement, 'bounce');
    }, 200);
  }

  showLikeList(postId: number, mode: string) {
    this.mobileService.setIsLikeList(true)
    this.mobileService.setPostId(postId)
    this.mobileService.setMode(mode)
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
  }

  showCommentList(mode: string, id: number) {

    const requestUserList = {
      id: id,
      path_id: this.path.id,
      mode: mode
    }
    this.mobileService.setIsLikeList(false)
    this.mobileService.setRequestUserList(requestUserList)
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
  }




  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        console.log('cordova not click')
        window.open(e.target.href, '_blank')
      }
    }
    if(e.target.tagName.toLowerCase() === 'span'){
      console.log('target id', e.target.id);
      this.getUserData(e, e.target.id);
    }
  }

  getUserData(event, id){
    this.routine.getUserDetailsById(id).subscribe(value=>{
      console.log('userid....', value);
      this.openuserInfo(event, value.data[0]);
    });
  }
  async openuserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }

  onClick(): void {
    this.h1?.nativeElement.scrollIntoView({
      top: this.h1?.nativeElement.offsetTop,
      behavior: 'smooth'
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 100) {
      this.isScrolledBottom = true
    } else {
      this.isScrolledBottom = false
    }
  }
 doInfinite(event) {
    console.log('doInfinite :');
    this.getHabitDetails(event);
  }
  getHabitDetails(event=null,isPostDeleted =false): Promise<any> {
    if (!this.singleHabit) {
      return
    }
    let replyId;
    if (event != null || event != undefined || (this.data?.replies?.length>0 && event== this.data?.replies[0]?.id )) {
       replyId= event
    }
  return new Promise( async (resolve, reject) => {
    try {
      let data
      if (replyId) {
        data = {
          id: this.singleHabit.id,
          path_id: this.path.id,
          mode: this.singleHabit.type,
          page: this.page,
          reply_id: replyId
        }
      }
      else {
         data = {
          id: this.singleHabit.id,
          path_id: this.path.id,
          mode: this.singleHabit.type,
          page: this.page
        }
      }

      await this.routineV2Service.getAllPostsRepliyDetails(data).subscribe(
        (response) => {
          this.isLoaded =true
          this.isTextboxHide =true;
          this.appState.set('isMobileReply',false)
          this.commentCount = response.data.reply_count;
          this.data.votescount= response.data?.voteCount;
          this.data.reply_count = response.data?.reply_count;  
           var level1;
             if(this.page ==1 || isPostDeleted) {
                this.apiRepliesCount = 0;
               this.repliesTree = [];
             }
            if (this.page == 1 && response.data.replies?.length > 0) {
              this.repliesTree = [];
              level1 = response.data.replies;
            }
            else if (response.data.replies?.length > 0) {
              level1 = this.repliesTree.concat(response.data.replies);
            }
          this.replyCount = response.data.reply_count;
          this.apiRepliesCount += response.data.replies?.length;
          this.colleagueList = response.data.colleagues_list;
          this.colleagues_len = response.data?.colleagues_list?.lenngth;
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1?.forEach(reply => {
              subReply?.forEach(element => {
                if (element?.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });

            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            // if (this.page == 1 && repliesTree?.length > 0) {
            //   this.repliesTree = [];
            //   this.repliesTree = this.repliesTree.concat(repliesTree);
            // }
            // else if(repliesTree?.length>0) {
            //    this.repliesTree = this.repliesTree.concat(repliesTree);
            // }
            this.page = this.page + 1
            if (event) {
              event.target.complete();
            }
            this.data.replies = this.repliesTree;
            console.log('repliesTree', this.repliesTree)            
            this.appState.set('repliesLength', this.repliesTree.length)
          }

        },

        (error) => {
          this.isLoaded =true
          this.isTextboxHide =true;
          this.appState.set('isMobileReply',false)
          console.log('error', error)
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
      resolve(true);
    } catch (error) {
      this.isLoaded =true
      this.isTextboxHide =true;
      this.appState.set('isMobileReply',false)
      console.log('error', error)
      reject(error);
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
    }
    });
  }

  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        console.log('response :', response);
        if(response.status){
          this.replyText = response.data.text
          this.cdRef.detectChanges()
          this.toastrService.success(this.i18n.t('Post translated successfully!'))
        }
      },
    (error) => {
      this.toastrService.error(this.i18n.t('Text translation failed!'))
      console.log("TranslationService data ",error);
    }
    )
  }

}
