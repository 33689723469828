import { Component, HostListener, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CordovaService } from '../cordova.service';
import { I18n } from '../i18n.service';
import { AppState } from "../app.service";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Path } from "../path/path.model";
import { ActivatedRoute, Router } from "@angular/router";
import mixpanel from "mixpanel-browser";
import { EventEmitter, Output } from "@angular/core";
import { HabitListItem } from "../habit-list-modal/habit-list-modal.model";
import { WINDOW } from 'src/app/window.service';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { AlertWebactionCalendarComponent } from 'src/app/alert-webaction-calendar/alert-webaction-calendar.component';
import { SpinnerService } from 'src/app/spinner.service';
import { HabitService } from 'src/app/habit/habit.service';
import * as moment from 'moment';
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model';
import { CalendarService } from 'src/app/calendar.service';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import { CompletedReviewModalComponent } from 'src/app/completed-review-modal/completed-review-modal.component';
import { RoutineService } from '../daily-content-v2/routine.service';
import { FullScreenDocumentComponent } from '../my-library/full-screen-document.component';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { HomeUiService } from '../new-design/home-ui.service';
import { Constants } from '../constants/constants';


export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}

@Component({
  selector: 'app-full-todo-info',
  templateUrl: './full-todo-info.component.html',
  styleUrls: ['./full-todo-info.component.scss'],
})
export class FullTodoInfoComponent implements OnInit {

  type: string
  action: any
  level: any
  levelDescription: string
  currentRoutine: any
  habit: any
  currentPath: Path;
  audioFormats = [".mp3"];
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  scheduledDay: number
  habits: HabitListItem[];
  hasNativeCalendar: boolean
  calendarParams: CalendarParams
  currentUser: any
  inoutScreen: InoutScreen
  yesterdayHabit: any
  currentDay: number
  modalDataResponse: any;

  @Output() closeItemEvent = new EventEmitter<boolean>();

  constructor(private navParams: NavParams,
    private modalController: ModalController,
    public i18n: I18n,
    private appState: AppState,
    public router: Router,
    private cordovaService: CordovaService,
    private homeService: HomeV5Service,
    private dialog: MatDialog,
    private routineService: RoutineService,
    private spinnerService: SpinnerService,
    private habitService: HabitService,
    private homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,
    private calendarService: CalendarService,
    private dailyStepsService: DailyStepsService,

     @Inject(WINDOW) private window: Window) { 
      this.habit = this.appState.get('singleHabit')
      this.currentUser = this.appState.get('currentUser')
      this.currentPath = this.appState.get("currentPath")
      const w: any = window
      this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
     }

  ngOnInit() {
    this.type = this.navParams.get('type');
    this.action = this.navParams.get('action');
    this.level = this.navParams.get('level');
    // this.currentDay = this.navParams.get('currentDay');
    this.currentDay = this.appState.get('currentScheduleDay')
    this.currentRoutine = this.navParams.get('currentRoutine')
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)

    this.setYesterdayHabit()
  }
  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }
  setYesterdayHabit() {
    this.currentPath = this.appState.get("currentPath")
    // LUMBA-1373
    if (this.currentDay < 1) {
      return
    }
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
    // LUMBA-1360
    let currentHabitIndex
    let prevHabitIndex
    for (let i in this.currentPath.habits) {
      const habit = this.currentPath.habits[i]
      if (
        
        habit.startDay <= this.currentDay &&
        this.currentDay <= habit.endDay
      ) {
        if (!currentHabitIndex) {
          currentHabitIndex = i
        }
      } else if (
        habit.startDay <= this.currentDay &&
        habit.endDay <= this.currentDay
      ) {
        prevHabitIndex = i
      }
    }
    if (currentHabitIndex >= 0) {
      prevHabitIndex = currentHabitIndex
    } else if (!prevHabitIndex) {
      return
    }
    this.yesterdayHabit = this.currentPath.habits[prevHabitIndex] // LUMBA-1344
    console.log('yesterdayHabit :', this.yesterdayHabit);

  }

  close() {
    this.modalController.dismiss();
  }

  getTitle(title){
    return title.slice(15,-1)
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  // open tool function 
  OpenDocumentId(){
    this.openDocument(this.action)
  }
  openDocument(post) {
    this.currentPath = this.appState.get("currentPath")
    this.appState.set('singleDocument', post)
    let posts;
      if(post.document?.id == post?.casualDocumentId) {
        posts = post.document;
      }
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    this.close()
    this.appState.set('singleDocument', posts)
    this.appState.set('journalPost', undefined)
    if (posts.isrecommended && posts.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', posts.startDay)
    const url = posts.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      mixpanel.track('InSteps-ToDo-Action-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
      // this.router.navigate(['/audio-library'], { state: { data: posts } })
      // this.router.navigate(['/audio-library'], { state: { data: posts } })
      this.homeUIService.openNextComponent(FullScreenAudioComponent,{ data: posts })
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      mixpanel.track('InSteps-ToDo-Action-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
      // this.router.navigate(['/video-library'], { state: { data: posts } })
      this.homeUIService.openNextComponent(FullScreenVideoComponent,{ data: posts })
    } else {
      console.log('Document');
      mixpanel.track('InSteps-ToDo-Action-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
      // this.router.navigate(['/detail-document'])
      this.homeUIService.openNextComponent(FullScreenDocumentComponent)
     
    }
  }

  // rescheduled function 
  rescheduleAction(){
    this.habitScheduleLater(this.action)
  }
  async habitScheduleLater(habit) {
    let posts, docLink;
      if(habit?.document?.id == habit?.casualDocumentId) {
        posts =habit.document;
      }
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    mixpanel.track("InSteps-ToDo-Reschedule Habit", {'id': habit.id, 'pathName': habit.pathName, 'isConvertedToRoutine': habit.isConvertedToRoutine, 'screenId': habit.screenId, 'title': habit.title, 'startDay': habit.startDay})
    this.prepareCalendarParams(this.innerHTMLToMainTitle(), this.routineService.innerHtmlToMobileAction(habit.text,docLink))

    if (this.hasNativeCalendar) {
      this.createCalendarEvent()
    } else {
      this.calendarParams.notes = this.routineService.innerHtmlToWebAction(habit.text,docLink)

      this.homeService.setCalendarData(this.calendarParams)
      // const dialogRef = this.dialog.open(AlertWebactionCalendarComponent)
      // dialogRef.afterClosed().subscribe((result) => {
        
      // })
      const modal = await this.modalController.create({
        component: AlertWebactionCalendarComponent,
        animated: false,
        cssClass: 'custom-modal-size',
       // enterAnimation: this.animationService.slideInLeftEnterAnimation,
        //leaveAnimation:this.animationService.SlideOutRightLeaveAnimation
      });
       await modal.present() 
    }

    // if (this.yesterdayHabit.scheduledLater > 0 && this.isMobile) {
    //   return false
    // }

    localStorage.setItem(
      `Toggle:User:${this.currentUser.id}:HabitScheduled:${habit.id}`,
      'yes'
    )

    // this.spinnerService.on()
    this.habitService.scheduleLater(habit.id).subscribe(res => {
      // LUMBA-1344
      // this.spinnerService.off()
      this.cdRef.detectChanges()
      this.close()

    })
  }
  prepareCalendarParams(title: any, msg: String, routinelen: any = null, eventlen: string = null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    var titleOfEvent = "Daily Learning Reminder"
    if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
      titleOfEvent = this.currentPath.names.titleOfEvent;
    } else {
      titleOfEvent = "Daily Learning Reminder"
    }

    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }
  innerHTMLToMainTitle() {
    var divMainTitle = document.createElement("DIV");
    divMainTitle.innerHTML = this.inoutScreen?.homepageSubtext.text;
    let tv_mainTitle = divMainTitle.innerText;
    return tv_mainTitle;
  }
  innerHtmlToNotesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    return cleanText + `\n\n Here is the tool you saw in the ${Constants.appName} app to help you complete this action: ` + arrayOfUrls + `\n\n After you have completed this action, mark it complete in the ${Constants.appName} app by clicking here: ${Constants.deepLinkURL}`;
  }
  createCalendarEvent() {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);
    const showAlertPrompt=true;
    const daysCount = this.currentPath.daysOfContent * 2  // LUMBA-1399
    // calOptions.recurrence = 'daily' // supported are: daily, weekly, monthly, yearly
    calOptions.recurrenceInterval = 1
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error,
      showAlertPrompt
    );
  }
  innerHtmlToWebAction(habit: string) {
    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);
    return (
      cleanText +
      `\n\n After you have completed this action, mark it complete in the ${Constants.appName} app by clicking here: ${Constants.deepLinkURL}`
    );
  }

  // mark as complete function 
  completeAction(){
    this.habitConfirm(this.action)
  }
  habitConfirm(habit) {
    mixpanel.track("InSteps-ToDo-Mark Habit Complete", {'title': habit.title, 'startDay': habit.startDay, 'screenId': habit.screenId,'pathName': habit.pathName,'isConvertedToRoutine': habit.isConvertedToRoutine, 'id': habit.id,'casualDocumentId': habit.casualDocumentId})
    this.close()
    var isHabitComplete = habit.confirmation
    try {
      this.dailyStepsService.removeFromSnoozed(this.yesterdayHabit.id)
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:HabitConfirmed:${this.yesterdayHabit.id}`,
        'yes'
      )
      localStorage.removeItem(
        `Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`
      )
      // this.spinnerService.on()
      this.habitService.confirm(habit.id).subscribe(res => {
        // LUMBA-1344
        // this.spinnerService.off()
        this.cdRef.detectChanges()
         try {
      let todaysHabit
      if (this.currentDay != 0) {
        todaysHabit = this.findLastHabit(this.currentPath.habits, this.currentDay)
      }
      if (this.currentPath.enabledAttributes.feedback && isHabitComplete == 0) {
        this.initModal(habit)
      }
    } catch (o_O) {
      console.log(o_O);
    }
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }

   
  }
  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }
  async initModal(habit) {
    // this.appState.set('currentDay', this.currentDay)
    const modal = await this.modalController.create({
      component: CompletedReviewModalComponent,
       componentProps: {currentHabit: habit},
      cssClass: this.homeUIService.isDark() ? 'dark-theme-review-modal mobile-custom-to_do_list-forms' : 'light-theme mobile-custom-to_do_list-forms',
    });
    

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
        this.appState.set("isCommented", false)
      }
    });

    return await modal.present();
  }
}
