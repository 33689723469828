import { Component, DoCheck, HostListener, NgZone, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AppState } from './app.service';
import { SessionService } from './session/session.service';
import { SpinnerService } from './spinner.service';
import { User } from './user/user.model';
import { detectIE } from './utils.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { MyCommitmentsComponent } from './my-commitments/my-commitments.component';
import { AnalyticsServiceService } from './services/analytics-service.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { UpdatePathRegistration, UtilityService } from './common-services/utility.service';
import { Subject, Subscription } from 'rxjs';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { LibraryService } from './my-library/library.service';
import { ToasterService } from './toaster.service';
import { I18n } from './i18n.service';
import { Element, ThisReceiver } from '@angular/compiler';
import { HomeUiService } from './new-design/home-ui.service';
import { MobileChatBoardComponent } from './pages/mobile-chat-board/mobile-chat-board.component';
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { SignInService } from './sign-in/sign-in.service';
import { PathRegistration } from './user/path-registration.model';
import { UserService } from './user/user.service';
import { Constants } from './constants/constants';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

function _window(): any {
  // For Cordova `navigator.app` access
  return window
}

const currentAppVersion = '10053';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements DoCheck, OnDestroy{

  title = 'app'
  user;
  refreshHack = false
  currentClient
  expired: boolean
  client: any
  error: any
  valid: boolean
  udiD: string;
  path: any;
  notificationText: string
  isNotifty: boolean
  subscription: Subscription;
  singleDocument;
  showLoader: any
  progress: number = 0
  currentRouteUrl: string = '';
  isMobile: boolean
  isTablet;
  appPausedAt;
  appStartedAt
  pathRegistration;
  currentUser;
  public updatePathRegistration: UpdatePathRegistration;
  destroyed$ = new Subject<boolean>();
  processedCommitmentNotifications: Set<number> = new Set();
  processedCommunityNotifications: Set<number> = new Set();
  constructor(private appState: AppState,
    private apollo: Apollo,
    private ngZone: NgZone,
    public sessionService: SessionService,
    private deeplinks: Deeplinks,
    private spinnerService: SpinnerService,
    private platform: Platform,
    private route: Router,
    private statusBar: StatusBar,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsServiceService,
    private appVersion: AppVersion,
    private market: Market,
    public alertController: AlertController,
    public utilService: UtilityService,
    private libraryService: LibraryService,
    private toasterService: ToasterService,
    private homeUIService: HomeUiService,
    public i18n: I18n,
    private firebaseX: FirebaseX,
    private screenOrientation: ScreenOrientation,
    private userService: UserService,
    private signInService: SignInService,
    private splashScreen: SplashScreen,
    private navController: NavController) {
    // Mobile entry point
    console.log("app entry point");
    mixpanel.track('Splash Screen Displayed', { timestamp: new Date().toISOString() });
    this.sessionService.removeNotificationBadge()
    const language = _window().navigator.userLanguage || _window().navigator.language || 'en'
    this.appState.set('language', language.substr(0, 2))
    this.appState.set('ieVersion', detectIE()) // LUMBA-1325
    this.path = this.appState.get("currentPath");
    this.pathRegistration =  this.appState.get("pathRegistration")
    this.singleDocument = localStorage.getItem('singleDocument');
    this.currentClient = localStorage.getItem('clientId');
    this.currentUser = this.appState.get("currentUser");
    this.user = localStorage.getItem('userid');
    this.isMobile = this.appState.get('isMobile')
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
    this.route.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        if (events[0].urlAfterRedirects == '/detail-document') {
          if (JSON.parse(localStorage.getItem('annotationData')) && !JSON.parse(localStorage.getItem('isCloseButtonClicked'))) {
            let annotations = JSON.parse(localStorage.getItem('annotationData'))
            if (annotations?.pdfjs_internal_editor_0?.name == "freeTextEditor" || annotations?.pdfjs_internal_editor_0?.name == "inkEditor" || annotations?.pdfjs_internal_editor_0) {
              this.alertControllerCreate();
            }
            else if (annotations) {
              this.checkManualEdits(annotations);
            }
          }

        }
        if (events[0].urlAfterRedirects == '/main') {
          if (this.signInService.showAuthLoader) {
            mixpanel.track("Before resting, the showAuthLoader value", {'showAuthLoader': this.signInService.showAuthLoader})
            this.signInService.showAuthLoader = false;
          }
        }
      });
    this.appState.refreshMainComponent.subscribe(() => {
      this.apollo.getClient().clearStore()
      // FIXME: Android/iOS hack: waiting all lifecyclehooks
      this.ngZone.run(() => {
        this.refreshHack = true
        this.sessionService.refreshSession().subscribe(() => {
          this.refreshHack = false
          this.spinnerService.off()
        })
      })
    })
    this.getToken()
    this.initializeApp();
    this.getAccessTokeFromAsWebAuthentication();
  }

  getAccessTokeFromAsWebAuthentication() {
    (window as any).handleOpenURL = (url: string) => {
      setTimeout(() => {
        console.log("url", url);
        this.platform.ready().then(() => {
          if (this.platform.is('cordova')) {
            if (localStorage.getItem('ssoProvider') =='EXXON') {
              this.signInService.getAccessCodeFromURLMobileExxon(url)
            }
          }
        });
      },200)
    }
  }
 checkManualEdits(annotations ) {
    annotations = Object.values(annotations);
    let previousEdits =[]
    if (localStorage.getItem("previousAnotations")) {
        previousEdits = Object.values(JSON.parse(localStorage.getItem("previousAnotations")));
      }
    const changedAnnotations = annotations.filter(annotation => 
         !previousEdits.some(prev => JSON.stringify(prev) === JSON.stringify(annotation) || prev.value ==annotation.value));
    if (changedAnnotations?.length>0) {
      localStorage.setItem("isCloseButtonClicked", JSON.stringify(true));
      this.alertControllerCreate();
    }
  }
  hideOpenedModals() {
   this.isTablet =
    this.platform.is("mobileweb") &&
    (this.platform.is("ipad") || this.platform.is("tablet"));
    let id = document.getElementsByClassName("modal-default");
    let pathSelectModal = document.getElementsByClassName('select-path-model-custom');
  const isPortrait = this.screenOrientation.type.includes("portrait");
  if (this.isTablet &&(isPortrait ||  window.innerWidth < window.innerHeight)) {
    // document.body.classList.add("hide-modal-portrait");
    if (id?.length > 0 && pathSelectModal?.length ==0) {
      Array?.from(id)?.forEach((tag) => {
        tag?.setAttribute("style", "display:none");
      });
    }
  } else if (this.sessionService.isTabletDevice()) {
    Array?.from(id)?.forEach((tag, index) => {
      let ind = 9999 + index;
      tag?.setAttribute("style", `display:visible;z-index:${ind}`);
    });
    // document.body.classList.remove("hide-modal-portrait");
  }
}
  initializeApp() {
    console.log("app initialization is started");
    mixpanel.track("mixpanel event for splash screen before app initialization");
   this.platform.ready().then(() => {
      // Initialize Mixpanel
     // mixpanel.init('YOUR_MIXPANEL_PROJECT_TOKEN');
   // Splash screen shown
      mixpanel.track('Splash Screen Shown', {
        timestamp: new Date().toISOString(),
        device: this.platform.platforms(),
      });

    try {
     // this.splashScreen.hide();
      console.log("splash screen is closed");
  mixpanel.track('Splash Screen Hidden', { timestamp: new Date().toISOString() });
} catch (error) {
  mixpanel.track('Splash Screen Error', { error: error.message });
}
      const storedStartTime = localStorage.getItem('appStartedAt');
      this.appStartedAt = storedStartTime ? parseInt(storedStartTime, 10) : Date.now();
      localStorage.setItem('appStartedAt', this.appStartedAt.toString());

      // Use Cordova pause and resume events
      document.addEventListener('pause', this.onAppPause.bind(this), false);
      document.addEventListener('resume', this.onAppResume.bind(this), false);
    }).catch(error => {
      console.error('Error while waiting for device ready:', error);
       mixpanel.track("Error while waiting for device ready",{error: error});
});
  }

  // private onAppPause() {
  //   // Record the timestamp when the app is paused
  //   console.log("called mixpanel pause event")
  //   this.appPausedAt = Date.now();
  //   mixpanel.track('App paused', {
  //     timestamp: new Date(this.appPausedAt).toISOString(),
  //   });
  // }
  onAppPause() {
    // Record the timestamp when the app is paused
    this.appPausedAt = Date.now();
    localStorage.setItem('appPausedAt', this.appPausedAt.toString());

    // Calculate the time spent in the app before it was paused
    const timeSpent = this.appPausedAt - this.appStartedAt;

    // Convert time spent from milliseconds to seconds
    const timeSpentInSeconds = Math.round(timeSpent / 1000);

    // Track the pause event with time spent
    mixpanel.track('App paused', {
      timestamp: new Date(this.appPausedAt).toISOString(),
      timeSpentInSeconds: timeSpentInSeconds
    });
  }
  private onAppResume() {
        console.log("called mixpanel resume event");

    if (this.appPausedAt) {
      // Calculate the time spent in the app
      const now = Date.now();
      const timeSpent = now - this.appPausedAt;

      // Convert time spent from milliseconds to seconds
      const timeSpentSeconds = Math.round(timeSpent / 1000);

      // Track event when the app is brought to the foreground
      mixpanel.track('App resumed from background', {
        timestamp: new Date(now).toISOString(),
        timeSpentInSeconds: timeSpentSeconds,
      });

      // Reset appPausedAt
      this.appPausedAt = null;
    } else {
      // Track event when the app is brought to the foreground without a pause
      mixpanel.track("App resumed from background", {
        timestamp: new Date().toISOString(),
      });
    }
  }
  ngOnInit() {
    this.platform.ready().then(() => {
      this.screenOrientation.onChange().subscribe(() => {
        //  this.hideOpenedModals();
        // this.lockOrientation();
        const isPortrait = this.screenOrientation.type.includes("portrait");
        // if (isPortrait) {
        //   this.sessionService.checkMobile();
        // }
        /// 
        if (/iPad/.test(navigator.userAgent) && window.innerWidth <= 768 && isPortrait) {
          this.isMobile = true
          this.appState.set('isMobile', true);
        }
      });
      }).catch(error => {
      console.error('Error while waiting for device ready:', error);
       mixpanel.track("Error while waiting for device ready in ngOnInit",{error: error});
});;
    this.client = this.appState.get('currentClient');
    this.path = this.appState.get("currentPath");
    // console.log('this.path :', this.path);
    // if (!localStorage.getItem("token")) {
    //   this.router.navigate(["/login"]);
    // }
    this.platform.ready().then(() => {
      // this.sessionService.updateUserTimeZone(this.currentUser);
      if(this.platform.is('mobileweb') && this.platform.is('android')) {
         this.appState.set('MobileWeb', true)
      } else {
         this.appState.set('MobileWeb', false)
      }
      if(this.platform.is('mobileweb') && this.platform.is('ios')) {
         this.appState.set('MobileWebIos', true)
      } else {
         this.appState.set('MobileWebIos', false)
      } 
      this.isTablet = this.platform.is('mobileweb') && (this.platform.is('ipad') || this.platform.is('tablet'));
      this.appState.set('isTabletDevice', this.isTablet);
    if (this.isTablet) {
      //this.lockOrientation();
    } 
      if (_window().navigator.splashscreen) {
        setTimeout(function () {
          _window().navigator.splashscreen.hide();
        },3000);
      }
    // this.deepLinkRoute();
      //this.exxonMobileWebbrowserToAppredirect()
      this.showStatusBar();
    })

    this.analyticsService.initFirebaseAnalytics();
    this.analyticsService.setCrashlyticsEnabled();
  }

  deepLinkRoute() {
    let route =Constants.deepLinkRouter
    if (this.platform.is('cordova')) {
       this.deeplinks
         .route({
           [route]: MyCommitmentsComponent,
         })
         .subscribe(
           (match) => {
             this.ngZone.run(() => {
               // this.router.navigate(["/main"]); // path would be like /auth/password/reset/123xyz
               setTimeout(() => {
                 this.homeUIService.openNextComponent(MyCommitmentsComponent);
               }, 650);
             });
           },
           (nomatch) => {
             console.log("link not matched");
           }
         );
    }
    else if(window.location.pathname =='/main/commitments') {
      this.ngZone.run(() => {
       this.router.navigate(["/main"]); // path would be like /auth/password/reset/123xyz
        setTimeout(() => {
          this.homeUIService.openNextComponent(MyCommitmentsComponent,'',this.isMobile?'':'desktop-todo')
        }, 550);
      });
    }
  }
  exxonMobileWebbrowserToAppredirect() {
    this.platform.ready().then(() => {
      this.ngZone.run(() => {
        const callbackURL = localStorage.getItem(environment.EXXON_CODE);
        console.log("called redirect url", callbackURL);
        if (callbackURL != null || callbackURL != undefined) {
          // Process the callback URL if needed
          this.router.navigate(["/main"]);
        }
      });
    });
  }

  ngDoCheck() {
    this.showLoader = this.appState.get('showLoader')
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(ev) {
   this.beforeAppClose();
   return null;
  }
  str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
  async saveEditPdf() {
   this.singleDocument = JSON.parse(localStorage.getItem('singleDocument'));
   this.user = localStorage.getItem('userid');
    const name = this.singleDocument?.link.substr(this.singleDocument?.link.lastIndexOf("/") + 1);
      const fileName = name?.split("?")[0];
     const data =localStorage.getItem('saveAnnotationData');
     let uint8arr = this.str2ab(data);
      const i =uint8arr, 
         n = new Blob([i],{
                            type: "application/pdf"
                        });
                        const url = window.URL.createObjectURL(n);
                        const myFormData = new FormData()
                       
                        myFormData.append('file',n, fileName);
                        
                      this.libraryService.postAnnotationDoc(myFormData).subscribe((res)=>{
                        let editData ={
                          "user_id":this.user,
                          "document_id" :this.singleDocument?.id,
                          'edited_document_url' : res?.data?.s3_url,
                          "is_reset": false
                        }
                        console.log(editData);
                        this.libraryService.editAnnotationDoc(editData).subscribe((res)=>{
                          console.log("result link", res, n);
                          this.spinnerService.off();
                          this.appState.set('isLibraryRefresh',true);
                          this.toasterService.success(this.i18n.t("Saved"),'top','success-toaster-class-doc');
                          //window.history.back()
               
                         }, error=>{
                          this.spinnerService.off();
                         // this.toasterService.success(this.i18n.t(error))
                        })
                       
                      }
                      , error=>{
                        this.spinnerService.off();
                        //this.toastrService.success(this.i18n.t(error))
                      })
                       
                        
                        
                        
  }
  alertControllerCreate() {
    this.alertController
      .create({
        //header: this.alert_exit_title,
        message: this.i18n.t("Do you want to save annotations in this document?"),
        cssClass:'alertLogCss',
        backdropDismiss: false,
        buttons: [
          {
            text: this.i18n.t("No"),
            role: 'cancel',
            handler: () => {
              localStorage.setItem("previousAnotations", undefined);
            }
          },
          {
            text: this.i18n.t("Yes"),
            handler: () => {
              // eslint-disable-next-line @typescript-eslint/dot-notation
              localStorage.setItem("previousAnotations", undefined);
              this.spinnerService.on();
              this.saveEditPdf();

            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }
 
  beforeAppClose() {
    let currentScreenIndex =this.appState.get('currentScreenIndexAfterScreenView')
    let scheduleDay =this.appState.get('currentDayAfterScreenView')
    let currentDay = this.appState.get('currentScheduleDay')
    let dailyStepsArray= this.appState.get("DailyStepsData");
    let screensIds= this.appState.get('screensIdsAfterScreenView')
   let allowScreenCount = this.appState.get('allowScreenCount');
    if (allowScreenCount && currentDay ==scheduleDay && dailyStepsArray[scheduleDay-1]?.stepStatus!='Review') {
      this.utilService.setScreenData(currentScreenIndex, scheduleDay, screensIds)
      this.utilService.setScreenCount()
    }
    if (this.appState.get('routine_schedule_day')) {
      this.appState.set('routine_schedule_day', null)
    }
    this.sessionService.removeAppStateOnContentClose()
  }
  loadprocessedCommitmentNotifications() {
    const storedData = localStorage.getItem('processedCommitmentNotifications');
    if (storedData) {
      this.processedCommitmentNotifications = new Set(JSON.parse(storedData));
    }
  }
  loadprocessedCommunityNotifications() {
    const storedData = localStorage.getItem('processedCommunityNotifications');
    if (storedData) {
      this.processedCommunityNotifications = new Set(JSON.parse(storedData));
    }
  }
  getToken() {
    if (this.platform.is('ios') || this.platform.is('android')) {
      console.log("device token entry point");
       mixpanel.track("Event is getting called before device token function is called",{ timestamp: new Date().toISOString()})
      this.platform.ready().then(() => {

        this.firebaseX.hasPermission().then((res) => {
          if (res === false) {
            this.firebaseX
              .getToken()
              .then((token) => {
                localStorage.setItem("deviceToken", token);
                console.log(`The token is ${token}`);
              })
              .catch((error) => {
                console.error("Error getting token", error)
                 mixpanel.track("error in getting called if firebase token has no permission",{error:error, timestamp: new Date().toISOString()})
              });

          } else {
            let IsNotificationIdAvailableCount=0;
            this.firebaseX
              .onMessageReceived()
              .subscribe((data) => {
                let postData = JSON.parse(data?.keysandvalues)
                console.log('data outside platform.resume() app component:', data);
                let notificationId = data['gcm.message_id']?parseInt(data['gcm.message_id']):parseInt(data['google.message_id']?.split(':')[1]?.split('%')[0])
                console.log('Starting notification ID (app component):', notificationId);
                const requestData = {
                  mode: postData?.mode,
                  postId: postData?.post_id,
                  id: notificationId,
                  route: postData?.route,
                  IsNotificationIdAvailableCount: IsNotificationIdAvailableCount
                }
                this.appState.set('AfterAppInit', requestData)
                // "AfterAppInit" used for when app is not in BG

                // platform.resume() is used for when app is in BG
                this.platform.resume.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                  IsNotificationIdAvailableCount++;
                  requestData.IsNotificationIdAvailableCount =IsNotificationIdAvailableCount
                  this.loadprocessedCommitmentNotifications();
                  this.loadprocessedCommunityNotifications();
                  if (IsNotificationIdAvailableCount == 1) {
                    if (this.processedCommitmentNotifications && this.processedCommitmentNotifications?.size > 0) {
                      let lastCommitmentNotificationId = Array.from(this.processedCommitmentNotifications).slice(-1)
                      this.processedCommitmentNotifications = new Set(lastCommitmentNotificationId);
                      localStorage.setItem('processedCommitmentNotifications',JSON.stringify(lastCommitmentNotificationId));
                    }
                    if (this.processedCommunityNotifications && this.processedCommunityNotifications?.size > 0) {
                      let lastCommunityNotificationId= Array.from(this.processedCommunityNotifications).slice(-1);
                      this.processedCommunityNotifications = new Set(lastCommunityNotificationId)
                      localStorage.setItem('processedCommunityNotifications',JSON.stringify(lastCommunityNotificationId))
                    }
                  }
                    
                  console.log('data INSIDE platform.resume() app component:', data,postData,IsNotificationIdAvailableCount,requestData);
                  console.log('Starting notification ID inside (app component):', notificationId);

                  let communitySavedId = parseInt(localStorage.getItem('CommunityNotificationId'))
                  console.log('communitySavedId (app component):', communitySavedId);

                  let commitmentsSavedId = parseInt(localStorage.getItem('CommitmentsNotificationId'))
                  console.log('commitmentsSavedId (app component):', commitmentsSavedId,data['tap']);

                  if (postData.route.match('/main/commitments') && notificationId && !this.isCommitmentNotificationProcessed(notificationId) && !this.processedCommunityNotifications?.has(notificationId)) {
                    this.processedCommitmentNotifications.add(notificationId);
                    if (data['tap'] == 'foreground') {
                      localStorage.setItem( 'processedCommitmentNotifications',JSON.stringify(Array.from(new Set(this.processedCommitmentNotifications))));
                    }
                    else if (data['notification_foreground'] === 'true' && !data['tap']) {
                      console.log('Forground notification received inside app component:', data.route);
                      localStorage.setItem('CommitmentsNotificationId', notificationId.toString())
                    } else {
                      console.log('Background Notification received inside app component:');
                      localStorage.setItem('CommitmentsNotificationId', notificationId.toString())
                      if (this.homeUIService.getModalStack()?.length > 0) {
                        if (this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.component?.name != 'MyCommitmentsComponent' && this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.name!= 'MyCommitmentsComponent') {
                          console.log("stayed on same commitment screen. no redirection needed")
                          this.homeUIService.setModalStack(MyCommitmentsComponent)
                          this.homeUIService.openNextComponent(MyCommitmentsComponent);
                        }
                      }
                      else {
                        this.homeUIService.setModalStack(MyCommitmentsComponent)
                        this.homeUIService.openNextComponent(MyCommitmentsComponent);
                      }
                     // this.router.navigate(['/main/commitments']);
                    }
                    localStorage.setItem( 'processedCommitmentNotifications',JSON.stringify(Array.from(new Set(this.processedCommitmentNotifications))));
                  }

                  if (postData.route.match('/main/questions') && notificationId && !this.isCommitmentNotificationProcessed(notificationId) && !this.processedCommunityNotifications?.has(notificationId)) {
                    if (data['tap'] == 'foreground') {
                      this.processedCommunityNotifications.add(notificationId);
                      localStorage.setItem('processedCommunityNotifications',JSON.stringify(Array.from(new Set(this.processedCommunityNotifications))));
                    }
                    else if (data['notification_foreground'] === 'true' && !data['tap']) {
                      console.log('Forground notification received inside app component:', postData.route);
                      this.processedCommunityNotifications.add(notificationId);
                      localStorage.setItem('CommunityNotificationId', notificationId.toString())
                    } else {
                      console.log('Background Notification received inside app component:', data.route);
                      this.appState.set('communityNotification', true)
                      this.appState.set('notificationData', requestData)
                      this.processedCommunityNotifications.add(notificationId);
                      if (this.router.url === '/main/questions' || this.homeUIService.getModalStack()?.length > 0) {
                        if (this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.component?.name == 'MobileChatBoardComponent' || this.homeUIService.getModalStack()[this.homeUIService.getModalStack()?.length - 1]?.name== 'MobileChatBoardComponent' ) {
                          console.log('this.router.url :', this.router.url,);
                          this.appState.set('isBackground', true)
                        }
                        else {
                          this.homeUIService.setModalStack(MobileChatBoardComponent)
                          this.homeUIService.openNextComponent(MobileChatBoardComponent);
                        }
                      } else {
                        console.log('this.router.url :', this.router.url,);
                        // this.router.navigate(['/main/questions']);
                          this.homeUIService.setModalStack(MobileChatBoardComponent)
                          this.homeUIService.openNextComponent(MobileChatBoardComponent);
                      }
                    }
                    localStorage.setItem('processedCommunityNotifications',JSON.stringify(Array.from(new Set(this.processedCommunityNotifications))));
                  }
                })

                this.sessionService.removeNotificationBadge()

              }, (error) => {
                console.log('error in app:', error);
                mixpanel.track("error in getting firebase token on message receive",{error:error, timestamp: new Date().toISOString()})
              });
          }
        })

        
        

        this.firebaseX.onTokenRefresh().subscribe((token: string) => {
          localStorage.setItem("deviceToken", token);
          if (!this.pathRegistration.pathData.deviceRegistrationId) {
            this.saveToken(this.pathRegistration);
          }
          console.log("New device token : ", token);
        });
      }).catch(error => {
        console.log("platform  error : ", error);
        
       mixpanel.track("Error while waiting for device ready in getToken",{error: error});

      });

    }
  }
  isCommitmentNotificationProcessed(id: number): boolean {
    console.log("this.processedCommitmentNotifications?.has(id)",this.processedCommitmentNotifications?.has(id),id)
    return this.processedCommitmentNotifications?.has(id);
  }

  saveToken(data) {
     let deviceId;
    if (this.isMobile && (localStorage.getItem('deviceToken') !== null || localStorage.getItem('deviceToken') != undefined)) {
      deviceId =localStorage.getItem('deviceToken')
    }
    else {
      deviceId = data.pathRegistration.pathData.deviceRegistrationId;
    }
    const pathRegistration = new PathRegistration({
      userId: this.currentUser.id,
      pathId: this.path.id,
      pathData: {
        loginReminderEnabled: data.pathData.loginReminderEnabled,
        loginReminderTime: data.pathData.loginReminderTime,
        deviceRegistrationId: deviceId,
        emailReminderEnabled: data.pathData.emailReminderEnabled,
        pushReminderEnabled: data.pathData.pushReminderEnabled,
        socialReminderEnabled: data.pathData.socialReminderEnabled,
        socialEmailEnabled: data.pathData.socialEmailEnabled,
      },
    });
    this.userService.savePathRegistration(pathRegistration).subscribe(
      (response) => {
        this.updatePathRegistration = response.data
        this.appState.set("pathRegistration", this.updatePathRegistration.updatePathRegistration);
        // console.log('response from session:', response);
      },
      (error) => {
        console.log("Server error", error);
      });
  }
  getAppInfo(){
    
    this.platform.ready().then(() => {
      let appVersion: string;

      if(this.platform.is('android') || this.platform.is('ios')){    
        this.appVersion.getVersionCode().then((response) => {
          appVersion = response?.toString()
          console.log('appVersion', appVersion);
          if (appVersion?.includes(currentAppVersion)) {
            console.log('version is matching', appVersion);
          } else {
            console.log('version is not matching', appVersion);
            let isAppUpdated = localStorage.getItem('isAppUpdated')
            console.log('isAppUpdated :', isAppUpdated);
            if(!isAppUpdated || isAppUpdated !== 'true' ){
              console.log('isAppUpdated :', isAppUpdated);
              this.presentAlert()
            }
          }
  
        });
      }

    })

  }

  ngDestroy() {
    this.appState.set("isCommitmentsViewed", false)
  }

  async presentAlert() {
    let notificationText = this.notificationText
    console.log('this.notificationText :', this.notificationText);
    if (!this.notificationText) {
      notificationText = 'You are using and old version of this app. Please upgrade to the latest version.'
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-alert',
      header: 'Update app',
      message: notificationText,
      backdropDismiss: false,
      buttons: [{
        text: 'OK',
        role: 'confirm',
        handler: () => { 
          if(this.platform.is('ios')){
            this.market.open('1498776896');
          }else if(this.platform.is('android')){
            this.market.open('com.first90.new_manager');
          }
        }
      }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    if (role) {
      localStorage.setItem('isAppUpdated', 'true')
    }
  }
  
  showStatusBar() {
    this.statusBar.show()
    this.statusBar.overlaysWebView(true);
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    try {
      if (this.analyticsService) {
        let localName = e.target.localName;
        if (localName.includes("img") || localName.includes("ion-icon") || localName.includes("button") || localName.includes("a") || localName.includes("u")) {
          this.analyticsService.setClickEvent(e.target?.innerText, e.target?.localName, e.view.location?.pathname, this.path?.id)
        }
      }
    } catch (error) {
      console.log('analytics error', error.message);
      
    }
    
  }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isTablet = this.platform.is('mobileweb') && (this.platform.is('ipad') || this.platform.is('tablet'));
      this.appState.set('isTabletDevice', this.isTablet);
      if (this.isTablet) {
        // this.sessionService.checkMobile();
        //  this.hideOpenedModals();
        // this.lockOrientation();
      }
  }
  lockOrientation() {
      // this.hideOpenedModals();
      // this.screenOrientation.lock(
      //   this.screenOrientation.ORIENTATIONS.LANDSCAPE
      // );

      this.screenOrientation
        .lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE).then((res) => {
        })
        .catch((err) => {
          console.error("Failed to lock orientation: ", err);
          this.lockOrientation()
        });
  }

  getNotificationData(){
    this.sessionService.getNotificationData().subscribe((response) => {
      console.log('response :', response.data[0]);
      this.notificationText = response.data[0].notification_text
      this.isNotifty = response.data[0].update_app_notification

      console.log('this.isNotifty :', this.isNotifty);
      if (this.isNotifty) {
        console.log('condition satisfied');
        this.getAppInfo()
      }

    }, (err)=> {
      console.log('err :', err);
    })
  }

  ngOnDestroy() {
    console.log("entered destroy phase")
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  
}
