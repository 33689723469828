import { AfterViewInit, Component, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent, Platform } from '@ionic/angular';
import { AppState } from 'src/app/app.service';
import { I18n } from 'src/app/i18n.service';
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model';
import { Path } from 'src/app/path/path.model';
import * as _ from 'underscore';
import { HomeUiService } from '../home-ui.service';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/user/user.model';
import { ToasterService } from 'src/app/toaster.service';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { HttpClient } from '@angular/common/http';
import Swiper from 'swiper';
import { DailyStepsContentModalComponent } from '../daily-steps-content-modal/daily-steps-content-modal.component';
import { AnimationsService } from 'src/app/shared/animations.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MyCommitmentsComponent } from 'src/app/my-commitments/my-commitments.component';
import { AllPathsComponent } from 'src/app/all-paths/all-paths.component';
import { AlertUnlockLevelComponent } from 'src/app/alert-unlock-level/alert-unlock-level.component';
import { SessionService } from 'src/app/session/session.service';

@Component({
  selector: 'app-your-path',
  templateUrl: './your-path.component.html',
  styleUrls: ['./your-path.component.scss']
})
export class YourPathComponent implements OnInit, AfterViewInit,DoCheck,OnDestroy {

  isMobile: boolean
  inoutScreens: InoutScreen[]
  path: Path
  currentDay: number
  @ViewChildren("stepTitle") stepTitle:QueryList<ElementRef>;
  @ViewChildren('focusableElement') focusableElements: QueryList<ElementRef>;
  @ViewChild('focusableCongratulateScreen') focusableCongratulateScreen: ElementRef;
  @ViewChild(IonContent) content: HTMLIonContentElement;
  @ViewChild('contentWrapper') contentWrapper: ElementRef;
  currentUser: User
  pathRegistration: any
  scheduleDay: number
  focusYourPath=false;
  rightSwipe;
  leftSwipe;
  startPlayButton;
  onFocusPlayBtn;
  dailyStepsArray;
  @ViewChild('swiperContainer') swiperContainer: any;
  swiper: Swiper;
  destroyed$ = new Subject<boolean>();
  playSvg;
  closeoutSvg;
  onFocuseCloseOut;
  isBtnFocused =false;
  isMobileWebIos = false;
  isMobileWeb=false;
  startCloseout;
  isScrollbarPresent;
  allPathsCount;
  isTouchDevice
  isModalOpen = false;
  constructor(private appState: AppState,
    private router: Router,
    private el : ElementRef,
    public i18n: I18n,
    public http: HttpClient,
    public platform: Platform,
    private modalController: ModalController,
    private homeUIService: HomeUiService,
    private render:Renderer2,
    private homev5Service: HomeV5Service,
    private animationService: AnimationsService,
    private sessionService: SessionService,
    public toasterService: ToasterService,) {
    this.isMobile = this.appState.get('isMobile')
    this.path = this.appState.get('currentPath')
    this.inoutScreens = this.path.inoutScreens
    this.currentDay = this.appState.get('currentScheduleDay')
    this.currentUser = this.appState.get('currentUser')
    this.scheduleDay = this.appState.get("scheduleDayForCurrentStep")
    this.pathRegistration = this.appState.get('pathRegistration')
    this.dailyStepsArray= this.appState.get("DailyStepsData");
   }
 
  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.allPathsCount =  this.appState.get('allPathCount')
    this.loadSvgFile('../../../assets/web_home_ui/white_play_btn.svg');
    this.loadSvgFile('../../../assets/web_home_ui/black_play_btn.svg');
    this.loadSvgFile('../../../assets/web_home_ui/black_circular-to-do.svg');
    this.loadSvgFile('../../../assets/web_home_ui/white_circular_to-do.svg');
    // this.initSwipers();
   this.getStepArrayInfo();
   this.homeUIService.refreshStepApiData.pipe(takeUntil(this.destroyed$)).subscribe( (data) => {
    if(data){
      this.dailyStepsArray = data;
      setTimeout(async ()=>{
        await this.swipeToActiveSlide(1000); 
      },500);
       // this.getStepArrayInfo();      
    }
  
  });
  }
  async getStepArrayInfo(){
    let stepBegin= false;
    this.dailyStepsArray?.forEach((step)=>{
     if(step?.stepStatus =='Review' && step?.step < this.dailyStepsArray?.length )
     {
       this.appState.set("currentScheduleDay",step?.step+1)
       this.appState.set('scheduleDayForCurrentStep',  step?.step+1);
       stepBegin =true;
     }
     else if(step?.stepStatus=='Resume') {
       this.appState.set("currentScheduleDay",step?.step)
       this.appState.set('scheduleDayForCurrentStep',  step?.step);
       stepBegin =true
     }
     else if(!step?.stepStatus && !stepBegin) {
       this.appState.set("currentScheduleDay",step?.step)
       this.appState.set('scheduleDayForCurrentStep',  step?.step);
       stepBegin =true
     }
   })
   setTimeout(async ()=>{
     await this.swipeToActiveSlide(1000); 
    },500);
  }

  initSwipers(): void {
   this.isTouchDevice = this.sessionService.isTabletDevice();
    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      slidesPerView: 'auto', 
     // spaceBetween: 5,
      breakpoints: {
        1200: {
          slidesPerView: 'auto',
        },
        992: {
          slidesPerView: 'auto',
          
        }
      },
      navigation: {
        prevEl: '.path-swiper-custom-prev',
        nextEl: '.path-swiper-custom-next',
      },
      allowTouchMove:this.isTouchDevice,   
       on: {
      init: () => {
        // Call swipeToActiveSlide after the transition to a new slide has completed
           setTimeout(() => {
              this.swipeToActiveSlide(1900);
           },1000)
         }
    },

    });
  }
  isAllItemsVisible() {
    const slidesPerView = +this.swiper.params.slidesPerView;
    const totalSlides = this.swiper.slides.length;
    const activeIndex = this.swiper.activeIndex;
    return activeIndex + slidesPerView >= totalSlides;
  }
  ngDoCheck(): void {
    // this.dailyStepsArray= this.appState.get("DailyStepsData");
    this.allPathsCount =  this.appState.get('allPathCount')
    this.scheduleDay = this.appState.get("scheduleDayForCurrentStep")
    this.currentDay = this.appState.get('currentScheduleDay')
    this.path = this.appState.get('currentPath');
  }

  swipeToActiveSlide(time) {
    let focusableCardIndex = this.currentDay;
    let firstCardIndex = this.focusableElements.toArray()[0];
    let focusableElement = this.focusableElements.toArray()[focusableCardIndex - 2];  
  if(this.isMobile) {
    setTimeout(() => {
      if (focusableElement && focusableElement.nativeElement) {
        const elementRect = focusableElement.nativeElement.getBoundingClientRect();
        const offsetTop = elementRect.top;
        this.content.scrollToPoint(0, offsetTop, 1000);
      }
    }, 300);
  }
  else {
    if(this.scheduleDay == this.dailyStepsArray?.length && !this.isMobile){
      focusableCardIndex = this.scheduleDay+1
      focusableElement = this.focusableCongratulateScreen;  
    }
    else  if(this.scheduleDay) {
      focusableCardIndex = this.scheduleDay
      focusableElement = this.focusableElements.toArray()[focusableCardIndex - 2]
    }
    setTimeout(() => {
      if (focusableElement && focusableElement.nativeElement) {
              // const elementRect = focusableElement.nativeElement.getBoundingClientRect();
              // const offsetLeft = elementRect.left;
              // this.swiper.update();
              this.swiper.slideTo(focusableCardIndex-2);
              // this.swiper.updateSlidesClasses()
              // this.swiper.updateSize();
            }
    
    }, time);
  }
  }
  ngAfterViewInit() {
    this.initSwipers();
    this.homeUIService.expandOnEnterAnimation()
    setTimeout(() => {
      const content = this.contentWrapper.nativeElement;
      this.isScrollbarPresent = content.scrollHeight > this.content['el'].scrollHeight;
      this.checkScrollbar();
    }, 700);
    //this.swipeToActiveSlide(2000);
  }
  
  closePage() {
    this.homeUIService.getLastModal();
    this.modalController.dismiss();
  }
  changeSubTextFont(index) {
   if(this.stepTitle) {
    const element = this.stepTitle.toArray()[index]?.nativeElement;
    const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
    if(specificElement) {
      this.render.setStyle(specificElement, 'font-size', '20px');
      this.render.setStyle(specificElement, 'font-weight', '500');
      this.render.setStyle(specificElement, 'font-style', 'normal');
      this.render.setStyle(specificElement, 'line-height', '21px');
      this.render.setStyle(specificElement, 'font-family', 'Roboto');
     this.render.setStyle(specificElement, 'word-break', 'break-word');
     this.render.setStyle(specificElement, 'overflow', 'hidden');
     this.render.setStyle(specificElement, ' text-overflow', 'ellipsis');
     this.render.setStyle(specificElement, 'display', '-webkit-box');
     this.render.setStyle(specificElement, '-webkit-line-clamp', '2');
     this.render.setStyle(specificElement, 'margin-bottom', '8px');
     this.render.setStyle(specificElement, '-webkit-box-orient', 'vertical');

    }
  return true;
   }
   
  }

  goToStep(inout) {
    if (this.currentDay >= inout.startDay) {
      this.homeUIService.dismissAllModals().then(() => {
        if(this.currentDay !== inout.startDay){
          const pathProgress = localStorage.getItem(
            `PathProgress:User:${this.currentUser.id}:DayProgress:${this.path.id}:${inout.startDay}`
          )
          this.appState.set('screenId',pathProgress)
        }
        this.appState.set('isYourPathPrevious', true);
        this.router.navigate(['/content', inout.startDay]);
      });
    }
  }

  getResumeStatus(pathRegistration, currentStep) {
    let isResume = false
    let currentEle;
    if (pathRegistration?.screensViewed) {
      let screensViewed = this.homev5Service.removeDuplicates(pathRegistration.screensViewed, "step");
      console.log('screensViewed :', screensViewed);
      screensViewed.forEach(element => {
        if (element.step === currentStep && element.visitedScreens < element.totalScreens) {
          isResume = true
          currentEle = element
        }
      });
    }
    console.log('resume currentEle :', currentEle);
    return isResume
  }
  openToDoListModal(btn) {
    if (this.sessionService.isTabletDevice() && btn=='to-do') {
      this.focusPlayBtn();
      setTimeout(() => {
        this.buttonOnBlur();
      }, 1000);
    }
    this.homeUIService.openNextComponent(MyCommitmentsComponent,'','desktop-todo')
  }
  openAllPathsComponent() {
    this.appState.set("isAllPaths", true);
    this.homeUIService.openNextComponent(AllPathsComponent,'','desktop-allpath')
  }
  getReviewStatus(pathRegistration, currentStep) {
    let isReview = false
    let currentEle;
    let screensViewed = this.appState.get('screensViewed')
    if (screensViewed) {
     let  screens = this.appState.get('screensViewed')
      let screensViewed = this.homev5Service.removeDuplicates(screens, "step");
      screensViewed.forEach(element => {
        if (element.step === currentStep && element.visitedScreens === element.totalScreens) {
          isReview = true
          currentEle = element
        }
      });
    
    } else {
      let screensViewed = this.homev5Service.removeDuplicates(pathRegistration.screensViewed, "step");
      screensViewed.forEach(element => {
        if (element.step === currentStep && element.visitedScreens === element.totalScreens) {
          isReview = true
          currentEle = element
        }
      });
  }
    console.log('review currentEle :', currentEle);
    return isReview
  }
  async openDailyStepsModal(comp?, compProps?,className?){
    if(this.homeUIService?.isDark()){
      const cust_class = className?className:'custom-modal'
      className = 'web-daily-steps-custom-modal dark-theme'
      }else{
        className = className?className:'custom-modal'
      }
      console.log("className", className);
    if(comp) {
      const modal = await this.modalController.create({
        component: comp,
        componentProps: compProps,
        // animated: false,
        cssClass: className,
        enterAnimation: this.animationService.slideInUpEnterAnimation,
        leaveAnimation: this.animationService.slideOutDownLeaveAnimation
       
      });
       await modal.present() 
      modal.onWillDismiss().then((data) => {
        if(data?.data?.isOpenNextStep) {
          modal.animated =false
         }
         if(data?.data?.isInstepTodoScreen) {
          this.homeUIService.unlockNextHabitLevel();
         }
         if (data?.data?.nextHabitUnlockFromToDoScreen == 'nextHabitUnlockFromToDoScreen') {
           setTimeout(() => {
             this.openDailyStepsModal(DailyStepsContentModalComponent, { currentDay: data?.data?.currentDay, startFromScreen: 'daily-content-screen-todo' }, 'web-daily-steps-custom-modal');
           }, 600);
         }
         if (data?.data?.isAlertUnlockClosed) {
            this.openDailyStepsModal(DailyStepsContentModalComponent,{currentDay: data?.data?.currentDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
         }
        this.swipeToActiveSlide(600); 
        if(this.dailyStepsArray[this.currentDay-1]?.stepStatus!=='Review'){
          this.getStepArrayInfo();
        }
        
       });
      modal.onDidDismiss().then(() => {
         this.isModalOpen = false;
      })
     
    }
   
   }
  //  unlockNextHabitLevel() {
  //   let levelUpdateData = this.appState.get("levelUpdateData");
  //   let allLevelsCompleted = this.appState.get("allLevelsCompleted");
  //   if (levelUpdateData?.levelUpdated === true) {
  //     this.appState.set("LevelNumber", levelUpdateData?.level.level + 1);
  //     const last =
  //       levelUpdateData?.routine.routine[
  //         levelUpdateData?.routine.routine.length - 1
  //       ];
  //     var isLastRepLogged = levelUpdateData?.level.reps_commit.filter(
  //       (item) => item.is_completed === true
  //     );

  //     if (
  //       levelUpdateData &&
  //       levelUpdateData.level.reps_commit.length ==
  //         isLastRepLogged.length + 1 &&
  //       last.id === levelUpdateData.level.id
  //     ) {
  //       if (allLevelsCompleted === true) {
  //         console.log("do nothing :");
  //       } else {
  //         setTimeout(()=>{
  //           this.homeUIService.checkLastRep(levelUpdateData.routine);
  //         },600)
  //       }
  //     } else {
  //       this.appState.set("levelUpdated", true);
  //       setTimeout(()=>{
  //         this.openDailyStepsModal(AlertUnlockLevelComponent,{nextLevelData:levelUpdateData.routine.schedule_day},'web-daily-steps-custom-modal')
  //       },600);
        
  //       this.appState.set("levelUpdateData", undefined);
  //     }
  //   }

  //   let levelSaveData = this.appState.get("levelSaveData");
  //   if (levelSaveData && this.dailyStepsArray[this.currentDay-1]?.stepStatus=='Review') {
  //     localStorage.setItem(
  //       `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
  //       "true"
  //     );
  //     this.appState.set("showUpdatedScreens", undefined);
  //     this.appState.set("levelSaveData", undefined);
  //   }
  //  }

  openDailyStepContent(stepData,btn?) {
    if (this.sessionService.isTabletDevice() && btn == "playBtn") {
      this.focusPlayBtn();
      setTimeout(() => {
        this.buttonOnBlur();
      }, 1000);
    }
    if (this.isModalOpen) {
      return
    }
    this.isModalOpen = true;
    this.appState.set("allowScreenCount", true);
    this.appState.set("currentScheduleDay",stepData?.step)
    if(stepData.stepStatus =="Review"){
      this.replay(stepData?.step)
    }
    else if(stepData.stepStatus=="Resume"){
      this.openDailyStepsModal(DailyStepsContentModalComponent,{currentDay: stepData?.step},'web-daily-steps-custom-modal')
      // this.router.navigate(['/content', stepData?.step])
    }else{
      var i = stepData?.step - 1;
    
      if (i == 0) {
        if(!this.isMobile) {
          // this.router.navigate(['/content', stepData?.step])
          localStorage.setItem('isBeginClicked','true')
          this.openDailyStepsModal(DailyStepsContentModalComponent,{currentDay: stepData?.step},'web-daily-steps-custom-modal')

        }
        return;
      }
      // let previousDayCompleted;
       let updateScheduleDay = i + 1;
      // for (let s = stepData?.step - 1; s >= 0; s--) {
      //   let dayHasContent = this.path.checkContentForDay(s);
      //   if (dayHasContent) {
      //     previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
      //       this.pathRegistration,
      //       s,
      //       this.currentUser,
      //       this.path
      //     ) || this.dailyStepsArray[s-1]?.stepStatus =='Review'
      //     break;
      //   }
      // }
      // let showPrev = previousDayCompleted;
      let data = {
        schedule_day: updateScheduleDay,
        user_id: this.currentUser.id,
      };
      // if (previousDayCompleted) {
        this.homev5Service.updateScheduleDay(data).subscribe(
          (response) => {
            if (response.success == true) {
              localStorage.setItem('isBeginClicked','true')
              this.appState.set("allowScreenCount", true);
              this.appState.set("LevelNumber", 1);
              this.appState.set("maxAllowedScheduleDay", updateScheduleDay);
              this.appState.set("currentScheduleDay", updateScheduleDay);
              this.appState.set("scheduleDayForCurrentStep", updateScheduleDay);
              this.appState.set("indexOfLevel", 0);
              this.appState.set("levelUpdated", undefined);
              this.currentDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
              this.scheduleDay = this.appState.get("scheduleDayForCurrentStep");
              localStorage.removeItem(
                `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
                  !(i == undefined) ? i + 1 : stepData?.step
                }`
              );
              // this.router.navigate([
              //   "/content",
              //   !(i == undefined) ? i + 1 : stepData?.step,
              // ]);
              this.openDailyStepsModal(DailyStepsContentModalComponent,{currentDay:  !(i == undefined) ? i + 1 : stepData?.step},'web-daily-steps-custom-modal')

              return false;
            } else {
              this.toasterService.warning(this.i18n.t(
                "Server or connection issue. Please check your internet connection."
              ));
            }
          },
          (error) => {
            this.toasterService.warning(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
            console.log("Response of update  error", error);
          }
        );
      // } else {
      //   this.toasterService.warning(this.i18n.t("Please complete previous step"));
      // }
    }

  }

  unlockReplay(stepData) {
    let index= stepData?.step;
    this.appState.set("currentScheduleDay", index);
    this.appState.set("allowScreenCount", true);
    // let isResume=this.getResumeStatus(this.pathRegistration,index)
    // let isReview=this.getReviewStatus(this.pathRegistration,index)
    if(stepData?.stepStatus =='Review'){
      this.replay(stepData?.step)
    }
    else if(stepData?.stepStatus =='Resume'){
      this.router.navigate(['/content', index])
    }else{
      var i = index - 1;
      if (i == 0) {
        localStorage.setItem('isBeginClicked','true')
        this.router.navigate(['/content', index])
        return;
      }
      let previousDayCompleted;
      let updateScheduleDay = i + 1;
      for (let s = index - 1; s >= 0; s--) {
        let dayHasContent = this.path.checkContentForDay(s);
        if (dayHasContent) {
          previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
            this.pathRegistration,
            s,
            this.currentUser,
            this.path
          )
          break;
        }
      }
      // let showPrev = previousDayCompleted;
      // console.log('previousDayCompleted :', previousDayCompleted);
      let data = {
        schedule_day: updateScheduleDay,
        user_id: this.currentUser.id,
      };
       if (previousDayCompleted || index<=this.scheduleDay) {
        this.homev5Service.updateScheduleDay(data).subscribe(
          (response) => {
            if (response.success == true) {
              localStorage.setItem('isBeginClicked','true')
              this.appState.set("allowScreenCount", true);
              this.appState.set("LevelNumber", 1);
              this.appState.set("maxAllowedScheduleDay", updateScheduleDay);
              this.appState.set("currentScheduleDay", updateScheduleDay);
              this.appState.set("scheduleDayForCurrentStep", updateScheduleDay);
              this.appState.set("indexOfLevel", 0);
              this.appState.set("levelUpdated", undefined);
              this.currentDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
              this.scheduleDay = this.appState.get("scheduleDayForCurrentStep");
              localStorage.removeItem(
                `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
                  !(i == undefined) ? i + 1 : this.scheduleDay
                }`
              );
              this.router.navigate([
                "/content",
                !(i == undefined) ? i + 1 : this.scheduleDay,
              ]);
              // this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent)

              return false;
            } else {
              this.toasterService.warning(this.i18n.t(
                "Server or connection issue. Please check your internet connection."
              ));
            }
          },
          (error) => {
            this.toasterService.warning(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
            console.log("Response of update  error", error);
          }
        );
       } 
      else {
        this.toasterService.warning(this.i18n.t("Complete the previous step to unlock this step."));
      }
    }
    
  }

  replay(i) {
    this.appState.set("isNewStepUnlocked", undefined);
    let screensViewed = this.appState.get('screensViewed')
    this.appState.set('allowScreenCount', undefined)
    let stepsArray;
    if (this.pathRegistration?.screensViewed) {
      stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration?.screensViewed,"step");
    }else{
      stepsArray = screensViewed
    }
    stepsArray?.forEach((element) => {
      if (
        element?.step == this.scheduleDay &&
        element?.visitedScreens === element?.totalScreens
      ) {
      }
    });    

    localStorage.removeItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
        i
      }`
    );
    if (this.isMobile) {
       this.isModalOpen = false;
      this.router.navigate([
        "/content",
        !(i == undefined) ? i : this.scheduleDay,
      ]);
    }
   else {
    this.openDailyStepsModal(DailyStepsContentModalComponent,{currentDay:i },'web-daily-steps-custom-modal')
   }
    return false;
  }
  loadSvgFile(path,pos?) {
    this.http.get(path, { responseType: 'text' })
      .subscribe(svgData => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
        const primaryColor = getComputedStyle(document?.documentElement)?.getPropertyValue('--theme-color');
        let pathElements = svgDoc.querySelectorAll('stop');
       pathElements?.forEach(pathElement => {
        const currentColor = pathElement?.getAttribute('stop-color')
        if(pathElement?.getAttribute('stop-color')=='#8077E7'){
          pathElement?.setAttribute('stop-color', primaryColor); // Use the var(--color) notation to set the fill color dynamically         
        }
      
      });   
      const serializer = new XMLSerializer();
      const modifiedSvgData = serializer.serializeToString(svgDoc);
      const encodedData = encodeURIComponent(modifiedSvgData);

      // if(pos=='left'){
      //   this.leftSwipe = `data:image/svg+xml,${encodedData}`;
      // }
      // else {
      //  this.rightSwipe = `data:image/svg+xml,${encodedData}`;
      // }
      if(path =='../../../assets/web_home_ui/white_circular_to-do.svg') {
        this.onFocuseCloseOut =`data:image/svg+xml,${encodedData}`;
      }
      if(path =='../../../assets/web_home_ui/black_circular-to-do.svg') {
        this.closeoutSvg =`data:image/svg+xml,${encodedData}`;
        this.startCloseout = this.closeoutSvg;
      }
      if(path =='../../../assets/web_home_ui/white_play_btn.svg') {
        this.onFocusPlayBtn =`data:image/svg+xml,${encodedData}`;
      } 
      else if(path =='../../../assets/web_home_ui/black_play_btn.svg'){
        this.startPlayButton =`data:image/svg+xml,${encodedData}`;
        this.playSvg =this.startPlayButton;
        // console.log("this.playsvg", this.playSvg);
      }   
      // console.log("this.playsvg", this.startCloseout, this.onFocuseCloseOut);
      });
  }
  focusPlayBtn()
  {
        console.log("entered focus method");
    this.isBtnFocused =true;
    if(this.dailyStepsArray[this.dailyStepsArray?.length-1]?.stepStatus=='Review') {
      this.closeoutSvg = this.onFocuseCloseOut
    }
    else {
      this.playSvg =this.onFocusPlayBtn;
    }
  }
  buttonOnFocus(val) {
    if (!this.sessionService.isTabletDevice()) {
      if (this.dailyStepsArray[this.dailyStepsArray?.length - 1]?.stepStatus == 'Review') {
        if (val == false) {
          this.closeoutSvg = this.startCloseout
        }
        else {
          this.closeoutSvg = this.onFocuseCloseOut
        }
      }
      else {
        if (val == false) {
          this.playSvg = this.startPlayButton
        }
        else {
          this.playSvg =this.onFocusPlayBtn;
        }
      }
    }
  }
  buttonOnBlur() {
    console.log("entered blur method");
    if(this.dailyStepsArray[this.dailyStepsArray?.length-1]?.stepStatus=='Review') {
        this.closeoutSvg = this.startCloseout
    }
    else {
        this.playSvg = this.startPlayButton
     
    }
  }
    
  onLeftSliderEnter() {
    if (this.sessionService.isTabletDevice()) {
      this.focusYourPath = false;
    } else {
      this.focusYourPath = true;
    }
  }

  onLeftSliderLeave() {
    this.focusYourPath =false
  }
  onScroll(event) {
    // Check for scrollbar presence during scrolling
    this.checkScrollbar(event);
  }

  private checkScrollbar(ev?) {
    const content = this.contentWrapper.nativeElement;
    this.isScrollbarPresent = content.scrollHeight > this.content['el'].scrollHeight;
    const button = this.el.nativeElement.querySelector('#fixedButton');
    const divEle = document.getElementsByClassName('home-step-content-wrap');
    // if (divEle && ev) {
    //   divEle[0].setAttribute('style', 'height:auto !important;align-items:center; justify-content:center;display:flex;flex-wrap:wrap;align-content:center;');
    // }
    if ((ev || button) && this.isScrollbarPresent) {
      console.log("called");
      button?.classList?.add('web-start-btn-fixed');
    } else {
       let btn = document.getElementsByClassName("web-start-btn-fixed");
      if (btn && btn?.length>0) {
         divEle[0].setAttribute('style', 'height:auto !important;align-items:center; justify-content:center;display:flex;flex-wrap:wrap;align-content:center;');
      }
      
       //button?.classList?.remove('web-start-btn-fixed');

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isTouchDevice = this.sessionService.isTabletDevice();
    this.checkScrollbar();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
