import { Injectable } from '@angular/core';
import { AppState } from './app.service';

@Injectable()
export class AppConstantsService {

  subRoutineAnswer: string

  constructor(private appState: AppState) 
  { }

  createCommitEvent(routine: any= null,level: any, typeValue: any = null, activityCueTime: any = null, routineTime: any = null, routineDay: any = null, commitType: any = null, makeCommitment: any = null){
    if (commitType === 'dayTime') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " on "+routineDay.toLowerCase()+" at "+routineTime+ ". "
    } else if (commitType === 'Location' && makeCommitment === 'yes') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ typeValue + " on "+routineDay.toLowerCase()+" at "+routineTime+ ". "
    } else if (commitType === 'Person' && makeCommitment === 'yes') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I encounter "+ typeValue + " on "+routineDay.toLowerCase()+" at "+routineTime+ ". "
    } else if (commitType === 'Activity' && makeCommitment === 'yes') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ activityCueTime+ " I " + typeValue + " on "+routineDay.toLowerCase()+" at "+routineTime+ ". "
    } else if (commitType === 'Other' && makeCommitment === 'yes') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ typeValue + " on "+routineDay.toLowerCase()+" at "+routineTime+ ". "
    } else if (commitType === 'Location' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ typeValue + ". "     
    } else if (commitType === 'Person' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " when I encounter "+ typeValue + ". "     
    } else if (commitType === 'Activity' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ activityCueTime+ " I " + typeValue + ". "     
    } else if (commitType === 'Other' && makeCommitment === 'no') {
      this.subRoutineAnswer = "My commitment: for the next week I will " + level.title.toLowerCase() + " "+ typeValue+ ". "
    }

    console.log("subRoutineAnswer",this.subRoutineAnswer)
    return this.subRoutineAnswer
  }

  removeAppStateData(){
    this.appState.set('makeCommitment', undefined)
    this.appState.set('activityCueTime', undefined)
    this.appState.set('commitData', undefined)
    localStorage.removeItem('commitData')
    localStorage.removeItem('makeCommitment')
    localStorage.removeItem('activityCueTime')
    this.appState.set('differentTimes', undefined)
    this.appState.set('differentDays', undefined)
    this.appState.set('routineTime', undefined)
    this.appState.set('selectedDate', undefined)
    this.appState.set('dayTimeArray', undefined)
    this.appState.set('selectedLevelIds', undefined)
    this.appState.set('multipleDates', undefined)
  }

}
