import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18n } from 'src/app/i18n.service';
import { RoutineInfo } from '../routine-info.model';
import { RoutineLevel } from '../routine-level.model';
import { HomeUiService } from '../../new-design/home-ui.service';

@Component({
  selector: 'person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {

  @Input() routine: RoutineInfo
  @Input() level: RoutineLevel
  @Input() inverted = false

  @Output() personData = new EventEmitter<any>();
  type = 'person'
  personLabel: string
  personName: string

  constructor(
    private i18n: I18n,
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,
  ) { 
    this.personLabel = this.i18n.t('name or description')
  }

  ngOnInit() {
    
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  getValue(person){
    this.personName = person
    this.personData.emit(this.personName.toLowerCase())
  }

}