import { AfterViewInit, Component, DoCheck, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IonInput,
  Platform,
  ToastController,
} from "@ionic/angular";
import { environment } from "src/environments/environment";
import { AppState } from "../app.service";
import { Client } from "../client/client.model";
import { CordovaService } from "../cordova.service";
import { I18n } from "../i18n.service";
import { NotificationService } from "../notification/notification.service";
import { PathGroup } from "../path-group/path-group.model";
import { PathGroupService } from "../path-group/path-group.service";
import { SessionService } from "../session/session.service";
import { SpinnerService } from "../spinner.service";
import { ThemeService } from "../theme/theme.service";
import { ToasterService } from "../toaster.service";
import { UserService } from "../user/user.service";
import { SignInService } from "./sign-in.service";
import { AnalyticsServiceService } from "../services/analytics-service.service";
import mixpanel from "mixpanel-browser";
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";
import { Filter } from "../shared/filter";
import { expandOnEnterAnimation } from "angular-animations";
import { AlertCreateAccountComponent } from "../alert-create-account/alert-create-account.component";
import { MatDialog } from "@angular/material/dialog";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HomeUiService } from "../new-design/home-ui.service";
import { Constants } from "../constants/constants";

interface SignInForm {
  email: string;
  password: string;
  pathGroupId?: number;
  pathId?: number; // LUMBA-1402
  changePath?: boolean; // LUMBA-982
  fromMobile?: boolean; // LUMBA-982
}

interface SignInFormSSO {
  email: string;
  ssoUid: string;
  isSso: boolean;
  changePath: boolean;
  fromMobile: boolean;
  pathGroupId: number;
  pathId: number;
  ssoProvider: string;
}

@Component({
  selector: "sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"], 
  animations: [
    expandOnEnterAnimation({ duration: 600 }),
    
   trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: 'auto' })),
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('0.25s linear', style({ opacity: 1, height: '50px' }))
      ]),
      transition(':leave', [
        animate('0.25s linear', style({ opacity: 0, height: 0 }))
      ])
    ]),
    // trigger('fadeInOut2', [
    //   state('in', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px' })),
    //   transition(':enter', [
    //     style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0 }),
    //     animate('0.5s cubic-bezier(0.25, 0.1, 0.25, 1)', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px', height: '*' }))
    //   ]),
    //   transition(':leave', [
    //     animate('0.5s cubic-bezier(0.165, 0.84, 0.44, 1)', style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0, height: 0 }))
    //   ])
    // ])
    trigger('fadeInOut2', [
      state('in', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0 }),
        animate('0.5s ease', style({ opacity: 1, transform: 'scale(1)', maxHeight: '1000px', height: '*' }))
      ]),
      transition(':leave', [
        animate('0.5s ease', style({ opacity: 0, transform: 'scale(0.95)', maxHeight: 0, height: 0 }))
      ])
    ])
  ]

})
export class SignInComponent implements OnInit, DoCheck, AfterViewInit {
  environment: any;
  signInForm: SignInForm = {
    email: "", //henry@spacex.com
    password: "", //password
    pathGroupId: null, // LUMBA-982
    pathId: null, // LUMBA-1402
    changePath: null,
    fromMobile: null,
  };

  signInFormSSO: SignInFormSSO = {
    email: "",
    ssoUid: "",
    isSso: null,
    changePath: null,
    fromMobile: null,
    pathGroupId:null,
    pathId:null,
    ssoProvider: "",
  };

  passwordType: string = "password";
  passwordIcon: string = "eye-off";
  isIframe = false;
  loggedIn = false;
  persist = false;
  isMobileWeb=false;
  isMobileWebIos = false;
  isAndroidTablet=false;
  valid = true;
  error: string;
  verifyEmail: string;
  resetEmail: string;
  forgotPassword = false;
  changePathVisible = false; // LUMBA-982
  mobileState = "splash";
  sapSSOButton = "Login with SAP"
  pathGroupName: string;
  queryParamSubDomain: string;
  currentPathGroup: PathGroup;
  client: Client;
  newEmailValue: any;

  expired: boolean;
  notStarted: boolean;
  loading = false;
  loginDisable=false;

  imageStyle: object = {
    "background-image": "url(assets/images/default-logo.jpg)",
  };
  clientLogoImageUrl = "assets/images/default-logo.jpg";
  backgroundStyle: object = {
    "background-image": "url(assets/images/default-background.jpg)",
  };
  constants = Constants;
  colours: any;
  nameSignUp: string;
  currentYear: number;
  clientLoginLogoImageUrl: string;
  clientBackgroundImageUrl: string;
  isSSOUser: boolean = false;
  isMobile: boolean;
  clientImage: any
  showLoader: boolean;
  progress: number = 0
  keyboardHeight;
  @ViewChild("loginBlock") loginBlock: ElementRef;
  showPwdField: boolean = false
  filter: Filter = {q: {enabled_eq: true}}
  animationState: string = 'in';
  options: [
    {
      label: 'Other',
      animations: ['expandCollapse']
    }
  ]
  inputEmail: string
  @ViewChild('mobilePassword', { static: false }) mobilePassword: IonInput;
  @ViewChild('webPassword', { static: false }) webPassword: IonInput;
  enableHelpEmail = true
  emailFocused = false
  fgtFocued = true
  translatedSignInAgreementText;
  onWebEmailFocus = false;
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    public toast: ToastController,
    public appState: AppState,
    private spinnerService: SpinnerService,
    private themeService: ThemeService,
    public cordovaService: CordovaService,
    private pathGroupService: PathGroupService,
    private userService: UserService,
    private i18n: I18n,
    private toasterService: ToasterService,
    public keyboard: Keyboard,
    public signIn: SignInService,
    private platform: Platform,
    private homeUIService: HomeUiService,
    private notificationService: NotificationService,
    private analyticsService: AnalyticsServiceService,
    private dialog: MatDialog,
  ) {
    this.isMobile = this.appState.get("isMobile");
    this.currentYear = new Date().getFullYear();
    this.environment = environment;
    this.translatedSignInAgreementText =`By continuing, you're agreeing to ${this.constants.appName}’s`
    this.pathGroupName = this.route.snapshot.paramMap.get("pathGroupName");
    localStorage.setItem("testPath", this.pathGroupName);
    this.verifyEmail = this.appState.get("verifyEmail");
    this.clientImage = this.appState.get('clientImage')
    const clientPathCount = localStorage.getItem("clientPathCount");
    this.isAndroidTablet = this.platform.is('mobileweb') && this.platform.is('tablet') && this.sessionService.isTabletDevice();
    if (
      clientPathCount &&
      parseInt(clientPathCount, 10) > 1 &&
      !!localStorage.getItem("activePathId")
    ) {
      this.changePathVisible = true; // LUMBA-1391
    }
    // initialize components UI services
    this.signIn.initServices(this.toasterService,this.spinnerService,this.route,this.router)
  }
  
  ngAfterViewInit(): void {
   this.keyBoardChange();
  //  this.checkBottomHeight();
  }
  toggleAnimation() {
    this.animationState = this.animationState === 'in' ? 'out' : 'in'; // Toggle animation state
  }
  keyBoardChange() {
    if(this.platform.is('android')) {
    
      let id;
      let val;
       this.keyboard.onKeyboardDidShow().subscribe((info) => {
       
         this.loginBlock?.nativeElement?.style.setProperty(
           'margin-bottom',
           `${(info.keyboardHeight)-140}px`
         );
        
        //  this.loginBlock?.nativeElement?.style.setProperty(
        //   'margin-top',
        //   `${20}px`
        // );
        if(this.mobileState == 'login'){
          id =document.getElementsByClassName('login-box')[0];   
          console.log($('.login-box')?.css('margin-top'),id);
          val=$('.login-box')?.css('margin-top');
          id?.setAttribute('style', `margin-top:${0}px`)
          console.log("id when keyboard pos", id)
        }
       });
   
       this.keyboard.onKeyboardDidHide().subscribe(() => {        
       
         this.loginBlock?.nativeElement?.style.setProperty(
           'margin-bottom',
           `${0}px`
         );
        //  this.loginBlock?.nativeElement?.style.setProperty(
        //   'margin-top',
        //   `${10}px`
        // );
        if(this.mobileState == 'login'){
          console.log(id);
          id?.setAttribute('style', `margin-top:${val}px`)
          console.log("when keyboard closes",id);
        }
       });
    
   }
  }
  ngDoCheck() {
    this.showLoader = this.appState.get('showLoader')
    if (this.showLoader) {
      document.getElementById('login').classList.add('hide-bg');
    }
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    var ssoProvider = localStorage.getItem('ssoProvider') 
    this.signIn.getParamsFromURL(this.loading,this.expired,this.client,this.valid);
    this.mobileState = "login";
    this.signIn.authenticateSSOTokenForLogin(this.loading,this.expired,this.client,this.valid)
   
    if (localStorage.getItem("token")) {
      this.router.navigate(["/main"]);
      return true;
    }

    // LUMBA-982
    if (!this.pathGroupName) {
      this.mobileState = "login";

      this.setImageStyles(); // LUMBA-1408
      this.spinnerService.off();
      this.cordovaService.hideSplashScreen();
      return;
    }
    this.pathGroupService.getPathGroupByName(this.pathGroupName).subscribe(
      (pathGroup) => {
        this.client = pathGroup.client;
        console.log("-----this.client-----",this.client)
        this.setStyles();
        this.currentPathGroup = pathGroup;
        this.signInForm.pathGroupId = +pathGroup.id;
        const now = new Date();
        this.nameSignUp = this.currentPathGroup.getCustomText("st_su");
        this.colours = this.currentPathGroup.colours;
        this.themeService.apply(
          this.currentPathGroup,
          this.currentPathGroup.client
        ); // LUMBA-1440
        if (now < new Date(this.currentPathGroup.starts)) {
          this.notStarted = true;
        }
        if (now > new Date(this.currentPathGroup.ends)) {
          this.expired = true;
        }
        this.spinnerService.off();
        this.cordovaService.hideSplashScreen();
      },
      () => {
        this.spinnerService.off();
        this.cordovaService.hideSplashScreen();
        this.router.navigate([`/not-found`]);
      }
    );
    this.analyticsService.setScreen(AnalyticsServiceService.LOGIN)
    this.setClientBackground();
  }

  @HostListener('document:keyup.enter', ['$event'])
  onEnterKeyPress(event: KeyboardEvent) {
    if(this.inputEmail){
      this.signInForm.email = this.inputEmail
    }
    if(this.signInForm.email.length > 1 && this.signInForm.password.length > 1){
      this.login();
    }else if(this.signInForm.email.length > 1 && this.inputEmail.length > 1){
      this.validateEmail()
    }
  }

  login() {
    if (this.loading || this.loginDisable) {
      return false;
    }

    if (this.signInForm) {
      this.loading = true;
      this.loginDisable = true;
      // LUMBA-982
      if (!this.signInForm.pathGroupId) {
        const activePathId = this.appState.get("activePathId");
        if (activePathId && this.appState.get("changePath")) {
          this.signInForm.pathId = parseInt(activePathId, 10);
          this.signInForm.changePath = true;
        }
      }
      this.signInForm.fromMobile = this.cordovaService.onCordova;
    }
    if(this.inputEmail){
      this.signInForm.email = this.inputEmail
    }
    if(this.signInForm.password){
      this.signInForm.password = this.signIn.removeWhiteSpaces(this.signInForm.password)
    }
    // this.spinnerService.on();
    this.sessionService.newSession(this.signInForm).subscribe(
      (result) => {
        // this.spinnerService.off();
        this.toasterService.success(this.i18n.t("You’re in!"));
        this.appState.set("activeCloseoutWelcomeScreen", undefined);
        this.appState.set("isLoggedIn", true);
        if (this.appState.get('routine_schedule_day')) {
          this.appState.set('routine_schedule_day', null)
        }
        if (result.expiredOrRemoved) {
          this.expired = true;
          this.client = result.client;
        } else if (result) {
          localStorage.setItem("lastUserEmail", this.signInForm["email"]); // LUMBA-982
          let onboaringSurvey = result?.data?.path?.onboardingSurveys?.length > 0 && result?.data.path?.enabledAttributes['onboarding_survey'];
          let isIntroVideo = result?.data.path?.names["isIntroVideo"];
          if (result.showOnboardingSurvey && (onboaringSurvey || isIntroVideo)) {
          localStorage.removeItem("dontShowAgain");
          localStorage.removeItem("isAppUpdated");
            this.router.navigate(["/onboarding"], { state: { onboardingSurvey: onboaringSurvey, isIntroVideo: isIntroVideo } });                          
          } else if (result.showCloseout) {
            this.router.navigate(["/closeout"]);
          } else {
            if (result.showOnboardingSurvey && !(result?.data?.path?.onboardingSurveys?.length > 0 || result?.data.path?.names["isIntroVideo"])) {
               this.homeUIService.checkSubpathStatus();
            }
            localStorage.setItem("dontShowAgain", "true");
            localStorage.setItem("isAppUpdated", "true");
            this.router.navigate(["/main"]);
          }
        } else {
          // TODO: Handle false response? Try again?
        }
        this.loading = false;
        this.loginDisable=false;
      },
      (errorResponse) => {
        this.spinnerService.off();
        this.valid = false;
        this.loading = false;
        this.loginDisable=false;
        let messages;
        if (
          typeof errorResponse.graphQLErrors !== "undefined" &&
          errorResponse.graphQLErrors.length > 0
        ) {
          messages = errorResponse.graphQLErrors.map((e) => e.message);
        } else {
          messages = [
            "Server or connection issue. Please check your internet connection.",
          ];
        }
        this.error = messages.join("<br>");
        this.toasterService.error(this.i18n.t(this.error));
      }
    );
  
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === "text" ? "password" : "text";
    this.passwordIcon = this.passwordIcon === "eye-off" ? "eye" : "eye-off";
  }

  private setStyles() {
    if (this.client.backgroundImageUrl) {
      this.backgroundStyle = {
        "background-image": `url(${this.client.backgroundImageUrl})`,
      };
      localStorage.setItem(
        "clientBackgroundImageUrl",
        this.client.backgroundImageUrl
      );
    }
    if (this.client.imageUrl) {
      this.clientLogoImageUrl = this.client.imageUrl;
      this.imageStyle = { "background-image": `url(${this.client.imageUrl})` };
      localStorage.setItem("clientLogoImageUrl", this.client.imageUrl);
    }
  }
  onTextareaFocus() {
    let id = document.getElementsByClassName("login-box");
    if (id) {
      console.log("test bottomheight");
      for (let i = 0; i < id?.length; i++) {
         id[i]?.setAttribute(
           "style",
           "margin-top:2%; margin-bottom:55px; overflow-y:auto;height:calc(100vh - 200px);"
         );
      }
      
    }
  }

    onTextareaBlur() {
    let id = document.getElementsByClassName("login-box");
    if (id) {
      for (let i = 0; i < id?.length; i++) {
         id[i]?.setAttribute(
           "style",
           "margin-top:21%; margin-bottom:5px; overflow-scroll:none; height:100%;"
         );
      }
      
    }
  }
  // checkBottomHeight() {

  //   let id = document.getElementsByClassName("login-box");
  //     this.platform.ready().then(() => {
  //       this.keyboard.onKeyboardWillShow().subscribe((info) => { 
  //         if (id ) {

  //           id[0]?.setAttribute(
  //             "style",
  //             "margin-top:2%; margin-bottom:55px; overflow-y:scroll;"
  //           );
  //         }
  //       });

  //       this.keyboard.onKeyboardWillHide().subscribe((info) => {
  //         if (id ) {

  //           id[0]?.setAttribute(
  //             "style",
  //             " margin-top: 21%;"
  //           );
  //         }
  //       });
  //     })
    
  // }
  setImageStyles() {
    // LUMBA-1396
    const data = this.themeService.themeData;
    if (data.clientBackgroundImageUrl) {
      this.backgroundStyle = {
        "background-image": `url(${data.clientBackgroundImageUrl})`,
      };
    }
    if (data.clientLogoImageUrl) {
      this.imageStyle = {
        "background-image": `url(${data.clientLogoImageUrl})`,
      };
      this.clientLogoImageUrl = data.clientLogoImageUrl;
    }
  }

  setClientBackground() {
    const data = this.themeService.themeData;
    if (data.clientBackgroundImageUrl) {
      this.clientBackgroundImageUrl = data.clientBackgroundImageUrl;
    }
    if (data.clientLogoImageUrl) {
      this.clientLogoImageUrl = data.clientLogoImageUrl;
    }
  }

  sendForgotPassword() {
    mixpanel.track('Login-forgot password')
    if (!this.resetEmail) {
     this.resetEmail =this.inputEmail
    }
    if (!this.resetEmail) {
      return false;    
    }
    this.loading = true;
    this.userService.sendForgotPassword(this.resetEmail).then(
      (response) => {
        if (response && response.success && response.message) {
          this.toasterService.success(this.i18n.t(response.message));
          this.toasterService.success(this.i18n.t(response.message));
          this.forgotPassword = false;
          if (this.isMobile) {
            this.mobileState = "login";
          }
          this.loading = false;
        }
        return response.data;
      },
      (error) => {
        this.toasterService.error(this.i18n.t("Email not found"));
        this.notificationService.alert(this.i18n.t("Email not found"), "Oops!");
        this.loading = false;
      }
    );
  }

  changeCheckEmailMode() {
    this.verifyEmail = undefined;
    this.appState.set("verifyEmail", undefined);
    this.goToPrev(); // LUMBA-1386
  }

  goToPrev() {
    if (this.pathGroupName) {
      this.appState.set("routedPath", this.pathGroupName);
      if (this.cordovaService.onCordova) {
        // LUMBA-1440
        this.mobileState = "splash";
        return;
      }
    }
    this.router.navigate([""]);
  }

  goToLogin() {
    this.verifyEmail = undefined;
    this.mobileState = 'login'
  }

  // LUMBA-982
  changePath() {
    this.router.navigate(["path-select"], {
      queryParams: {
        origin: "login",
      },
    });
  }

  resendVerificationEmail() {
    if (!this.verifyEmail) {
      this.verifyEmail =this.inputEmail
    }
    if (!this.verifyEmail) {
      return false;
    }
    this.userService.resendVerificationEmail(this.verifyEmail).then(
      (response) => {
        if (response && response.success && response.message) {
          this.toasterService.success(this.i18n.t(
            "An email has been sent to this address."
          ));
        }
        return response.data;
      },
      (error) => {
        console.log("sendForgotPassword error", error);
        this.toasterService.error(this.i18n.t("Email not found"));
      }
    );

    return false;
  }

  goToSignUp() {
    const routedPath = this.appState.get("routedPath");
      this.router.navigate(["signup"]);
  }

  isClientEnabled(): boolean {
    return this.client && this.client.enabled;
  }

  isEngagementClient(): boolean {
    return (
      this.client?.name &&
      this.client?.name.toLowerCase() === "engagement"
    );
  }

  isValid(): boolean {
    this.valid = true;
    if (
      !(
        this.signInForm.email &&
        this.signInForm.password &&
        this.signInForm.password.length > 6
      )
    ) {
      this.valid = false;
    }
    return this.valid;
  }

  goToSAP() {
  let url = environment.openidSAP
  localStorage.setItem('ssoProvider','SAP') 
  if (this.isMobile) {
      this.signIn.openSAPBrowser(url);
    } else {
      window.open(url, "_self");
    }
  }

  goToDenone() {
    let url = environment.SSODENONEURL
    localStorage.setItem('ssoProvider','DENONE') 
    if (this.isMobile) {
        this.signIn.openSAPBrowser(url);
      } else {
        window.open(url, "_self");
    }
  }

  goToOkta(){
    let url = environment.SSO_OKTA_URL
    localStorage.setItem('ssoProvider','OKTA') 
    if (this.isMobile) {
        this.signIn.openSAPBrowser(url);
     } else {
      window.open(url, "_self");
    }
  }

  goToExxon(){
    let url = environment.SSO_EXXON_URL
    console.log('goToExxon url :', url);
    localStorage.setItem('ssoProvider','EXXON') 
    if (this.isMobile) {
      if (this.platform.is('ios')) {
        this.signIn.openExxonMobileBrowser(environment.IOS_SSO_EXXON_URL);
      }
      else {
        this.signIn.openSAPBrowser(url);
      }
     } else {
      window.open(url, "_self");
      setTimeout(() => {
        this.signIn.showAuthLoader = true;
        mixpanel.track('Enabled showAuthLoader in web after SSO url redirection',{'showAuthLoader': this.signIn.showAuthLoader})
      },600);
      
    }
  }

  goToLululemon(){
    let url = environment.SSO_LULULEMON_URL
    localStorage.setItem('ssoProvider','LULULEMON') 
    if (this.isMobile) {
        this.signIn.openSAPBrowser(url);
     } else {
      window.open(url, "_self");
    }
  }


  onKey(event) {
    this.inputEmail = event.target.value;
    this.inputEmail = this.signIn.removeWhiteSpaces(this.inputEmail)
    if(this.inputEmail.length < 1) this.showPwdField = false
  }

  validateEmail(){
    if (this.signIn.validateMail(this.inputEmail)) {
      // this.spinnerService.on()
      this.userService.getList(this.inputEmail)
        .subscribe(response => {
          // this.spinnerService.off()
          if(response){
            if (response.isSso && response.ssoProvider === "SAP") {
              this.goToSAP()
            } else if(response.isSso && response.ssoProvider === "OKTA"){
              this.goToOkta()
            } else if(response.isSso && response.ssoProvider === "EXXON"){
              this.goToExxon()
            } else if(response.isSso && response.ssoProvider === "LULULEMON"){
              this.goToLululemon()
            } else if (response.email === this.inputEmail.toLowerCase()){  
              this.signInForm.email = this.inputEmail
              this.showPwdField = true
              setTimeout(() => {
                this.mobilePassword.setFocus();
                this.webPassword.setFocus();
              }, 900);
            }else{
              if (this.signIn.validateLoginId(this.inputEmail)) {
                this.toasterService.warning(this.i18n.t("User ID not recognized. Please try again."));
              } else {
                this.createNewAccount(this.inputEmail)
              }   
            }
          }else{
            if (this.signIn.validateLoginId(this.inputEmail)) {
              this.toasterService.warning(this.i18n.t("User ID not recognized. Please try again."));
            } else {
              this.createNewAccount(this.inputEmail)    
            }            
          }
        },
        (err)=>{
          // this.spinnerService.off()
          this.toasterService.warning(this.i18n.t("Server or connection issue. Please check your internet connection."));
        }
      )
    }else{
      this.toasterService.warning(this.i18n.t("Please enter valid email!"));
    }
  }

  createNewAccount(email){
    this.appState.set('signUpForm', undefined)
    
    if(this.platform.is('android') || this.platform.is('ios')) {
      const dialogAction = this.dialog.open(
        AlertCreateAccountComponent
      );
      dialogAction.afterClosed().subscribe((result) => {
        console.log('result :', result);
        this.spinnerService.off()
        if(result === 'confirm'){
          this.router.navigate(['/signup'], { queryParams: { newUserEmail: email } })
        }else if(result === 'cancel'){
          this.signInForm.email = email
        }else{
        }
      });
    }else {
      this.mobileState = 'newLogin'
      this.newEmailValue = email
   }
  }

  inputFocus() {
    this.enableHelpEmail = false;
    if(this.isAndroidTablet){
    this.onTextareaFocus();
    }
  }

  inputBlur() {
    this.enableHelpEmail = true
    if (this.isAndroidTablet && !this.onWebEmailFocus) {
      this.onTextareaBlur();
    }
  }

  openLink(url: string) {
    if (this.cordovaService.onCordova) {
      this.cordovaService.openLinkInBrowser(url)
    }else{
      window.open(url)
    }
  }

  emailInputFocus(){
    this.emailFocused = true
    this.onWebEmailFocus = true;
    if(this.isAndroidTablet){
      this.onTextareaFocus();
      }
  }

  emailInputBlur() {
    if(!this.inputEmail || this.inputEmail.length < 1){
      this.emailFocused = false
    }
    this.onWebEmailFocus = false;
      if (this.isAndroidTablet && this.enableHelpEmail) {
        this.onTextareaBlur();
      }
  }

  fgtInputFocus(){
    this.fgtFocued = true
  }

  fgtInputBlur(){
    this.fgtFocued = false
  }

}
