import { AfterViewInit, Component, DoCheck, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { HomeSheetModalComponent } from '../home-sheet-modal/home-sheet-modal.component';
import { AppState } from 'src/app/app.service';
import { Path } from 'src/app/path/path.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Question } from 'src/app/question/question.model';
import { Habit } from 'src/app/habit/habit.model';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import { DailyStepsTrackerNewComponent } from '../daily-steps-tracker-new/daily-steps-tracker-new.component';
import { HomeWeeklyTrackerComponent } from '../home-weekly-tracker/home-weekly-tracker.component';
import mixpanel from 'mixpanel-browser';
import { HamburgerMenuComponent } from '../hamburger-menu/hamburger-menu.component';
import * as moment from 'moment';
import { expandOnEnterAnimation, slideInDownOnEnterAnimation, slideOutUpOnLeaveAnimation } from 'angular-animations';
import { HomeUiService } from '../home-ui.service';
import { AllPathsComponent } from 'src/app/all-paths/all-paths.component';
import { Subject } from 'rxjs';
import { FooziAiConciergeComponent } from '../foozi-ai-concierge/foozi-ai-concierge.component';
import { FooziAiConciergeService } from '../foozi-ai-concierge.service';
import { SessionService } from 'src/app/session/session.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Component({
  selector: 'app-updated-home',
  templateUrl: './updated-home.component.html',
  styleUrls: ['./updated-home.component.scss'],
  animations:[expandOnEnterAnimation({ duration: 500 }),
    slideInDownOnEnterAnimation({duration: 500}),
    slideOutUpOnLeaveAnimation({duration:500})
  ]
})
export class UpdatedHomeComponent implements OnInit,AfterViewInit, OnDestroy, DoCheck {
  @ViewChild("modal") modal: HomeSheetModalComponent;
  @ViewChild("hamModal") hamModal: HamburgerMenuComponent;
  destroyed$ = new Subject<boolean>();
  @ViewChild("dailyStepsTracker") dailyStepsTracker: DailyStepsTrackerNewComponent; 
  isMobile;
  currentPath: Path;
  client;
  allPathsCount;
  inoutScreenData;
  currentDay;
  durationOfTask;
  pathRegistration;
  scheduleDay;
  scheduledStep;
  addHeader: boolean;
  activeDay;
  isVisible;
currentUser;
currentStep;
totalSteps;
stepsComplete
stepsCompleteTotal
canvas;
stepsArray;
enabledAttributes;
pathGroupId: any;
routineVersion: boolean;
scheduleDays: number[];
currentDayStarted: boolean;
currentDayCompleted: boolean;
allContentCompleted: boolean;
dayHasIncompleteMandatory;
showCloseoutWelcomeScreen;
isLastCurrentDayCompleted;
dayHasContent;
allLevelsCompleted;
isFullCardView
subPathName;
isSlideLeft;
task;
isModalOpen;
questionData: Question[];
documentData: Document[];
pollData: any[];
surveyData: any[];
hadbitData: Habit[];
maxOneNumbers;
maxTwoNumbers;
lengthOfSnoozed;
routineList;
maxData;
lastStep=0;
timezone;
selectedTimings;
showContinueButton: boolean;
  showReplayButton: boolean;
  showBeginButton: boolean;
  showCompletedMessage: boolean;
  showUnlockButton: boolean = false;
  showPrev: boolean = false;
  activeCloseoutWelcomeScreen
  dayNumber;
  maxAllowedScheduleDay;
  previousDayCompleted
  rightState;
  state;
  isTodayTask;
  makeHabit;
  dailySteps=[]
  counterId;
  isAndroid =false;
  themeColor;
  dailyStepsArray;
  isPortrait;
  allPathsComponent: AllPathsComponent;
  sectionSize = [];
  constructor(private modalController: ModalController, public homeUIService: HomeUiService,private screenOrientation: ScreenOrientation,
     private appState: AppState, public platform: Platform, private fooziAiService: FooziAiConciergeService, private sessionService: SessionService,
     private router: Router, private homev5Service: HomeV5Service,private dailyStepsService: DailyStepsService) { 
      const themeColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');
      this.themeColor=this.hexToRgb(themeColor,0.8);
    this.homeUIService.allpaths(this.appState.get('currentClient').id);
    this.isPortrait = this.screenOrientation.type.includes("portrait");
    this.dailyStepsArray = this.appState.get('DailyStepsData');

    this.sectionSize = [
        {
          size:2, sizeMd:3,sizeSm:3,sizeLg:2.5,sizeXl:2
         },
        {
          size:10, sizeMd:9,sizeSm:9,sizeLg:9.5,sizeXl:10
        }
      ]
   }

  
  async ngOnInit() {
    this.isMobile = this.appState.get('isMobile')
    this.init()
    this.allPathsCount =  this.appState.get('allPathCount')
    this.switchStep();
    this.homeUIService.refreshStepApiData.pipe(takeUntil(this.destroyed$)).subscribe( (data) => {
      if(data){
        this.dailyStepsArray = data;  
      }
    
    });
    this.fooziAiService.expandHamburger.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      let col1, col2;
      this.sectionSize = [];
      console.log("called datat",data)
      if (data) {
         col1 = {
          size:3.5, sizeMd:5,sizeSm:5,sizeLg:4,sizeXl:3.5
        }
        col2 = {
          size:8.5, sizeMd:6.5,sizeSm:6.5,sizeLg:8,sizeXl:8.5
        }
        this.sectionSize.push(col1,col2)
      }
      else {
         col1 = {
          size:2.5, sizeMd:3,sizeSm:3,sizeLg:2.5,sizeXl:2
         }
        col2 = {
          size:9.5, sizeMd:9,sizeSm:9,sizeLg:9.5,sizeXl:10
        }
        this.sectionSize.push(col1,col2);
      }
    });
    this.isVisible = this.homeUIService.getModalStack()?.length > 0
    let currentClient=this.appState.get('currentClient')
    this.homeUIService.getCohorts(currentClient.id)
  }
  switchStep(){
    if (
      this.showReplayButton 
    ) {
     this.scheduledStep =this.scheduledStep+1

      this.dayHasIncompleteMandatory = this.currentPath.checkMandatoryForDay(
        this.activeDay
      );
      this.showContinueButton = this.homev5Service.checkAllNonMandatory()
        ? this.homev5Service.checkScreenCount(
            this.pathRegistration,
            this.activeDay
          )
        : (!this.dayHasIncompleteMandatory && this.currentDayStarted)?this.homev5Service.checkScreenCount(
          this.pathRegistration,
          this.activeDay
        ):(this.dayHasIncompleteMandatory && this.currentDayStarted);
      if (this.showContinueButton) {
        this.scheduledStep = this.scheduledStep - 1;
        this.activeDay = this.scheduledStep -1;
      }
    }
  }
  
  init() {
    this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get('currentScheduleDay')
    this.scheduleDay = +this.appState.get('currentScheduleDay')
    this.pathRegistration = this.appState.get('pathRegistration')
    this.activeDay = this.scheduleDay
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.client = this.appState.get('currentClient')
    this.inoutScreenData = this.findForCurrentDay(this.currentPath?.inoutScreens)
    this.currentUser = this.appState.get('currentUser')
    
      if (!this.pathRegistration.screensViewed) {
        let steps = this.appState.get('screensViewed')
        if (steps) {
           this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
           this.calculateSteps(this.stepsArray)
        }
       
      } else {
        let steps = this.pathRegistration.screensViewed
        if (steps) {
           this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
           this.calculateSteps(this.stepsArray)
        }
      }

      this.enabledAttributes = this.appState.get("routineVersion")
      if (this.enabledAttributes.routineVersion) {
        this.routineVersion = this.enabledAttributes.routineVersion
      } else {
        this.routineVersion = false
      }
  
      this.currentDayStarted = !!localStorage.getItem(
        `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${this.scheduledStep}`
      )
  
      this.currentDayCompleted = !!localStorage.getItem(
        `Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.scheduledStep}`
      )
  
      this.dayHasIncompleteMandatory = this.currentPath.checkMandatoryForDay(
        this.scheduledStep
      ) // LUMBA-1317
      this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted
      this.dayHasContent = this.currentPath.checkContentForDay(this.scheduledStep)
      // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content
  
      // Check whether localdata is present or not if not then check how many steps are completed
      if (!this.currentDayStarted || !this.currentDayCompleted) {
        let stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed, "step");
        stepsArray.forEach(element => {
          if (element.step == this.scheduledStep && element.visitedScreens < element.totalScreens) {
            this.currentDayStarted = true
          } else if (element.step == this.scheduledStep && element.visitedScreens == element.totalScreens) {
            this.currentDayStarted = true
            this.currentDayCompleted = true
          }
        });
      }
  
      if (this.scheduledStep) {
        if (this.showCloseoutWelcomeScreen) {
          if (this.scheduleDay < this.currentPath.daysOfContent) {
            // LUMBA-1435
            this.activeDay = this.currentPath.daysOfContent + 1
          } else {
            this.activeDay = this.scheduleDay + 1
          }
          this.activeCloseoutWelcomeScreen = true
        }
        this.showCompletedMessage =
          !this.dayHasIncompleteMandatory && this.dayHasContent
  
          this.showContinueButton = this.homev5Service.checkScreenCount(
            this.pathRegistration,
            this.scheduledStep
          )
          this.showReplayButton = this.homev5Service.checkReplayForNonMand(
            this.pathRegistration,
            this.scheduledStep
          )
          this.showBeginButton = this.homev5Service.checkPrevDayCompletedForNonMand(
            this.pathRegistration,
            this.scheduledStep,
            this.currentUser,
            this.currentPath,
            this.currentDayStarted
          )
  
      }
     
      this.dailySteps = [
        {
          kind: 'poll',
          title: 'h_ds_p',
          icon: 'glyphicon glyphicon-question-sign'
        },
        {
          kind: 'document',
          title: 'h_ds_d',
          icon: 'glyphicon glyphicon-list'
        },
        {
          kind: 'question',
          title: 'h_ds_q',
          icon: 'glyphicon glyphicon-comment'
        },
        {
          kind: 'survey',
          title: 'h_ds_s',
          icon: 'glyphicon glyphicon-comment'
        },
        {
          kind: 'habit',
          title: 'h_ds_h',
          icon: 'glyphicon glyphicon glyphicon-list'
        },
        {
          kind: 'routine',
          title: 'h_ds_r',
          icon: 'glyphicon glyphicon glyphicon-list'
        }
      ]
    
      this.dailySteps.forEach(s => this.fillParams(s))
      this.dailySteps.sort((a, b) => a.order - b.order)
      this.setSnoozedHabits()
      this.checkCurrentDayCompleted()
      this.checkUnlockCurrentDayCompleted();
      this.checkAllNonMandatory();
      this.subStepsCompleted()
  
    }
    subStepsCompleted() {

      this.stepsComplete = 0;
      this.dailySteps.forEach(element => {
        if ((element.isCompleted && !element.isMandetory) || (element.isCompleted && element.isMandetory)) {
          this.stepsComplete++
        }
      });
      this.stepsCompleteTotal = this.dailySteps.length
    }
   
    checkAllNonMandatory() {
      let isNonMandatoryArray = this.dailySteps.filter(s => s.exists && !s.isMandatory);
      let isAllTaskArray = this.dailySteps.filter(s => s.exists);
  
      if (isNonMandatoryArray.length === isAllTaskArray.length) {
        localStorage.setItem(`Toggle:User:${this.currentUser.id}:checkNonMandatory:${this.currentPath.id}:${this.currentDay}`, 'yes')
      } else {
        localStorage.removeItem(`Toggle:User:${this.currentUser.id}:checkNonMandatory:${this.currentPath.id}:${this.currentDay}`)
      }
    }
    calculateSteps(stepsArray: any[]) {
      if (stepsArray && stepsArray.length >= 1) {
        try {
          stepsArray.forEach(element => {
            if (element.step === this.currentDay) {
              this.currentStep = element.visitedScreens
              this.totalSteps = element.totalScreens
              throw new Error("steps error");
            } else {
              this.currentStep = 0
              this.totalSteps = 0
            }
          });
        } catch (error) { }
      }
    }
    async ngDoCheck(): Promise<void> {
      this.allPathsCount =  this.appState.get('allPathCount')
      this.init();
      this.fetchCurrentStepImage(this.themeColor);
    }
    isBreakPointChanges(val){
        if(val>0.25){
          this.addHeader =true
        }
        else {
          this.addHeader =false
        }
      }
    async fetchCurrentStepImage(col1,isPageOpened?){
      this.scheduledStep = this.appState.get('scheduleDayForCurrentStep');
      this.inoutScreenData = this.findForCurrentDay(this.currentPath.inoutScreens)
      let ele;
      if(this.isMobile) {
        ele=document.getElementsByClassName('screen-bg');
        if(ele){
          let image =this.inoutScreenData?.libraryImage
          let imageUrl;
          if(this.dailyStepsTracker?.activeCloseoutWelcomeScreen ||(this.dailyStepsArray&& this.dailyStepsArray[this.dailyStepsArray?.length-1]?.stepStatus=='Review' )) {
            imageUrl = `url(${this.currentPath.pathImage})`;
          }
          else {
            imageUrl = `url(${image})`;
          }
          ele[0]?.setAttribute('style',` background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 15%, ${col1} 85%), ${imageUrl};background-position: center;background-repeat: no-repeat;`)
        }
      }
      else if((localStorage.getItem('isBeginClicked') === 'true'||this.dailyStepsArray[this.scheduledStep-1]?.stepStatus ==undefined || isPageOpened) && !this.isMobile){
        ele=document.getElementsByClassName('web-screen-bg');
        let image;
        if(ele){
            image= this.dailyStepsArray[this.scheduledStep-1]?.inoutScreens?.library_image;
          let imageUrl;
          if((this.dailyStepsArray&& this.dailyStepsArray[this.dailyStepsArray?.length-1]?.stepStatus=='Review' )) {
            imageUrl = `url(${this.currentPath.pathImage})`;
          }
          else {
            imageUrl = `url(${image})`;
          }

            ele[0]?.setAttribute('style',` background: linear-gradient(180deg, rgba(10, 10, 10, 0) 26.04%, #0A0A0A 100%),
linear-gradient(0deg, rgba(10, 10, 10, 0.2), rgba(10, 10, 10, 0.2)), ${imageUrl},#0a0a0a 60%;background-position: center;background-repeat: no-repeat;background-size:cover`)
        }
      }
    }
    openComponent() {
      console.log("called allpaths component");
      this.appState.set("isAllPaths", true);
      this.homeUIService.openNextComponent(AllPathsComponent, '', 'desktop-allpath')
    }
    checkUnlockCurrentDayCompleted() {

      let isMandatoryArray = this.dailySteps.filter(s => s.exists && s.isMandatory);
      let isMandCompletedArray = this.dailySteps.filter(s => s.exists && s.isMandatory && s.isCompleted);
      if (isMandatoryArray.length > 0 && (isMandatoryArray.length == isMandCompletedArray.length)) {
        localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes')
      } else {
        localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.currentPath.id}:${this.currentDay}`)
      }
    }
    checkCurrentDayCompleted() {
      if (this.dailySteps.filter(s => s.exists && !s.isCompleted).length) {
  
        localStorage.removeItem(`Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`)
      } else {
  
        localStorage.setItem(`Toggle:User:${this.currentUser.id}:DayCompleted:${this.currentPath.id}:${this.currentDay}`, 'yes')
      }
    }
    fillParams(item: any) {

      const source = this[item.kind]
      if (source) {
        item.exists = true
        item.isCompleted = source.isCompleted
        item.order = source.screenOrder
        item.isMandatory = source.isMandatory
        item.actionPoints = source.actionPoints ? source.actionPoints : source.answer ? source.answer.actionPoints : 0
        item.screenId = `daily-content-screen-${item.kind}`
      } else {
        item.exists = false
        item.isCompleted = false
        item.isMandatory = false
        item.order = 0
        item.actionPoints = 0
      }
      return item
    }
  
    setSnoozedHabits() {
      this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
      this.dailyStepsService.setSnoozedSteps(
        this.currentPath.habits
          .filter(item => item.scheduledLater
            // hided the this condition which show the snooz action above the completed day
            //  && item.endDay < this.currentDay
            && item.confirmation < 1) // LUMBA-1360, // LUMBA-1445
          .map(habit => {
            return {
              id: habit?.id,
              dayNum: habit.startDay, // LUMBA-1307
              title: habit.getTitle(),
              text: habit.text,
              actionPoints: habit.actionPoints,
              screenId: 'daily-content-screen-habit'
            }
          }
          )
      )
    }  
  ngAfterViewInit(): void {
     this.fetchCurrentStepImage(this.themeColor,true); 
      if(this.isMobile){
        if(this.platform.is('android')) {
          this.isAndroid =true;
        }
        else {
          this.isAndroid =false
        }
      }
  }

  hexToRgb(hexCode,opacity){
    let hex = hexCode?.replace('#', '');
    
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;   
    }

    return `rgba(${r},${g},${b},${opacity})`;
  }
  async openModal() {
    const modal = await this.modalController.create({
      component: HomeSheetModalComponent,
     // cssClass: 'no-backdrop-modal',
      backdropDismiss: false, // Set backdropDismiss to false to prevent dismissal
    });
  
    await modal.present();
  }
  findForCurrentDay(collection: any): any {
    return collection.find(item => item.startDay <= this.currentDay && this.currentDay <= item.endDay)
  }
  async click(){
    this.appState.set('isHamburgerOpen',true)
   this.isModalOpen = this.appState.get('isHamburgerOpen');
   
  }
  closeHamModal() {
    this.appState.set('isHamburgerOpen',false)
    this.isModalOpen =this.appState.get('isHamburgerOpen');
    this.hamModal.closeHamModal();
  }
  allowScreenCount(status) {
    this.dailyStepsTracker.allowScreenCount(status);
  }
 
  replay(i){
    this.dailyStepsTracker.replay(i)
  }
  unlockReplay(day){
    this.dailyStepsTracker.unlockReplay(day)
  }
  async openFooziModal() {
    const modal = await this.modalController.create({
      component: FooziAiConciergeComponent,
      //  componentProps: compProps,
      // animated: false,
      cssClass: this.isMobile ? "mobile-foozi-ai" : "custom-modal",
    });
    await modal.present();
    this.homeUIService.setModalStack(modal);
    this.fooziAiService.setFooziCompStatus(FooziAiConciergeComponent);
    modal.onDidDismiss().then(() => {
      this.fooziAiService.setFooziCompStatus(undefined);
    })
  }

  isTabletDevice(): boolean { 
     this.isPortrait = this.screenOrientation.type.includes("portrait");
    return this.appState.get('isTabletDevice') && this.isPortrait;
    // this.sessionService.hideTabletDeviceouchpoints();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.sessionService.isTabletDevice()) {
      this.fetchCurrentStepImage(this.themeColor, true);
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.modal?.closeModal();
    this.modalController.dismiss();

  }

}
