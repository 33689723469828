import { Component, Input, OnInit } from '@angular/core'

import { Document } from './document.model'
import { DocumentService } from './document.service'
import { CordovaService } from '../cordova.service'
declare var $: any

@Component({
  selector: 'first90-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent {
  @Input() document: Document
  @Input() isPreview: boolean

  constructor(
    private documentService: DocumentService,
    private cordovaService: CordovaService
  ) { }

  view (url: string) {
    if (this.cordovaService.onCordova) {
      this.cordovaService.openLinkInBrowser(url)
    } else {
      window.open(url, '_blank')
    }
    if ( !this.isPreview ) {
      this.documentService.viewDocument(
        this.document.id
      ).subscribe(
        response => {
          console.log( response )
        }
      )
      this.document.isCompleted = true
    }
  }

  openShareDropdown(event) {
    event.stopPropagation()
    event.target.closest('.dropdown').classList.toggle('open')
  }

  share(event, document) {
    event.stopPropagation()
    this.openMailTo(document.title, document.link)
  }

  openMailTo(subject, body) {
    location.href = 'mailto:%20' +
      '?subject=' + encodeURIComponent(subject) +
      '&body=' + encodeURIComponent(body)
    return true
    window.open('mailto:%20' +
      '?subject=' + encodeURIComponent(subject) +
      '&body=' + encodeURIComponent(body), '_self')
  }
}
