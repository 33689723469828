import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18n } from 'src/app/i18n.service';
import { RoutineInfo } from '../routine-info.model';
import { RoutineLevel } from '../routine-level.model';
import { HomeUiService } from '../../new-design/home-ui.service';

@Component({
  selector: 'other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss']
})
export class OtherComponent implements OnInit {

  @Input() routine: RoutineInfo
  @Input() level: RoutineLevel
  @Input() inverted = false

  @Output() otherData = new EventEmitter<any>();
  other: string
  otherLabel: string

  constructor(private i18n: I18n,
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,) { 
    this.otherLabel = this.i18n.t('Enter cue')
  }

  ngOnInit() {
    
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }

  getValue(other){
    this.otherData.emit(other.toLowerCase())
  }

}