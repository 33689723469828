import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import * as AddToCalendar from '../../../vendor/add-to-calendar-buttons/add-to-calendar.js'
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { ModalController } from '@ionic/angular';
import { HomeUiService } from '../new-design/home-ui.service';

declare var $: any


export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}


@Component({
  selector: 'app-alert-web-calendar',
  templateUrl: './alert-web-calendar.component.html',
  styleUrls: ['./alert-web-calendar.component.scss']
})
export class AlertWebCalendarComponent implements OnInit,AfterViewInit {

  @ViewChild ('calendarButtonsContainer') calendarButtonsContainer: ElementRef
  @Input() isWeekendCalandar;

  hasNativeCalendar: boolean  // LUMBA-1421
  cloudCalendarExpanded: boolean // LUMBA-1421
  cloudCalendarSet: boolean // LUMBA-1421
  calendarParams: CalendarParams
  currentPath: any;
  eventLength: string
  isMobile;
  constructor(private cordovaService: CordovaService,private homeService: HomeV5Service,private modalController: ModalController, 
    public homeUIService: HomeUiService,
     @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
     @Optional() private dialog: MatDialogRef<AlertWebCalendarComponent>,private appState: AppState    ) {
    
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    this.currentPath = this.appState.get("currentPath");
    this.isMobile = this.appState.get('isMobile');
  }

  ngOnInit() {
    this.initCalendarButton()
  }
  ngAfterViewInit() {
    if (!this.data) {
      this.data = this.isWeekendCalandar;
    }
  }

  initCalendarButton() { 
         
    if (this.hasNativeCalendar) {
      return
    }
    this.calendarParams = this.homeService.getCalendarData()
    const daysCount = this.calendarParams.endEventTime 
    const endDateTime = moment(this.calendarParams.startDate, 'HH:mm').add(daysCount, 'day').toDate()
    var frequency = ''
    
    if (this.calendarParams && this.calendarParams.frequency) {
     frequency = this.calendarParams.frequency.toUpperCase()
    } else{
      frequency = ''
    }

    const calendarButtons = AddToCalendar.addToCalendar({
      options: {
        class: 'desktop-schedule-icon',
        id: 'add-calendar-buttons-id'
      },
      data: {
        title: this.calendarParams.title,
        start: this.calendarParams.startDate,
        // timezone: America/Los_Angeles,					// converts the time to the IANA timezone
        end: this.calendarParams.endDate,
        // duration: 120,                            // Event duration (IN MINUTES)
        // allday: true,	
        endDate: endDateTime,
        // Override end time, duration and timezone, triggers 'all day'
        address: this.calendarParams.eventLocation,
        description: this.calendarParams.notes,
        frequency: frequency
      }
    })
    document.querySelector('.calendar').appendChild(calendarButtons);

  }

close() {
  if (this.dialog) {
   this.dialog.close();
  }
  else {
    this.modalController.dismiss();
  }
}

}
