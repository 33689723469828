import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from '../app.service';
import { Path } from '../path/path.model';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { UserService } from '../user/user.service';
import { last } from 'rxjs/operators';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';

export interface CurrentPathRegistration {
  [pathRegistration: string]: any;
}

export interface UpdatePathRegistration {
  [updatePathRegistration: string]: any;
}

@Injectable()
export class UtilityService {

  currentPath: Path
  currentUser: User
  public pathRegistration: CurrentPathRegistration;
  public updatePathRegistration: UpdatePathRegistration;
  API_URL: string = environment.apiBaseUrl
  UPDATE_SCREENS_VIEWED = '/users/update_screens_viewed'
  PATH_COMPLETION_EMAIL = '/users/path_completion_mail'

  constructor(private appState: AppState,
    private userService: UserService,
    private toasterService: ToasterService,
    private homeUiService: HomeUiService,
    private i18n: I18n,
    private httpclient: HttpClient,private homeService: HomeV5Service
  ) {
    this.currentPath = this.appState.get('currentPath')
    this.currentUser = this.appState.get('currentUser')
    this.pathRegistration = this.appState.get('pathRegistration')
  }

  convertToArray() {
    this.currentPath = this.appState.get('currentPath')
    let jsonObject = this.currentPath.colours
    let colorArray = []
    for (let element in jsonObject) {
      if (element === 'stat1' || element === 'stat2' || element === 'stat3' || element === 'stat4' || element === 'stat5') {
        colorArray.push({
          colorCode: jsonObject[element]
        });
      }
    }
    return colorArray
  }

  public getColor(routine) {
    var array = JSON.parse(routine.pathcolours)
    return array.theme
  }



  getCurrentUserRank(stats: any) {
    var current_user = []
    current_user = stats.leaderboard.user_position
    let thisPathUserRank = stats.leaderboard.current_path_user.findIndex(element => element.isMe)
    return thisPathUserRank;
  }

  getCurrentPathUser(stats: any) {
    var current_user = []
    current_user = stats.leaderboard.user_position
    let thisUser = stats.leaderboard.current_path_user.filter(element => element.isMe)
    let thisPathUser = thisUser[0]
    return thisPathUser;
  }

  public updateScreenCount(data: any): Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.httpclient.put(this.API_URL + this.UPDATE_SCREENS_VIEWED, data, { headers })
  }

  setScreenData(currentScreenIndex: any, currentDay: any, screensIds: any) {
    let currentScreenArray = []
    currentScreenArray.push(currentScreenIndex + 1)
    var stepObject = {
      step: currentDay,
      visitedScreens:currentScreenArray[0]>screensIds?.length?screensIds?.length: currentScreenArray[0],
      totalScreens: screensIds?.length,
      isScreenCompleted: false
    }
    this.appState.set('stepObject', stepObject)
  }

  setScreenCount() {
    var stepsArray = []
    var stepObject = this.appState.get('stepObject')
    var prevStepArray = []
    let pathRegistration = this.appState.get('pathRegistration')
    let stepsViewed = pathRegistration.screensViewed
    if (stepsViewed) {
      prevStepArray = stepsViewed
    } else {
      prevStepArray = this.appState.get('screensViewed')
    }

    let currentPath = this.appState.get('currentPath')
    let currentUser = this.appState.get('currentUser')

    if (prevStepArray && prevStepArray.length >= 1) {
      prevStepArray.push(stepObject)
      this.appState.set('stepsArray', prevStepArray)
      this.saveScreens(currentUser.id, currentPath.id, prevStepArray)
    } else {
      stepsArray.push(stepObject)
      this.appState.set('stepsArray', stepsArray)
      if (stepsArray && stepsArray.length >= 1) {
        this.saveScreens(currentUser.id, currentPath.id, stepsArray)
      }
    }
  }

  public async saveScreens(userId: number, pathId: number, screensViewed: any) {    
    let lastScreenViewed = screensViewed[screensViewed.length - 1]
    let lastStep = lastScreenViewed?.step - 1
    let lastVisited = lastScreenViewed?.visitedScreens
    let totalScreens = lastScreenViewed?.totalScreens
    let stepsCompleted = this.getScreensCompleted(lastStep,lastVisited,totalScreens)
    
    const requestData = {
      user_id: userId,
      path_id: pathId,
      screens_viewed: JSON.stringify(screensViewed),
      steps_completed: stepsCompleted.toString()
    }
    if (requestData.steps_completed < 0) {
      return
    }
    localStorage.setItem('stepsCompleted', stepsCompleted.toString())
    this.appState.set("screensViewed", screensViewed);
    this.appState.set("lastStep", lastStep);
    this.updateScreenCount(requestData).subscribe(
      (response) => {
        this.appState.set("screensViewed", response.data);
        this.homeUiService.getDailyStepsInfo();
        localStorage.removeItem('currentScreenIndexAfterScreenView')
        localStorage.removeItem('currentDayAfterScreenView')
      },
      error => {
        console.log('saveScreens error', error)
        this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
      }
    )
  }

  getScreensCompleted(lastStep: number,lastVisited: number,totalScreens: number){
    let stepsCompleted
    if (totalScreens >= 10) {
      let temp = lastStep + (lastVisited / totalScreens);
      stepsCompleted = temp.toFixed(1)
      return stepsCompleted
    } else {
      stepsCompleted = lastStep + '.' + lastVisited
      return stepsCompleted
    }
  }


  public sendEmail(data): Observable<any> {
    const headers = new HttpHeaders();
     headers.set("Authorization", localStorage.getItem("token"));
    const params = new HttpParams().append('user_id', data);
    return this.httpclient.get(this.API_URL + this.PATH_COMPLETION_EMAIL, { headers, params })
  }

  stopEmbeddedVideo(){
    setTimeout(() => {
      let disableVideo = this.appState.get('disableVideo')
      const iframe = this.appState.get('iframeObject')
      if (disableVideo && iframe) {
        iframe.src = iframe.src;
      }
    }, 10);
  }

  enableHeight(textareaContainer, isMobile, platform, keyboard){
    //this code is for increasing the height of the text area for android device when keyboard pops up
    if(isMobile){
      if(platform.is('android')) {
      //  this.textareaContainer.nativeElement?.setAttribute('style', 'margin-bottom:200px;')
        keyboard.onKeyboardDidShow().subscribe((info) => {
          console.log('keyboard will show with height', info.keyboardHeight);
          textareaContainer.nativeElement.style.setProperty(
            'bottom',
            `${info.keyboardHeight-81}px`
          );
          // textareaContainer.nativeElement.style.setProperty(
          //   'padding-bottom',
          //   `${300-(info.keyboardHeight-20)}px`
          // );
        });

        keyboard.onKeyboardDidHide().subscribe((info) => {
          console.log('keyboard did hide',info.keyboardHeight);
          console.log('keyboard did hide',info);
          textareaContainer.nativeElement.style.setProperty(
            'margin-bottom',
            `${0}px`
          );
          textareaContainer.nativeElement.style.setProperty(
            'bottom',
            `${75}px`
          );
          textareaContainer.nativeElement.style.setProperty(
            'padding-bottom',
            `${0}px`
          );
        });
      }
    }
  }

}
